import React, { useCallback, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Error from '@material-ui/icons/Error';
import MainButton from '../../../MainButton';
import CustomDialog from '../../../CustomDialog';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadConfiguration,
  resetError,
  setConfigurationUpdated,
  setLoadCode,
} from '../../../../Reducers/Configuration/Actions';
import { setVisible } from '../../../../Reducers/Ui/Actions';
import { ConfigurationContext } from '../../../../ContextProviders/ConfigurationProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ActivePopupContext } from '../../../../ContextProviders/ActivePopupProvider';

const useStyles = makeStyles((theme) => ({
  dialogTitleRoot: {
    fontSize: '1.5rem',
    fontWeight: 400,
  },

  TypographyCaption: {
    fontSize: 12,
    lineHeight: 1.7,
  },
  errorResult: {
    background: '#ED1C29',
    color: '#fff',
    padding: '20px',
    borderRadius: '4px',
    fontSize: 16,
    lineHeight: 1.7,
    textAlign: 'center',
    '& p': { color: '#fff !important' },
  },
  errorResultIcon: {
    float: 'left',
  },
  codeFieldWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 32,
  },
  codeField: {
    fontSize: '26px',
    borderRadius: 4,
    height: 40,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      height: 56,
      width: '100%',
    },
  },
  codeFieldInput: {
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    height: 20,
    padding: '10px 16px',
    minWidth: 320,
    [theme.breakpoints.down('xs')]: {
      padding: '0 5px',
      height: 56,
      minWidth: 50,
    },
  },
  activeButton: {
    backgroundColor: '#ed1c29',
    padding: '6px 15px',
    '&:hover': {
      backgroundColor: '#ed1c29',
      opacity: 0.5,
    },
  },
  inactiveButton: {
    padding: '6px 15px',
  },
  dialogSavingInProgress: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    height: 50,
    marginTop: 24,
  },
}));

const LoadConfigurationDialog = (props) => {
  const dispatch = useDispatch();
  const { configuration, configurationUpdated, loadCode, error } = useSelector(
    (state) => state.Configuration
  );
  const customData = useContext(ConfigurationContext);
  const { backgroundWorkMap, visibilityMap } = useSelector((state) => state.Ui);
  const show = !!visibilityMap.loadConfiguration;
  const workInProgress = !!backgroundWorkMap.loadConfiguration;
  const classes = useStyles(props);
  const { t } = useTranslation();

  useEffect(() => {
    if (configurationUpdated) {
      customData.setData(configuration.customdata);
      dispatch(setConfigurationUpdated(false));
    }
  }, [dispatch, configurationUpdated, configuration, customData]);

  const onClose = useCallback(() => {
    dispatch(setVisible('loadConfiguration', false));
    dispatch(resetError());
  }, [dispatch]);

  useContext(ActivePopupContext).useActive('LoadConfiguration', onClose, show);

  const onLoadClick = async () => {
    dispatch(resetError());
    await dispatch(loadConfiguration(loadCode));
  };

  const onChange = (e) => {
    if (error) {
      dispatch(resetError());
    }
    dispatch(setLoadCode(e.target.value));
  };

  return (
    <CustomDialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      dialogtitle={t('loadConfigurationDialog.title')}
      onCancel={onClose}
    >
      <DialogContent>
        {workInProgress && (
          <div className={classes.dialogSavingInProgress}>
            <CircularProgress />
          </div>
        )}
        {!workInProgress && (
          <React.Fragment>
            <Typography
              variant="body1"
              classes={{ root: classes.TypographyCaption }}
              id="alert-dialog-description"
            >
              {t('loadConfigurationDialog.existingCodeText')}
            </Typography>
            <div className={classes.codeFieldWrapper}>
              <TextField
                className={classes.codeField}
                value={loadCode || ''}
                variant={'outlined'}
                onChange={onChange}
                placeholder={t('loadConfigurationDialog.configurationCode')}
                InputProps={{
                  classes: {
                    input: classes.codeFieldInput,
                  },
                }}
              />
            </div>
          </React.Fragment>
        )}
        {error && (
          <div className={classes.errorResult}>
            <Error className={classes.errorResultIcon} />
            <Typography variant="body1">{t('loadConfigurationDialog.loadError')}</Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!workInProgress && (
          <MainButton
            onClick={onLoadClick}
            variant="contained"
            size="medium"
            disabled={!loadCode}
            className={!loadCode ? classes.inactiveButton : classes.activeButton}
          >
            {t('loadConfigurationDialog.confirm')}
          </MainButton>
        )}
      </DialogActions>
    </CustomDialog>
  );
};
LoadConfigurationDialog.propTypes = {
  configuration: PropTypes.object,
};
export default LoadConfigurationDialog;
