import React from 'react';
import Fade from '@material-ui/core/Fade/Fade';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
  inner: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
});

export const Loader = withStyles(styles, { name: 'Progress' })(({ loading, classes }) => (
  <Fade in={loading} timeout={300} unmountOnExit>
    <div className={classes.container}>
      <div className={classes.inner}>
        <CircularProgress />
      </div>
    </div>
  </Fade>
));
