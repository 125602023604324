import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { VisualizationContext } from '../../ContextProviders/VisualizationProvider';
import { InteractionStarted } from '../../../Visualization/EventTypes';
import { ToolbarButton } from './ToolbarButton';

export const ToolbarButtonWithSubmenu = withStyles({
  root: {
    pointerEvents: 'none',
  },
  paper: {
    background: 'rgba(255, 255, 255, 0.8)',
    boxShadow: 'none',
    padding: '12px 20px',
    pointerEvents: 'all',
  },
})(({ classes, children, ...props }) => {
  const visualization = useContext(VisualizationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    if (anchorEl === event.currentTarget) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (open) {
      return visualization.on(InteractionStarted, handleClose);
    }
  }, [open, visualization]);

  return (
    <>
      <ToolbarButton {...props} onClick={handleClick} active={open} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -12,
          horizontal: -20,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={classes}
      >
        {children}
      </Popover>
    </>
  );
});
