import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '../Toolbar';
import ViewButton from './Tools/ViewButton/ViewButton';
import PanButton from './Tools/PanButton/PanButton';
import Notes from './Tools/Notes/Notes';
import Measurements from './Tools/Measurements/Measurements';

const StyledToolbar = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 20,
    right: 20,
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.8)',
      top: -10,
      right: 10,
    },
  },
}))(Toolbar);

const VisualizationToolbar = () => {
  return (
    <StyledToolbar>
      <ViewButton />
      <PanButton />
      <Notes />
      <Measurements />
    </StyledToolbar>
  );
};

export default VisualizationToolbar;
