import { FileLoader, Group, Sprite, SpriteMaterial, Texture } from 'three';
import { EventActions } from '../../../../createWarehouseConfiguration';
import { createIntersectionQuery } from '/Visualization/utils/getIntersection';
import { PointerIntersection } from '/Visualization/EventTypes';
import commentSpriteSrc from './icons/comment_sprite.svg';

export function createNotesVisualization(visualization, configuration, options = {}) {
  const { scene } = visualization;
  const { spriteSrc = commentSpriteSrc } = options;
  const textureProto = new Texture();
  const svgContentPromise = new FileLoader().loadAsync(spriteSrc);
  textureProto.encoding = visualization.renderer.outputEncoding;

  const createSpriteWithNumber = async (number) => {
    const texture = textureProto.clone();
    const material = new SpriteMaterial({
      map: texture,
      sizeAttenuation: false,
      depthTest: false,
      depthWrite: false,
    });
    const sprite = new Sprite(material);
    sprite.center.set(1, 0);
    sprite.scale.set(0.02, 0.02, 1);

    const svgContent = await svgContentPromise;
    const base64 = btoa(svgContent.replace('{#}', number));
    const dataUrl = 'data:image/svg+xml;base64,' + base64;
    const img = document.createElement('img');
    img.src = dataUrl;
    texture.image = img;
    texture.needsUpdate = true;
    return sprite;
  };

  const syncNotes = async () => {
    visualization.notes && scene.remove(visualization.notes);
    const notesGroup = new Group();
    notesGroup.name = 'notes';
    visualization.notes = notesGroup;
    scene.add(notesGroup);
    const notes = configuration.getData().notes || [];
    for (const note of notes) {
      const sprite = await createSpriteWithNumber('#' + note.noteId);
      sprite.userData = note;
      const { position } = note;
      sprite.position.set(position.x, position.y, position.z);
      notesGroup.add(sprite);
    }
  };

  const disposeOnChange = configuration.onChange(
    ({ data: { action } }) => action === EventActions.setData && syncNotes()
  );

  const dispose = () => {
    disposeOnChange();
    visualization.notes && scene.remove(visualization.notes);
  };

  return {
    dispose,
    syncNotes,
  };
}

export function createNoteControls(visualization, callback) {
  return visualization.on(PointerIntersection, ({ data: { event } }) => {
    const { renderer, camera, scene, notes } = visualization;
    const intersectionQuery = createIntersectionQuery(event, renderer, camera);
    const intersect = intersectionQuery.getIntersection(notes) || intersectionQuery.getIntersection(scene);
    if (intersect && intersect.object && callback) {
      const {
        point: { x, y, z },
        object: {
          userData: { noteId },
        },
      } = intersect;
      return callback({ position: { x, y, z }, noteId, event });
    }
  });
}
