import React, { createContext, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from '@material-ui/core/utils/debounce';

export const VisualizationContext = createContext(null);

export const VisualizationProvider = ({ visualization, children }) => (
  <VisualizationContext.Provider value={visualization}>{children}</VisualizationContext.Provider>
);

VisualizationProvider.propTypes = {
  visualization: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export const VisualizationContainer = (props) => {
  const visualization = useContext(VisualizationContext);
  const handleResize = useMemo(
    () =>
      debounce(() => {
        const element = visualization.renderer.domElement.parentElement;
        const { width, height } = element.getBoundingClientRect();
        if (width > 0 && height > 0) {
          visualization.setSize({ width, height });
        }
      }, 100),
    [visualization]
  );
  const setRef = (element) => {
    if (element) {
      element.appendChild(visualization.renderer.domElement);
      handleResize();
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
  return <div {...props} ref={setRef} id="visualizationContainer" />;
};
