export class Observable {
  _eventListeners = [];
  _object = null;

  constructor(object = null) {
    this._object = object;
  }

  on = (eventName, callback) => {
    if (this.indexOf(eventName, callback) !== -1) {
      console.warn(`The following callback is already added to the event: ${eventName}`, callback);
      return;
    }
    this._eventListeners.push({
      eventName,
      callback,
    });
    return () => this.off(eventName, callback);
  };

  off = (eventName, callback) => {
    const idx = this.indexOf(eventName, callback);
    if (idx !== -1) {
      this._eventListeners.splice(idx, 1);
    }
  };

  indexOf = (eventName, callback) => {
    for (let o = 0; o < this._eventListeners.length; o++) {
      const el = this._eventListeners[o];
      if (el.eventName === eventName && el.callback === callback) {
        return o;
      }
    }
    return -1;
  };

  dispatch = (eventName, params) => {
    return this._eventListeners
      .filter((el) => el.eventName === eventName)
      .map((el) =>
        el.callback.call(this._object, {
          eventName,
          time: new Date(),
          data: params,
        })
      );
  };

  dispose = () => {
    this._eventListeners = [];
    this._object = undefined;
  };
}

export default Observable;
