import React from 'react';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import red from '@material-ui/core/colors/red';
import { ThemeProvider } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    primary: {
      ...red,
      contrastText: '#ffffff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 0,
      },
    },
  },
  media: {
    portrait: '@media (orientation: portrait)',
    landscape: '@media (orientation: landscape)',
  },
});

export const CustomThemeProvider = (props) => <ThemeProvider theme={theme} {...props} />;
