import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: 0,
    fontSize: '1em',
  },
  iconRoot: {
    marginLeft: -8,
    marginRight: 2,
  },
});

const ActionButton = ({ label, ...props }) => {
  const { root, iconRoot } = useStyles();
  return (
    <Button classes={{ root }} color="primary" variant="contained" disableElevation size="large" {...props}>
      <ChevronRightIcon classes={{ root: iconRoot }} /> {label}
    </Button>
  );
};

ActionButton.propTypes = {
  label: PropTypes.node,
};

export default ActionButton;
