import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { wallTypes as localWallTypes } from '../DataStructures/wallTypes';
import { roofTypes as localRoofTypes } from '../DataStructures/roofTypes';
import { roofWindowTypes as localRoofWindowTypes } from '../DataStructures/roofWindowTypes';

export function getOptionAttributeValue(option, attrIdentifier, defaultValue) {
  const attr = find(get(option, 'attributes'), { identifier: attrIdentifier });
  return get(attr, 'values[0].value', defaultValue);
}

export function getComponentByIdentifier(componentOptions, identifier) {
  return find(componentOptions, { identifier });
}

export function getOptionsByComponentIdentifier(componentOptions, identifier) {
  return get(getComponentByIdentifier(componentOptions, identifier), 'options') || [];
}

export function filterOptionsByAttributeValue(options, attrIdentifier, attrValue) {
  return filter(options, (option) => getOptionAttributeValue(option, attrIdentifier) === attrValue);
}

export function embedOptionsIntoLocal(localOptions, options) {
  return options.map((option) => ({
    ...find(localOptions, { externalIdentifier: option.identifier }),
    option,
  }));
}

export function createLocalOptions(options, localOptionCreatorFn) {
  return options.map((option) => ({
    ...localOptionCreatorFn(option),
    option,
  }));
}

export function getEmbeddedOptionIdentifier(localOption) {
  return get(localOption, 'option.identifier', '');
}

export function findByEmbeddedOptionIdentifier(options, embeddedOptionIdentifier) {
  return options.find(({ option: { identifier } }) => identifier === embeddedOptionIdentifier);
}

export function initLocalOptionsWithDefaultOption(localOptions) {
  return localOptions.map((localOption) => ({
    ...localOption,
    option: {
      identifier: localOption.externalIdentifier,
    },
  }));
}

export function createEmbeddedOptions(componentOptions) {
  // embed into local options
  const localOptions = {};

  localOptions.walls = embedOptionsIntoLocal(
    localWallTypes,
    getOptionsByComponentIdentifier(componentOptions, 'walls')
  );

  localOptions.colors = createLocalOptions(
    getOptionsByComponentIdentifier(componentOptions, 'colors'),
    (option) => ({
      value: getOptionAttributeValue(option, 'hex_value'),
    })
  );

  const roofOptions = getOptionsByComponentIdentifier(componentOptions, 'roof');
  localOptions.roof = embedOptionsIntoLocal(
    localRoofTypes,
    filterOptionsByAttributeValue(roofOptions, 'type', 'roof')
  );
  localOptions.roof_window = embedOptionsIntoLocal(
    localRoofWindowTypes,
    filterOptionsByAttributeValue(roofOptions, 'type', 'roof_window')
  );

  return localOptions;
}
