import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const ConfigurationContext = createContext(null);

export const ConfigurationProvider = ({ configuration, children }) => (
  <ConfigurationContext.Provider value={configuration}>{children}</ConfigurationContext.Provider>
);

ConfigurationProvider.propTypes = {
  configuration: PropTypes.object.isRequired,
  children: PropTypes.node,
};
