import { BoxGeometry, Mesh, MeshStandardMaterial, Plane, Vector3 } from 'three';
import { createPointerMoveEventListener } from '../Utils/pointerEvents';
import { getContainerBox, getObjectSize } from './utils/objectSize';
import { findClosestEditable, getIntersection, getIntersectionWithPlane } from './utils/getIntersection';
import { DOOR, GATE, WINDOW } from '../Constants/ObjectTypes';
import { BACK, FRONT, LEFT, RIGHT } from '../Constants/Sides';
import { addData, toBottom } from './constructionTools';
import findObjectByWarehouseObjectRef from './utils/findObjectByWarehouseObjectRef';
import { canObjectBePlacedToSegment } from '../App/Rules/canWallSegmentGetObjectType';
import { MODEL_NAME } from './Constants';
import Observable from '../Utils/Observable';

const stemWidth = 0.115;
const endStemWidth = stemWidth;
const stemDepth = 0.1;
const halfStemWidth = stemWidth * 0.5;

const collisionMaterial = new MeshStandardMaterial({
  color: '#ff5555',
  polygonOffset: true,
  polygonOffsetFactor: -1,
});

function isVerticalMovementAllowed(type) {
  return [WINDOW].indexOf(type) !== -1;
}

export function getHorizontalWallPosition(vec, side) {
  switch (side) {
    case LEFT:
      return vec.x;
    case RIGHT:
      return -vec.x;
    case FRONT:
      return -vec.z;
    case BACK:
      return vec.z;
  }
}

export function setHorizontalWallPosition(vec, side, value) {
  switch (side) {
    case LEFT: {
      vec.x = value;
      break;
    }
    case RIGHT: {
      vec.x = -value;
      break;
    }
    case FRONT: {
      vec.z = -value;
      break;
    }
    case BACK: {
      vec.z = value;
      break;
    }
  }
}

export function getAxisValueBySide(vec, side) {
  switch (side) {
    case LEFT:
    case RIGHT:
      return vec.x;
    case FRONT:
    case BACK:
      return vec.z;
  }
}

export function getSideNormal(side) {
  switch (side) {
    case LEFT:
      return new Vector3(0, 0, 1);
    case RIGHT:
      return new Vector3(0, 0, -1);
    case FRONT:
      return new Vector3(1, 0, 0);
    case BACK:
      return new Vector3(-1, 0, 0);
  }
  throw new Error('Unknown side: ' + side);
}

function getDistanceBySide(vec, side) {
  switch (side) {
    case LEFT:
    case RIGHT:
      return vec.z;
    case FRONT:
    case BACK:
      return vec.x;
  }
}

function createMovementPlaneForObject(object) {
  const wp = new Vector3();
  object.getWorldPosition(wp);
  const { side } = object.userData;
  return new Plane(getSideNormal(side), -Math.abs(getDistanceBySide(wp, side)));
}

function findCollisionObjects(root) {
  const objects = [];
  root.traverse((obj) => obj.collisions && objects.push(obj));
  return objects;
}

function hasCollisions(object) {
  const collisionObjects = findCollisionObjects(object.wall);
  const { wallLightband } = object.wall;
  const objectBox = getContainerBox(object);
  for (const child of [...collisionObjects, wallLightband]) {
    if (!child || child === object || !child.collisions) {
      continue;
    }
    if (objectBox.intersectsBox(getContainerBox(child))) {
      return true;
    }
  }
  return false;
}

export function createSegmentStems(segment, side) {
  const size = getObjectSize(segment);
  const { blockCount, index } = segment.wrappedObject.userData;
  const isFirst = index === 0;
  const isLast = index + 1 === blockCount;
  const stemLeft = new Mesh(
    new BoxGeometry(isFirst ? endStemWidth : stemWidth, size.y, stemDepth),
    collisionMaterial
  );
  const stemRight = new Mesh(
    new BoxGeometry(isLast ? endStemWidth : stemWidth, size.y, stemDepth),
    collisionMaterial
  );
  const endCorrection = Math.abs(stemWidth - endStemWidth) * 0.5;
  stemLeft.position.x = -0.5 * getAxisValueBySide(size, side) + (isFirst ? endCorrection : 0);
  stemRight.position.x = 0.5 * getAxisValueBySide(size, side) + (isLast ? -endCorrection : 0);
  addData(stemLeft, {
    name: 'stemLeft',
  });
  addData(stemRight, {
    name: 'stemRight',
  });
  return [stemLeft, stemRight];
}

export function createWallStems(wall) {
  const { blockCount, length } = wall.userData;
  const size = getObjectSize(wall);
  const segmentWidth = length / blockCount;
  const stems = [];
  let position = -length * 0.5;
  for (let i = 0; i <= blockCount; i++) {
    const isEnd = i === 0 || i === blockCount;
    const stem = new Mesh(
      new BoxGeometry(isEnd ? endStemWidth : stemWidth, size.y, stemDepth),
      collisionMaterial
    );
    stem.position.x = position;
    stem.collisions = true;
    position += segmentWidth;
    stems.push(stem);
  }
  const endCorrection = Math.abs(stemWidth - endStemWidth) * 0.5;
  stems[0].position.x += endCorrection;
  stems[blockCount].position.x -= endCorrection;
  return stems;
}

function createObjectWrapper(object3D) {
  const { parent, warehouseObjectRef: warehouseObject } = object3D;
  const getObjectConfiguration = () => {
    const createSetter = (prop) => (value) => {
      warehouseObject[prop] = value;
      addData(object3D, warehouseObject);
    };
    const getData = () => warehouseObject;
    const setCenterOffsetX = createSetter('centerOffsetX');
    const setCenterOffsetY = createSetter('centerOffsetY');
    const setLeftOffsetX = createSetter('leftOffsetX');
    const setIndex = createSetter('index');
    return {
      setCenterOffsetX,
      setCenterOffsetY,
      setLeftOffsetX,
      setIndex,
      getData,
    };
  };

  let stems;
  const showCollisionObjects = () => {
    if (warehouseObject.absoluteOffset) {
      stems = createWallStems(parent);
    } else {
      stems = createSegmentStems(parent, warehouseObject.side);
    }
    parent.add(...stems);
    stems.forEach((stem) => toBottom(stem));
  };
  const hideCollisionObjects = () => {
    stems && parent.remove(...stems);
  };

  return {
    getObjectConfiguration,
    showCollisionObjects,
    hideCollisionObjects,
  };
}

export function calculateLocalPosition(availableWidth, segmentWidth, absolutePosition) {
  const diffOffset = ((availableWidth / segmentWidth + 1) % 2) * segmentWidth * 0.5;
  const diff = (absolutePosition + diffOffset) % segmentWidth;
  return Math.abs(diff) > segmentWidth * 0.5 ? diff + (diff < 0 ? 1 : -1) * segmentWidth : diff;
}

function calculateSegmentIndex(availableWidth, segmentWidth, absolutePosition) {
  return Math.floor((absolutePosition + availableWidth * 0.5) / segmentWidth);
}

function setTemporaryMaterial(object, material) {
  if (object.material && !object.originalMaterial) {
    object.originalMaterial = object.material;
  }
  if (object.material) {
    object.material = material;
  }
  for (const child of object.children) {
    setTemporaryMaterial(child, material);
  }
}

function restoreOriginalMaterial(object) {
  if (object.originalMaterial) {
    object.material = object.originalMaterial;
  }
  for (const child of object.children) {
    restoreOriginalMaterial(child);
  }
}

export const ObjectControlEvents = {
  movementStart: 'movementStart',
  positionChanged: 'positionChanged',
  movementEnd: 'movementEnd',
};

function isMovableObject(object) {
  if (!object || !object.userData) {
    return false;
  }
  const { group } = object.userData;
  return [GATE, DOOR, WINDOW].indexOf(group) !== -1;
}

export function createObjectControls(visualization, warehouseConfiguration) {
  const { renderer, scene, camera, renderFrame, controls } = visualization;
  let activeObject;
  let startPoint;
  let activeObjectSize;
  let objectWrapper;
  let verticalMovementAllowed;
  let warehouse;
  let movementPlane;
  let invalidSegment;
  let initialPosition;

  const observable = new Observable();
  const { on, dispatch } = observable;

  const setActiveObject = (object, evt) => {
    if (activeObject) {
      // prevent other start events
      return;
    }
    if (object) {
      setStartEvent(evt);
      const { group } = object.userData;
      switch (group) {
        case DOOR:
        case GATE:
        case WINDOW: {
          invalidSegment = false;
          movementPlane = createMovementPlaneForObject(object);
          startPoint = getIntersectionWithPlane(evt, renderer, camera, movementPlane);
          activeObject = object;
          initialPosition = activeObject.position.clone();
          activeObjectSize = getObjectSize(activeObject);
          controls.enabled = false;
          objectWrapper = createObjectWrapper(activeObject);
          objectWrapper.showCollisionObjects();
          if (!activeObject.originalMaterial) {
            activeObject.originalMaterial = activeObject.material;
          }
          verticalMovementAllowed = isVerticalMovementAllowed(
            objectWrapper.getObjectConfiguration().getData().type
          );
          renderFrame();
        }
      }
    }
  };

  const setActiveObjectByRef = (objectRef, evt) => {
    const object = findObjectByWarehouseObjectRef(scene, objectRef);
    setActiveObject(object, evt);
    moveObjectCenterToCursor(objectRef, object, evt);
  };

  const moveObjectCenterToCursor = (warehouseObject, object, evt) => {
    const configurationData = warehouseConfiguration.getData();
    const { height } = configurationData;
    const { side } = warehouseObject;
    setHorizontalWallPosition(startPoint, side, object.position.x);
    startPoint.y = object.position.y + height * 0.5;
    onChange(evt);
  };

  visualization.objectControls = {
    setActiveObject,
    setActiveObjectByRef,
    on,
  };

  const onChange = (evt) => {
    if (activeObject) {
      const { type, side, absoluteOffset } = objectWrapper.getObjectConfiguration().getData();
      const point = getIntersectionWithPlane(evt, renderer, camera, movementPlane);
      if (!point) {
        return;
      }
      const pointDelta = point.sub(startPoint);
      const configurationData = warehouseConfiguration.getData();
      const { xWallSegmentWidth, zWallSegmentWidth, height, width, length } = configurationData;

      const segmentWidth = getAxisValueBySide({ x: xWallSegmentWidth, z: zWallSegmentWidth }, side);

      const availableWidth = absoluteOffset
        ? getAxisValueBySide({ x: length, z: width }, side)
        : segmentWidth;

      // update horizontal position
      const deltaX = getHorizontalWallPosition(pointDelta, side);
      let x = initialPosition.x + deltaX;
      const halfTargetSize = getAxisValueBySide(activeObjectSize, side) * 0.5;
      const xMin = -availableWidth * 0.5 + halfTargetSize + halfStemWidth;
      const xMax = availableWidth * 0.5 - halfTargetSize - halfStemWidth;

      const localPosition = calculateLocalPosition(availableWidth, segmentWidth, x);

      if (x < xMin) {
        x = xMin;
      } else if (x > xMax) {
        x = xMax;
      } else if (Math.abs(localPosition) < 0.5) {
        // snap to center (helps mainly for placing cloned gates)
        x = x - localPosition;
      }
      activeObject.position.x = x;

      if (verticalMovementAllowed) {
        // update vertical position
        const deltaY = pointDelta.y;
        let y = initialPosition.y + deltaY;
        const halfTargetHeight = activeObjectSize.y * 0.5;
        const yMin = -height * 0.5 + halfTargetHeight;
        const yMax = height * 0.5 - halfTargetHeight;
        if (y < yMin) {
          y = yMin;
        } else if (y > yMax) {
          y = yMax;
        }
        activeObject.position.y = y;
      }

      isMovableObject(activeObject) &&
        dispatch(ObjectControlEvents.positionChanged, { object: activeObject });

      invalidSegment =
        absoluteOffset &&
        !canObjectBePlacedToSegment(
          configurationData,
          type,
          side,
          calculateSegmentIndex(availableWidth, segmentWidth, x),
          activeObject.warehouseObjectRef
        );

      if (invalidSegment || hasCollisions(activeObject)) {
        setTemporaryMaterial(activeObject, collisionMaterial);
      } else {
        restoreOriginalMaterial(activeObject);
      }
      renderFrame();
    }
  };

  const { setStartEvent } = createPointerMoveEventListener(renderer.domElement, onChange, {
    onStart: (evt) => {
      if (activeObject) {
        // prevent actions to happen (eg. opening context menu)
        evt.stopPropagation();
        evt.preventDefault();
        return;
      }
      warehouse = scene.getObjectByName(MODEL_NAME);
      const intersect = getIntersection(evt, renderer, camera, warehouse);
      const object = intersect && findClosestEditable(intersect.object);
      object && setActiveObject(object, evt);

      isMovableObject(object) && dispatch(ObjectControlEvents.movementStart, { object });
    },
    onEnd: () => {
      if (activeObject) {
        const objectConfiguration = objectWrapper.getObjectConfiguration();
        if (hasCollisions(activeObject)) {
          activeObject.position.x = objectConfiguration.getData().centerOffsetX;
          if (verticalMovementAllowed) {
            activeObject.position.y = objectConfiguration.getData().centerOffsetY;
          }
        } else {
          objectConfiguration.setCenterOffsetX(activeObject.position.x);
          if (verticalMovementAllowed) {
            objectConfiguration.setCenterOffsetY(activeObject.position.y);
          }
        }
        if (objectConfiguration.getData().absoluteOffset) {
          // update object index
          const { width, length, xWallSegmentWidth, zWallSegmentWidth } = warehouseConfiguration.getData();
          const { side, centerOffsetX } = objectConfiguration.getData();
          const availableWidth = getAxisValueBySide({ x: length, z: width }, side);
          const segmentWidth = getAxisValueBySide({ x: xWallSegmentWidth, z: zWallSegmentWidth }, side);
          objectConfiguration.setIndex(calculateSegmentIndex(availableWidth, segmentWidth, centerOffsetX));
          // update leftOffsetX (so that it can be used by the warehouse builder)
          objectConfiguration.setLeftOffsetX(activeObject.position.x + availableWidth * 0.5);
        }
        objectWrapper.hideCollisionObjects();
        if (!invalidSegment) {
          restoreOriginalMaterial(activeObject);
        }

        isMovableObject(activeObject) && dispatch(ObjectControlEvents.movementEnd, { object: activeObject });

        activeObject = null;
        controls.enabled = true;
        renderFrame();
      }
    },
  });
}
