import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import get from 'lodash/get';
import Error from '@material-ui/icons/Error';
import MainButton from '../../../MainButton';
import CustomDialog from '../../../CustomDialog';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetConfigurationCode,
  resetError,
  saveConfiguration,
} from '../../../../Reducers/Configuration/Actions';
import { setVisible, setWorkInProgress } from '../../../../Reducers/Ui/Actions';
import { ConfigurationContext } from '../../../../ContextProviders/ConfigurationProvider';
import { VisualizationContext } from '../../../../ContextProviders/VisualizationProvider';
import { ActivePopupContext } from '../../../../ContextProviders/ActivePopupProvider';

const useStyles = makeStyles((theme) => ({
  dialogTitleRoot: {
    padding: '12px 12px 20px',
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  dialogContentRoot: {
    padding: '0 24px 0 12px',
  },
  activeButton: {
    backgroundColor: '#ed1c29',
    padding: '6px 15px',
    '&:hover': {
      backgroundColor: '#ed1c29',
      opacity: 0.5,
    },
  },
  saveConfigurationCode: {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 48,
    height: 40,
    fontSize: 24,
    lineHeight: '28px',
    [theme.breakpoints.down('xs')]: {
      height: 56,
      marginBottom: 32,
    },
  },
  TypographyCaption: {
    fontSize: 12,
    lineHeight: 1.7,
  },
  successResult: {
    background: '#ed1c29',
    color: '#fff',
    padding: '14px 8px',
    borderRadius: '4px',
  },
  successResultIcon: {
    float: 'left',
  },
  errorResult: {
    background: '#ED1C29',
    color: '#fff',
    padding: '14px 8px',
    borderRadius: '4px',
    fontSize: 14,
    lineHeight: 1.7,
    textAlign: 'center',
    '& p': { color: '#fff !important' },
  },
  errorResultIcon: {
    float: 'left',
  },
  titleFieldWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 32,
  },
  titleField: {
    fontSize: '26px',
    borderRadius: 4,
    height: 40,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      height: 56,
      width: '100%',
    },
  },
  titleFieldInput: {
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    height: 20,
    padding: '10px 16px',
    minWidth: 320,
    [theme.breakpoints.down('xs')]: {
      padding: '0 5px',
      height: 56,
      minWidth: 50,
    },
  },
  dialogSavingInProgress: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    height: 50,
    marginTop: 24,
  },
}));

const SaveConfigurationDialog = (props) => {
  const { backgroundWorkMap, visibilityMap } = useSelector((state) => state.Ui);
  const { configuration, error } = useSelector((state) => state.Configuration);
  const [title, setTitle] = useState('');
  const customData = useContext(ConfigurationContext);
  const visualization = useContext(VisualizationContext);

  const show = !!visibilityMap.saveConfiguration;
  const workInProgress = !!backgroundWorkMap.saveConfiguration;
  const code = get(configuration, 'code', '');

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    show && dispatch(resetConfigurationCode());
  }, [show, dispatch]);

  const close = useCallback(async () => {
    await dispatch(setVisible('saveConfiguration', false));
    dispatch(resetError());
    // we need to reset the code so a new one is created on next save
    dispatch(resetConfigurationCode());
  }, [dispatch]);

  useContext(ActivePopupContext).useActive('SaveConfiguration', close, show);

  const confirm = async () => {
    await dispatch(setVisible('saveConfiguration', false));
    // we need to reset the code so a new one is created on next save
    dispatch(resetConfigurationCode());
  };

  const onChange = (e) => {
    if (error) {
      dispatch(resetError());
    }
    setTitle(e.target.value);
  };

  const onSaveClick = async () => {
    dispatch(resetError());
    dispatch(setWorkInProgress('saveConfiguration', true));
    const screenshots = (await visualization.getScreenshots()) || {};
    const data = customData.getData();
    dispatch(saveConfiguration(data, screenshots, 'user', { title }));
  };

  return (
    <CustomDialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onCancel={close}
      onClose={close}
      dialogtitle={t('saveConfigurationDialog.dialogTitle')}
    >
      <DialogContent className={classes.dialogContentRoot}>
        {workInProgress && (
          <div className={classes.dialogSavingInProgress}>
            <CircularProgress />
          </div>
        )}
        {!workInProgress && !code && (
          <React.Fragment>
            <Typography
              variant="body1"
              classes={{ root: classes.TypographyCaption }}
              id="alert-dialog-description"
            >
              {t('saveConfigurationDialog.configurationTitleDescription')}
            </Typography>
            <div className={classes.titleFieldWrapper}>
              <TextField
                className={classes.titleField}
                value={title}
                variant={'outlined'}
                onChange={onChange}
                placeholder={t('saveConfigurationDialog.configurationTitle')}
                InputProps={{
                  classes: {
                    input: classes.titleFieldInput,
                  },
                }}
              />
            </div>
          </React.Fragment>
        )}
        {!workInProgress && code && (
          <React.Fragment>
            <Typography
              variant="body1"
              classes={{ root: classes.TypographyCaption }}
              id="alert-dialog-description"
            >
              {t('saveConfigurationDialog.saveSuccessful')}
            </Typography>

            <Typography
              variant="body1"
              classes={{ root: classes.TypographyCaption }}
              id="alert-dialog-description"
            >
              {t('saveConfigurationDialog.loadWithCode')}
            </Typography>
            <Typography
              id="alert-dialog-description"
              variant="h5"
              classes={{ root: classes.saveConfigurationCode }}
            >
              <div className={classes.successResult}>
                <CheckCircle className={classes.successResultIcon} />
                <b>{code}</b>
              </div>
            </Typography>
          </React.Fragment>
        )}
        {error && (
          <div className={classes.errorResult}>
            <Error className={classes.errorResultIcon} />
            <Typography variant="body1">{t('saveConfigurationDialog.saveError')}</Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!workInProgress && code && (
          <MainButton onClick={confirm} variant="contained" size="medium" className={classes.activeButton}>
            {t('saveConfigurationDialog.ok')}
          </MainButton>
        )}
        {!workInProgress && !code && (
          <MainButton
            onClick={onSaveClick}
            variant="contained"
            size="medium"
            className={classes.activeButton}
          >
            {t('saveConfigurationDialog.confirm')}
          </MainButton>
        )}
      </DialogActions>
    </CustomDialog>
  );
};
export default SaveConfigurationDialog;
