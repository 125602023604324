const hostsByNodeEnv = {
  production: '',
  development: 'http://int.warehouseconfigurator.local', //'http://localhost:10030',
  test: 'http://int.warehouseconfigurator.local',
};
const host = hostsByNodeEnv[process.env.NODE_ENV];
const networkConf = {
  baseURL: host,
  timeout: 60000,
};
export default networkConf;
