import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

export const GroupLabel = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
  },
})(Typography);

export default GroupLabel;
