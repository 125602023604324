import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Error from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';

import { validateFieldData } from '../../Utils/Validator';
import ActionButton from '../ActionButton';
import MenuTitleBar from '../MenuTitleBar';
import { useDispatch, useSelector } from 'react-redux';
import { resetConfigurationCode, saveConfiguration } from '../../Reducers/Configuration/Actions';
import { ConfigurationContext } from '../../ContextProviders/ConfigurationProvider';
import { VisualizationContext } from '../../ContextProviders/VisualizationProvider';
import { resetError, resetRequestOffer, sendRequestOffer } from '../../Reducers/Offer/Actions';
import { useForm } from '../../Hooks/useForm';
import { withLocalValue } from '../withLocalValue';
import PrivacyPolicyControl from '../ConfigurationToolbar/PrivacyPolicyControl';
import { ActivePopupContext } from '../../ContextProviders/ActivePopupProvider';

const StyledTextField = withStyles({
  root: {
    marginBottom: 8,
  },
})(withLocalValue(TextField));

const useStyles = makeStyles((theme) => ({
  container: {
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '0 30px',
    },
  },
  actions: {
    padding: 24,
  },
  errorResult: {
    background: '#ED1C29',
    color: '#fff',
    padding: '20px',
    borderRadius: '4px',
    fontSize: 16,
    lineHeight: 1.7,
    marginBottom: 16,
    textAlign: 'center',
    '& p': { color: '#fff !important' },
  },
  errorResultIcon: {
    float: 'left',
  },
}));

const formFields = {
  name: {
    type: 'text',
    autoFocus: true,
    required: true,
    value: '',
  },
  surname: {
    type: 'text',
    required: true,
    value: '',
  },
  company: {
    type: 'text',
    required: true,
    value: '',
  },
  tel: {
    type: 'tel',
    required: true,
    value: '',
  },
  email: {
    type: 'email',
    required: true,
    value: '',
  },
  postalCode: {
    type: 'text',
    required: true,
    value: '',
  },
  address: {
    type: 'text',
    required: true,
    value: '',
  },
  message: {
    type: 'text',
    multiline: true,
    required: true,
    rows: 3,
    rowsMax: 6,
    value: '',
  },
};

const StyledDialogContent = withStyles({
  root: {
    padding: '24px 24px 24px 24px',
  },
})(DialogContent);

const RequestOffer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loadCode } = useSelector((state) => state.Configuration);
  const { error, requestOfferReceived } = useSelector((state) => state.Offer);
  const visualization = useContext(VisualizationContext);
  const customData = useContext(ConfigurationContext);
  const { formData, changeHandler, debouncedChangeHandler, validate, reset } = useForm(formFields, {
    validatorFn: validateFieldData,
  });
  const [privacyPolicyApproved, setPrivacyPolicyApproved] = useState(false);

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (requestOfferReceived && !error) {
      setSuccess(true);
      dispatch(resetRequestOffer());
      reset();
    }
  }, [dispatch, error, requestOfferReceived, reset]);

  const handleClickOpen = async () => {
    setPrivacyPolicyApproved(false);
    await dispatch(resetError());
    await dispatch(resetConfigurationCode());
    const screenshots = (await visualization.getScreenshots()) || {};
    const data = customData.getData();
    dispatch(saveConfiguration(data, screenshots, 'user'));
    setSuccess(false);
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(resetRequestOffer());
    reset();
  }, [dispatch, reset]);

  useContext(ActivePopupContext).useActive('RequestOffer', handleClose, open);

  const sendButtonClickHandler = () => validate() && dispatch(sendRequestOffer(loadCode, formData));

  const dialogContent = success ? (
    <>
      <MenuTitleBar title={t('requestOffer.success.dialogTitle')} onClose={handleClose} />
      <StyledDialogContent>
        <Typography>{t('requestOffer.success.text')}</Typography>
      </StyledDialogContent>

      <DialogActions className={classes.actions}>
        <ActionButton
          label={t('requestOffer.success.okButtonText')}
          onClick={handleClose}
          disabled={!loadCode}
        />
      </DialogActions>
    </>
  ) : (
    <>
      <MenuTitleBar title={t('requestOffer.dialogTitle')} onClose={handleClose} />
      <StyledDialogContent>
        {error && (
          <div className={classes.errorResult}>
            <Error className={classes.errorResultIcon} />
            <Typography variant="body1">{t('requestOffer.loadError')}</Typography>
          </div>
        )}
        {Object.keys(formData).map((name) => (
          <StyledTextField
            variant="outlined"
            margin="dense"
            fullWidth
            key={name}
            name={name}
            {...formData[name]}
            label={t(`requestOffer.fields.${name}`)}
            helperText={formData[name].error && t(`validationErrorTexts.${formData[name].helperText}`)}
            onChange={debouncedChangeHandler}
            onBlur={changeHandler}
          />
        ))}
        <PrivacyPolicyControl onChange={setPrivacyPolicyApproved} checked={privacyPolicyApproved} />
      </StyledDialogContent>

      <DialogActions className={classes.actions}>
        <ActionButton
          label={t('requestOffer.sendButtonText')}
          onClick={sendButtonClickHandler}
          disabled={!loadCode || !privacyPolicyApproved}
        />
      </DialogActions>
    </>
  );

  return (
    <div className={classes.container}>
      <ActionButton label={t('requestOffer.actionButtonText')} onClick={handleClickOpen} />

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {dialogContent}
      </Dialog>
    </div>
  );
};

export default RequestOffer;
