import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const ToolbarButton = withStyles({
  root: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: ({ iconSrc }) => ({
    width: 60,
    height: 70,
    backgroundImage: `url(${iconSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'inline-flex',
    transition: 'all 150ms ease-in-out',
  }),
  active: ({ activeIconSrc }) => ({
    '& > $icon': {
      backgroundImage: `url(${activeIconSrc})`,
    },
  }),
  label: {
    fontSize: '0.9em',
  },
})(({ onClick, active, classes, label }) => {
  return (
    <div className={clsx(classes.root, active && classes.active)} onClick={onClick}>
      <span className={classes.icon} />
      {label && <Typography className={classes.label}>{label}</Typography>}
    </div>
  );
});
