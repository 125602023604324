import { initLocalOptionsWithDefaultOption } from '../Selectors/Option';

export const colors = initLocalOptionsWithDefaultOption([
  {
    value: '#E7EBDA',
    title: 'RAL 9002 - Grauweiß',
    externalIdentifier: 'ral_9002',
  },
  {
    value: '#A5A5A5',
    title: 'RAL 9006 - Weiß-Aluminium',
    externalIdentifier: 'ral_9006',
  },
  {
    value: '#FFFFFF',
    title: 'RAL 9010 - Reinweiß',
    externalIdentifier: 'ral_9010',
  },
  {
    value: '#293122',
    title: 'RAL 7016 - Anthrazit-Grau',
    externalIdentifier: 'ral_7016',
  },
  {
    value: '#642424',
    title: 'RAL 3009 - Oxid-Rot',
    externalIdentifier: 'ral_3009',
  },
]);

export const defaultColor = colors[1];
