import {
  BoxGeometry,
  Color,
  CylinderBufferGeometry,
  DoubleSide,
  Group,
  MathUtils,
  Matrix4,
  Mesh,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Plane,
  Vector3,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { getContainerBox, getGeometrySize, getObjectSize } from './utils/objectSize';
import { sliceByPlanes } from './utils/sliceByPlanes';
import { applyMatrixToGeometry, applyMatrixToGeometryRecursive } from './utils/applyMatrixToGeometry';
import {
  addData,
  cloneElement,
  getWrappedObject,
  repeatElement,
  toBottom,
  toTop,
  wrapWithGroup,
} from './constructionTools';
import { createSampleElements, createSampleTypedSegments } from './sampleElements';
import { NO_ROOF_WINDOW } from '../App/DataStructures/roofWindowTypes';
import { isTextileRoof } from '../App/DataStructures/roofTypes';
import { getRangeObjectSize } from '../App/Selectors/RangeObject';
import { DOOR, GABLE, GATE, ROOF, WALL, WALL_LIGHTBAND, WINDOW } from '../Constants/ObjectTypes';
import { BACK, FRONT, LEFT, RIGHT } from '../Constants/Sides';
import { BOTTOM, MODEL_NAME, TOP } from './Constants';

const zAxis = Object.freeze(new Vector3(0, 0, 1));
const halfPI = Math.PI * 0.5;

// 1 unit = 1 meter
const defaultWallSegmentWidth = 5;
const roofTiltAngle = 18;
const roofEavesOverhang = 0.18;
const roofBottomOffset = 0.03;
const roofProfileHeightCorrection = 0.0098;
const floorPadding = 0.44;
const profileOffset = 0.03;

// virtual hole settings
const holeRenderOrder = 1;
const wallSegmentRenderOrder = 2;

function objectNeedsHole(object) {
  return [WINDOW, WALL_LIGHTBAND].indexOf(object.type) !== -1;
}

const fixedPositionByType = {
  [GATE]: {
    type: BOTTOM,
  },
  [DOOR]: {
    type: BOTTOM,
  },
  [WALL_LIGHTBAND]: {
    type: TOP,
    offset: 0.5,
  },
};

const loadedPrototypes = {
  ...createSampleElements(),
};

const typedSegments5m = {
  trapezoidal_sheet: null,
  ...createSampleTypedSegments(5),
};

const typedSegments4m = {
  trapezoidal_sheet: null,
  ...createSampleTypedSegments(4),
};

const elementPrototypes = {
  // switchable elements
  xWallSegment: null,
  zWallSegment: null,
  roofSegment: null,

  // default elements
  ...loadedPrototypes,
};

export function createGround() {
  const ground = cloneElement(elementPrototypes.ground);
  ground.receiveShadow = true;
  return ground;
}

function createWall(wallSegmentPrototype, segmentHeight = 1, blockCount = 1, data = {}, cloneOptions = {}) {
  const block = cloneElement(wallSegmentPrototype, { cloneMaterial: true });
  block.renderOrder = wallSegmentRenderOrder;
  const size = getObjectSize(block);
  block.scale.set(1, segmentHeight / size.y, 1);
  const wall = repeatElement(block, blockCount, { type: WALL, blockCount, ...data }, cloneOptions);
  wall.position.y = segmentHeight * 0.5;
  return wall;
}

function createGable(segmentWidth, blockCount = 1) {
  const angleRad = MathUtils.degToRad(roofTiltAngle);
  const width = segmentWidth * blockCount;
  const halfWidth = width * 0.5;
  const height = Math.tan(angleRad) * halfWidth;

  const distanceFromOrigin = Math.sin(angleRad) * halfWidth;
  const leftPlane = new Plane(new Vector3(0, -1, 0).applyAxisAngle(zAxis, angleRad), distanceFromOrigin);
  const rightPlane = new Plane(new Vector3(0, -1, 0).applyAxisAngle(zAxis, -angleRad), distanceFromOrigin);

  const wall = createWall(elementPrototypes.zWallSegment, height, blockCount);

  wall.position.y = height * 0.5;

  const gable = new Group();
  gable.add(wall);
  sliceByPlanes(gable, [leftPlane, rightPlane]);

  addData(gable, {
    type: GABLE,
  });

  return gable;
}

function removeLastRoofBar(roofGroup) {
  const roofElements = roofGroup.children;
  const lastBar = roofElements[roofElements.length - 1].getObjectByName('bar');
  lastBar && lastBar.parent.remove(lastBar);
}

function createRoof(segmentWidth, width, length, height = 1, options = {}) {
  const angleRad = MathUtils.degToRad(roofTiltAngle);
  const halfWidth = width * 0.5;

  const roofPrototype = cloneElement(elementPrototypes.roofSegment, { cloneGeometry: true });
  const size = getGeometrySize(roofPrototype);

  const roofSegmentDepthCorrection = Math.tan(angleRad) * size.z;
  const roofSegmentHeight = halfWidth / Math.cos(angleRad) + roofEavesOverhang + roofSegmentDepthCorrection;
  const roofHeight = Math.tan(angleRad) * halfWidth;
  const roofData = { type: ROOF, hasContextMenu: true };
  const cloneOptions = { cloneMaterial: false, cloneGeometry: false };
  const segmentCount = Math.round(length / segmentWidth);

  roofPrototype.geometry.applyMatrix4(new Matrix4().makeScale(1, roofSegmentHeight / size.y, 1));
  roofPrototype.position.y = -roofEavesOverhang * 0.5;

  const bar = roofPrototype.getObjectByName('bar');
  if (bar) {
    const barSize = getGeometrySize(bar);
    bar.geometry.applyMatrix4(new Matrix4().makeScale(1, roofSegmentHeight / barSize.y, 1));
    bar.position.y = Math.tan(angleRad) * barSize.z;
  }

  const leftRoof = repeatElement(roofPrototype, segmentCount, roofData, cloneOptions);
  addData(leftRoof, {
    type: ROOF,
    side: 'left',
  });
  leftRoof.rotateX(-halfPI + angleRad);
  leftRoof.position.z = width * 0.25;
  removeLastRoofBar(leftRoof);

  const rightRoof = repeatElement(roofPrototype, segmentCount, roofData, cloneOptions);
  addData(rightRoof, {
    type: ROOF,
    side: 'right',
  });
  rightRoof.rotateY(Math.PI);
  rightRoof.rotateX(-halfPI + angleRad);
  rightRoof.position.z = -width * 0.25;
  removeLastRoofBar(rightRoof);

  const roofEdgeProfiles = createRoofEdgeProfiles(
    width,
    length,
    roofSegmentHeight - roofSegmentDepthCorrection,
    options
  );

  const roof = new Group();
  roof.add(leftRoof, rightRoof, ...roofEdgeProfiles);

  if (elementPrototypes.roofProfile) {
    const topProfile = createTopRoofProfile(length);
    topProfile.position.y = roofHeight * 0.5 + roofBottomOffset;
    roof.add(topProfile);
  }

  if (elementPrototypes.roofSegmentOverlapping) {
    const roofSize = getObjectSize(leftRoof);
    const roofOverlappingSize = getGeometrySize(elementPrototypes.roofSegmentOverlapping);
    const offsetY = -roofSize.y * 0.5 - roofOverlappingSize.y * 0.5 + Math.cos(angleRad) * size.z * 0.5;
    const overlappingLeft = repeatElement(elementPrototypes.roofSegmentOverlapping, segmentCount);
    removeLastRoofBar(overlappingLeft);
    const offsetZ = roofSize.z + 0.0248;
    overlappingLeft.position.z = offsetZ;
    overlappingLeft.position.y = offsetY;
    const overlappingRight = repeatElement(elementPrototypes.roofSegmentOverlapping, segmentCount);
    removeLastRoofBar(overlappingRight);
    overlappingRight.rotation.y = Math.PI;
    overlappingRight.position.z = -offsetZ;
    overlappingRight.position.y = offsetY;
    roof.add(overlappingLeft, overlappingRight);
  }

  roof.position.y = height + roofHeight * 0.5 + roofBottomOffset;
  return roof;
}

function createTopRoofProfile(length) {
  const profile = cloneElement(elementPrototypes.roofProfile);
  const g = new Group();
  g.add(profile);
  g.scale.set(length, 1, 1);
  return g;
}

function createCornerProfiles(width, length, height) {
  const halfHeight = height * 0.5;
  const halfWidth = width * 0.5;
  const halfLength = length * 0.5;

  const frontRight = cloneElement(elementPrototypes.wallProfile);
  frontRight.scale.y = height;
  frontRight.position.set(halfLength, halfHeight, -halfWidth);
  const backRight = cloneElement(elementPrototypes.wallProfile);
  backRight.rotation.y = halfPI;
  backRight.scale.y = height;
  backRight.position.set(-halfLength, halfHeight, -halfWidth);
  const backLeft = cloneElement(elementPrototypes.wallProfile);
  backLeft.rotation.y = Math.PI;
  backLeft.scale.y = height;
  backLeft.position.set(-halfLength, halfHeight, halfWidth);
  const frontLeft = cloneElement(elementPrototypes.wallProfile);
  frontLeft.rotation.y = 3 * halfPI;
  frontLeft.scale.y = height;
  frontLeft.position.set(halfLength, halfHeight, halfWidth);
  return [frontRight, backRight, backLeft, frontLeft];
}

function createRoofEdgeProfiles(width, length, roofSegmentHeight, options = {}) {
  const { bottomProfile = false } = options;
  const angleRad = MathUtils.degToRad(roofTiltAngle);

  // create corner profiles
  const profileHeight = roofSegmentHeight + roofProfileHeightCorrection;
  const halfProfileOverhang = (roofEavesOverhang - roofProfileHeightCorrection) * 0.5;
  const [backLeft, frontLeft] = createCornerProfiles(width, length, profileHeight);

  const rightGroup = new Group();
  // set y and z to center
  frontLeft.position.y = 0;
  backLeft.position.y = 0;
  frontLeft.position.z = 0;
  backLeft.position.z = 0;
  rightGroup.add(frontLeft, backLeft);

  // add bottom profile if needed
  if (bottomProfile) {
    const leftBottom = cloneElement(elementPrototypes.wallProfile);
    leftBottom.scale.y = getObjectSize(rightGroup).x - 0.016;
    leftBottom.rotation.z = halfPI;
    leftBottom.rotation.x = -halfPI;
    leftBottom.position.y = -profileHeight * 0.5 + 0.022;
    rightGroup.add(leftBottom);
  }

  // apply rotation and positioning on the group
  rightGroup.rotation.x = halfPI - angleRad;
  rightGroup.position.z = -width * 0.25;
  rightGroup.translateY(-halfProfileOverhang);

  // create the left group by cloning the right group
  const leftGroup = rightGroup.clone();
  leftGroup.position.z = -rightGroup.position.z;
  leftGroup.rotation.z = Math.PI;
  leftGroup.rotation.x = halfPI + angleRad;

  return [rightGroup, leftGroup];
}

// eslint-disable-next-line no-unused-vars
function createFloor(width, length) {
  const floor = cloneElement(elementPrototypes.floor);
  floor.scale.set(length + floorPadding, 1, width + floorPadding);
  floor.receiveShadow = true;
  return floor;
}

// eslint-disable-next-line no-unused-vars
function createPrototypePreview() {
  const g = new Group();
  const elements = Object.values(elementPrototypes);
  for (let i = 0; i < elements.length; i++) {
    const preview = cloneElement(elements[i]);
    preview.position.set(30, 0, i * 3);
    g.add(preview);
  }
  return g;
}

function createRainGutter(width, length, height) {
  const rainGutterXOffset = 0.18;
  const rainGutterYOffset = -0.09;
  const halfWidth = width * 0.5;
  const halfLength = length * 0.5;
  const rainGutterLeft = new Group();
  const leftEnd = cloneElement(elementPrototypes.raingutter_left);
  const rightEnd = cloneElement(elementPrototypes.raingutter_right);
  const body = cloneElement(elementPrototypes.raingutter_body);
  const endLength = getObjectSize(leftEnd).x;
  leftEnd.position.x = -halfLength + endLength * 0.5;
  rightEnd.position.x = halfLength - endLength * 0.5;
  const bodySize = getObjectSize(body);
  body.scale.x = (length - endLength * 2) / bodySize.x;
  rainGutterLeft.add(leftEnd, rightEnd, body);
  rainGutterLeft.position.set(0, rainGutterYOffset, halfWidth + rainGutterXOffset);
  const rainGutterRight = rainGutterLeft.clone();
  rainGutterRight.rotateY(Math.PI);
  rainGutterRight.position.z *= -1;
  const g = new Group().add(rainGutterLeft, rainGutterRight);
  g.position.y = height;
  return g;
}

function createRoofWindow(type, segmentWidth, width, length, height) {
  const angleRad = MathUtils.degToRad(roofTiltAngle);
  const halfWidth = width * 0.5;
  const halfLength = length * 0.5;
  const roofHeight = Math.tan(angleRad) * halfWidth;
  const fromDistance = segmentWidth;
  if (type === 'dome_window') {
    const zDistance = 2;
    const yDistance = -0.5;
    const count = Math.floor((length - 2 * fromDistance) / segmentWidth);
    const g = new Group();
    for (let i = 0; i < count; i++) {
      const odd = i % 2;
      const obj = cloneElement(elementPrototypes.domeWindow);
      obj.position.z = odd ? -zDistance : zDistance;
      obj.position.x = fromDistance + segmentWidth / 2 + i * segmentWidth - halfLength;
      obj.rotateX(odd ? -angleRad : angleRad);
      g.add(obj);
    }
    g.position.y = height + roofHeight + yDistance;
    return g;
  }
  if (type === 'ridge_light_band') {
    const ridgeLightBandLength = 20;
    const count = Math.floor((length - 2 * fromDistance) / ridgeLightBandLength);
    const ridgeLightBand = repeatElement(cloneElement(elementPrototypes.ridgeLightband), count);
    ridgeLightBand.position.y = height + roofHeight;
    return ridgeLightBand;
  }
}

function createTextileRoofParts(segmentSource) {
  const roofSegment = cloneElement(segmentSource['textile_pvc_top']);
  const roofSegmentOverlapping = cloneElement(segmentSource['textile_pvc_overlapping']);
  return {
    roofSegment,
    roofSegmentOverlapping,
  };
}

function createRoofParts(segmentSource, roofType) {
  const roofSegment = cloneElement(segmentSource[roofType], { cloneMaterial: true });
  return {
    roofSegment,
    roofSegmentOverlapping: null,
  };
}

function initPrototypes() {
  Object.assign(elementPrototypes, loadedPrototypes);
}

function filterElementPrototypesByGroup(...groups) {
  return Object.values(elementPrototypes).filter(
    (object) => object && object.userData && groups.indexOf(object.userData.group) !== -1
  );
}

function setFixedObjectPosition(element, object) {
  const fixedPosition = fixedPositionByType[object.type];
  if (fixedPosition) {
    const { type, offset } = fixedPosition;
    if (type === BOTTOM) {
      toBottom(element, offset);
    } else if (type === TOP) {
      toTop(element, offset);
    }
  }
}

function localizeGroupChildrenPosition(group) {
  const center = getContainerBox(group).getCenter(new Vector3());
  group.position.copy(center);
  for (const child of group.children) {
    child.position.sub(center);
  }
}

function addHoleForObject(segment, object) {
  const objectSize = getObjectSize(object);
  const segmentSize = getGeometrySize(getWrappedObject(segment));

  const objectWidth = Math.max(objectSize.x, objectSize.z);

  const geometry = new BoxGeometry(objectWidth - 0.01, objectSize.y - 0.01, segmentSize.z + 0.01);
  const hole = new Mesh(geometry, new MeshPhongMaterial({ colorWrite: false }));
  hole.renderOrder = holeRenderOrder;
  object.add(hole);
}

function addObjectToSegment(walls, object) {
  const wall = walls[object.side];
  const segment = wrapWithGroup(wall.children[object.index]);

  const prototype = elementPrototypes[object.variant || object.type];
  if (!prototype) {
    console.error('No prototype found for the following object', object);
    return;
  }

  const element = cloneElement(prototype, { cloneMaterial: true });
  addData(element, { ...object, editable: true });
  element.warehouseObjectRef = object;
  element.wall = wall;
  element.collisions = true;

  element.position.x = object.centerOffsetX;
  if (object.centerOffsetY && !isNaN(object.centerOffsetY)) {
    element.position.y = object.centerOffsetY;
  }
  segment.add(element);
  setFixedObjectPosition(element, object);

  objectNeedsHole(object) && addHoleForObject(segment, element);
}

function buildWallLightband(size, wall) {
  const {
    userData: { length, blockCount },
  } = wall;
  const segmentSize = length / blockCount;
  const width = segmentSize * size;

  const leftPart = cloneElement(elementPrototypes['wall_lightband_left'], { cloneMaterial: true });
  const rightPart = cloneElement(elementPrototypes['wall_lightband_right'], { cloneMaterial: true });

  const leftPartSize = getObjectSize(leftPart);
  const rightPartSize = getObjectSize(rightPart);
  const bodyPartSize = getObjectSize(elementPrototypes['wall_lightband_body']);
  const bodyPartMaxWidth = width - leftPartSize.x - rightPartSize.x;
  const bodyPartCount = Math.floor(bodyPartMaxWidth / bodyPartSize.x);
  const bodyPartWidth = bodyPartSize.x * bodyPartCount;

  const wallLightband = repeatElement(
    elementPrototypes['wall_lightband_body'],
    bodyPartCount,
    {},
    {
      cloneMaterial: true,
    }
  );

  leftPart.position.x = -bodyPartWidth * 0.5 - leftPartSize.x * 0.5;
  rightPart.position.x = bodyPartWidth * 0.5 + rightPartSize.x * 0.5;

  const elements = [leftPart, ...wallLightband.children, rightPart];

  // create groups by segment size

  let groupElements = [];
  let groupWidth = 0;
  const last = elements.length - 1;
  const maxGroupWidth = segmentSize + bodyPartSize.x * 0.5;
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const elementWidth = getObjectSize(element).x;
    const nextWidth = groupWidth + elementWidth;
    if (nextWidth > maxGroupWidth) {
      const g = new Group();
      g.add(...groupElements);
      localizeGroupChildrenPosition(g);
      wallLightband.add(g);
      groupElements = [element];
      groupWidth = elementWidth;
    } else if (last === i) {
      groupElements.push(element);
      const g = new Group();
      g.add(...groupElements);
      localizeGroupChildrenPosition(g);
      wallLightband.add(g);
    } else {
      groupElements.push(element);
      groupWidth = nextWidth;
    }
  }

  return wallLightband;
}

function addWallLightband(walls, object) {
  const wall = walls[object.side];
  const segment = getWrappedObject(wall.children[object.indexFrom]);

  // check if wallLightBandReference exists in the segment
  if (segment.wallLightband) {
    return; // already added, skip
  }

  // get wallLightBand size
  const { from, to, size } = getRangeObjectSize(object);

  const element = buildWallLightband(size, wall);

  element.collisions = true;
  wall.add(element);

  const segmentGroups = element.children;
  for (let i = 0; i < segmentGroups.length; i++) {
    const group = segmentGroups[i];
    addData(group, { ...object, from, to, size, rangeIndex: i, editable: true });
    group.warehouseObjectRef = object;
    group.wall = wall;
    addHoleForObject(segment, group);
  }

  const positionX = (wall.children[from].position.x + wall.children[to].position.x) * 0.5;
  element.position.set(positionX, 0, segment.position.z);

  // set a reference in all crossed segments
  for (let i = from; i <= to; i++) {
    getWrappedObject(wall.children[i]).wallLightband = element;
  }

  setFixedObjectPosition(element, object);
}

function addObjectToWall(walls, object) {
  const wall = walls[object.side];

  const prototype = elementPrototypes[object.variant || object.type];
  if (!prototype) {
    console.error('No prototype found for the following object', object);
    return;
  }

  const element = cloneElement(prototype, { cloneMaterial: true });
  addData(element, { ...object, editable: true });
  element.warehouseObjectRef = object;
  element.wall = wall;
  element.collisions = true;

  // using leftOffsetX makes absolutely positioned elements stick to their place when size changes
  if (object.leftOffsetX) {
    element.position.x = object.leftOffsetX - wall.userData.length * 0.5;
  } else {
    element.position.x = object.centerOffsetX;
  }
  if (object.centerOffsetY && !isNaN(object.centerOffsetY)) {
    element.position.y = object.centerOffsetY;
  }
  wall.add(element);
  setFixedObjectPosition(element, object);

  objectNeedsHole(object) && addHoleForObject(wall.children[0], element);
}

function addObjects(walls, objects) {
  for (const object of objects) {
    switch (object.type) {
      case WALL_LIGHTBAND: {
        addWallLightband(walls, object);
        break;
      }
      default: {
        if (object.absoluteOffset) {
          addObjectToWall(walls, object);
        } else {
          addObjectToSegment(walls, object);
        }
      }
    }
  }
}

export function toLinear(color) {
  return new Color(color).convertSRGBToLinear();
}

export function createWarehouse(options = {}) {
  const {
    width = 15,
    length = 15,
    height = 4.2,
    xWallSegmentWidth = 5,
    zWallSegmentWidth = 5,
    objects = [],
    wallType,
    wallColor,
    roofType,
    roofColor,
    consoleColor,
    rainGutter,
    roofWindowType,
    doorColor,
    gateColor,
    windowColor,
  } = options;

  initPrototypes();

  // z wall segment is always 5 m, use the default type list
  if (wallType && typedSegments5m[wallType.variant]) {
    elementPrototypes.zWallSegment = cloneElement(typedSegments5m[wallType.variant]);
  }

  // x wall segment can be 5 or 4 m
  const xSegmentSource = xWallSegmentWidth === 4 ? typedSegments4m : typedSegments5m;
  if (wallType && xSegmentSource[wallType.variant]) {
    elementPrototypes.xWallSegment = cloneElement(xSegmentSource[wallType.variant]);
  }
  if (roofType && xSegmentSource[roofType.variant]) {
    Object.assign(elementPrototypes, createRoofParts(xSegmentSource, roofType.variant));
  } else if (isTextileRoof(roofType)) {
    Object.assign(elementPrototypes, createTextileRoofParts(xSegmentSource));
  }

  if (wallColor) {
    const color = toLinear(wallColor.value);
    elementPrototypes.xWallSegment.material.color.set(color);
    elementPrototypes.zWallSegment.material.color.set(color);
  }
  if (consoleColor) {
    const color = toLinear(consoleColor.value);
    elementPrototypes.wallProfile.material.color.set(color);
    elementPrototypes.roofProfile && elementPrototypes.roofProfile.material.color.set(color);
    elementPrototypes.floor.setColor(color);
  }
  if (roofColor && !isTextileRoof(roofType)) {
    elementPrototypes.roofSegment.material.color.set(toLinear(roofColor.value));
  }
  if (doorColor) {
    elementPrototypes.door.setColor(toLinear(doorColor.value));
  }
  if (gateColor) {
    const color = toLinear(gateColor.value);
    filterElementPrototypesByGroup(GATE).forEach((object) => object.setColor(color));
  }
  if (windowColor) {
    const color = toLinear(windowColor.value);
    filterElementPrototypesByGroup(WINDOW, WALL_LIGHTBAND).forEach((object) => object.setColor(color));
  }

  const lengthSegments = length / xWallSegmentWidth;
  const widthSegments = width / zWallSegmentWidth;
  const halfWidth = width * 0.5;
  const halfLength = length * 0.5;

  const warehouse = new Group();

  const cloneOptions = {
    cloneMaterial: true,
  };

  const leftSide = createWall(
    elementPrototypes.xWallSegment,
    height,
    lengthSegments,
    {
      side: LEFT,
      hasContextMenu: true,
      editable: true,
      length,
    },
    cloneOptions
  );
  leftSide.position.z = halfWidth;
  const rightSide = createWall(
    elementPrototypes.xWallSegment,
    height,
    lengthSegments,
    {
      side: RIGHT,
      hasContextMenu: true,
      editable: true,
      length,
    },
    cloneOptions
  );
  rightSide.rotation.y = Math.PI;
  rightSide.position.z = -halfWidth;

  const frontSide = createWall(
    elementPrototypes.zWallSegment,
    height,
    widthSegments,
    {
      side: FRONT,
      hasContextMenu: true,
      editable: true,
      length: width,
    },
    cloneOptions
  );
  frontSide.rotation.y = halfPI;
  frontSide.position.x = halfLength;
  const backSide = createWall(
    elementPrototypes.zWallSegment,
    height,
    widthSegments,
    {
      side: BACK,
      hasContextMenu: true,
      editable: true,
      length: width,
    },
    cloneOptions
  );
  backSide.rotation.y = -halfPI;
  backSide.position.x = -halfLength;

  const cornerProfiles = createCornerProfiles(width, length, height);

  const roof = createRoof(xWallSegmentWidth, width, length, height, {
    bottomProfile: !rainGutter && !isTextileRoof(roofType),
  });

  const gableFront = createGable(zWallSegmentWidth, widthSegments);
  gableFront.rotation.copy(frontSide.rotation);
  gableFront.position.copy(frontSide.position);
  gableFront.position.y = height;

  const gableBack = createGable(zWallSegmentWidth, widthSegments);
  gableBack.rotation.copy(backSide.rotation);
  gableBack.position.copy(backSide.position);
  gableBack.position.y = height;

  warehouse.add(...cornerProfiles, leftSide, rightSide, frontSide, backSide, roof, gableFront, gableBack);

  if (rainGutter) {
    const rainGutterObj = createRainGutter(width, length, height);
    if (isTextileRoof(roofType)) {
      const box = getContainerBox(roof);
      rainGutterObj.position.y = box.min.y + getObjectSize(rainGutterObj).y * 0.5 - 0.1;
    }
    warehouse.add(rainGutterObj);
  }

  if (roofWindowType && roofWindowType !== NO_ROOF_WINDOW) {
    warehouse.add(createRoofWindow(roofWindowType.value, xWallSegmentWidth, width, length, height));
  }

  if (objects.length) {
    addObjects(
      {
        left: leftSide,
        right: rightSide,
        front: frontSide,
        back: backSide,
      },
      objects
    );
  }

  addData(warehouse, { name: MODEL_NAME });

  return warehouse;
}

export function createFrontMarker() {
  const geometry = new CylinderBufferGeometry(0.5, 0, 2, 32);
  geometry.computeVertexNormals();
  const material = new MeshStandardMaterial({ color: 0xffff00 });
  const cylinder = new Mesh(geometry, material);
  cylinder.position.set(20, 1, 0);
  return cylinder;
}

function createLoader() {
  return new GLTFLoader();
}

function loadModel(loader, path) {
  return new Promise((resolve, reject) => {
    loader.load(path, resolve, undefined, reject);
  });
}

function applyEncoding(root, encoding) {
  root.traverse((object) => {
    if (object.material && object.material.map) {
      object.material.map.encoding = encoding;
    }
  });
}

function applyEnvMapIntensity(root, value) {
  root.traverse((object) => {
    if (object.material) {
      object.material.envMapIntensity = value;
    }
  });
}

function applyEnvMap(root, texture) {
  root.traverse((object) => {
    if (object.material) {
      object.material.envMap = texture;
    }
  });
}

function centerGeometryYRecursive(object, targetY) {
  if (undefined === targetY) {
    const box = getContainerBox(object);
    targetY = -(box.max.y + box.min.y) * 0.5;
  }
  if (object.geometry) {
    object.geometry.applyMatrix4(new Matrix4().makeTranslation(0, targetY, 0));
  }
  for (const child of object.children) {
    centerGeometryYRecursive(child, targetY);
  }
}

function fixWallMaterial(material) {
  material.roughness = 0.3;
  material.metalness = 0.3;
}

function fixElementMaterial(material) {
  material.roughness = 0.25;
  material.metalness = 0.3;
}

function fixProfileMaterial(material) {
  material.roughness = 0.2;
  material.metalness = 0.3;
}

function createModelLoader(basePath, options = {}) {
  const loader = createLoader();
  loader.setPath(basePath);

  const partList = [];

  const { encoding, envMapIntensity, envMap } = options;

  const handlers = {};

  async function loadPart(name) {
    loader.setResourcePath(`${basePath}${name}/`);
    const gltf = await loadModel(loader, `${name}/${name}.gltf`);
    encoding && applyEncoding(gltf.scene, encoding);
    Number.isFinite(envMapIntensity) && applyEnvMapIntensity(gltf.scene, envMapIntensity);
    envMap && applyEnvMap(gltf.scene, envMap);
    handlers[name] && handlers[name](gltf);
    return gltf;
  }

  function loadAll() {
    return Promise.all(partList.map(loadPart));
  }

  function addHandler(name, fn) {
    partList.push(name);
    handlers[name] = fn;
  }

  return {
    loadAll,
    addHandler,
    loadPart,
  };
}

export function createModelPartsLoader(options = {}) {
  const { addHandler, loadAll } = createModelLoader('3d/models/', options);

  addHandler('trapezoidal_sheet', (gltf) => {
    const trapezoidal_sheet_500 = gltf.scene.getObjectByName('trapezoidal_sheet_500');
    trapezoidal_sheet_500.geometry.center();
    trapezoidal_sheet_500.scale.set(1, 1, 1);
    trapezoidal_sheet_500.position.set(0, 0, 0);
    applyMatrixToGeometry(trapezoidal_sheet_500);
    fixWallMaterial(trapezoidal_sheet_500.material);
    typedSegments5m.trapezoidal_sheet = trapezoidal_sheet_500;
    const trapezoidal_sheet_400 = gltf.scene.getObjectByName('trapezoidal_sheet_400');
    trapezoidal_sheet_400.geometry.center();
    trapezoidal_sheet_400.scale.set(1, 1, 1);
    trapezoidal_sheet_400.position.set(0, 0, 0);
    applyMatrixToGeometry(trapezoidal_sheet_400);
    fixWallMaterial(trapezoidal_sheet_400.material);
    typedSegments4m.trapezoidal_sheet = trapezoidal_sheet_400;
  });

  addHandler('sandwich_walls', (gltf) => {
    const sandwich_wall_500 = gltf.scene.getObjectByName('sandwich_wall_500');
    sandwich_wall_500.geometry.center();
    sandwich_wall_500.scale.set(1, 1, 1);
    sandwich_wall_500.position.set(0, 0, 0);
    sandwich_wall_500.rotateY(Math.PI);
    applyMatrixToGeometry(sandwich_wall_500);
    fixWallMaterial(sandwich_wall_500.material);
    typedSegments5m.sandwich = sandwich_wall_500;
    const sandwich_wall_400 = gltf.scene.getObjectByName('sandwich_wall_400');
    sandwich_wall_400.geometry.center();
    sandwich_wall_400.scale.set(1, 1, 1);
    sandwich_wall_400.position.set(0, 0, 0);
    sandwich_wall_400.rotateY(Math.PI);
    applyMatrixToGeometry(sandwich_wall_400);
    fixWallMaterial(sandwich_wall_400.material);
    typedSegments4m.sandwich = sandwich_wall_400;
  });

  addHandler('profiles', (gltf) => {
    const profile_500 = gltf.scene.getObjectByName('profile_500');
    applyMatrixToGeometry(profile_500);
    const { geometry } = profile_500;
    const size = getObjectSize(profile_500);
    geometry.center();
    geometry.applyMatrix4(new Matrix4().makeScale(1, 1 / size.y, 1));
    geometry.applyMatrix4(new Matrix4().makeRotationY(Math.PI));
    geometry.applyMatrix4(
      new Matrix4().makeTranslation(-size.x / 2 + profileOffset, 0, size.z / 2 - profileOffset)
    );
    fixProfileMaterial(profile_500.material);
    loadedPrototypes.wallProfile = profile_500;
  });

  addHandler('roof_top_profile', (gltf) => {
    const object = gltf.scene.getObjectByName('roof_top_profile_500');
    applyMatrixToGeometry(object);
    const { geometry } = object;
    const size = getObjectSize(object);
    geometry.center();
    geometry.applyMatrix4(new Matrix4().makeScale(1 / size.x, 1, 1));
    geometry.applyMatrix4(new Matrix4().makeTranslation(0, -0.06, 0));
    geometry.computeVertexNormals();
    object.material.side = DoubleSide;
    fixProfileMaterial(object.material);
    loadedPrototypes.roofProfile = object;
  });

  addHandler('door', (gltf) => {
    const door = cloneElement(gltf.scene.getObjectByName('door'));
    applyMatrixToGeometryRecursive(door);
    centerGeometryYRecursive(door);
    addData(door, {
      group: DOOR,
    });
    door.setColor = function (color) {
      this.getObjectByName('door_1_0').material.color.set(color);
    };
    fixElementMaterial(door.children[0].material);
    fixElementMaterial(door.children[1].material);
    loadedPrototypes.door = door;
  });

  addHandler('windows', (gltf) => {
    const window_01x = cloneElement(gltf.scene.getObjectByName('window_01x'), { cloneMaterial: true });
    window_01x.position.set(0, 0, 0);
    addData(window_01x, {
      group: WINDOW,
    });
    window_01x.setColor = function (color) {
      this.getObjectByName('window_01x_2_1').material.color.set(color);
    };
    applyMatrixToGeometryRecursive(window_01x);
    fixElementMaterial(window_01x.getObjectByName('window_01x_2_1').material);

    const window_02x = cloneElement(gltf.scene.getObjectByName('window_02x'), { cloneMaterial: true });
    window_02x.position.set(0, 0, 0);
    addData(window_02x, {
      group: WINDOW,
    });
    window_02x.setColor = function (color) {
      this.getObjectByName('window_02x_2_1').material.color.set(color);
    };
    applyMatrixToGeometryRecursive(window_02x);
    fixElementMaterial(window_02x.getObjectByName('window_02x_2_1').material);

    const window_03x = cloneElement(gltf.scene.getObjectByName('window_03x'), { cloneMaterial: true });
    window_03x.position.set(0, 0, 0);
    addData(window_03x, {
      group: WINDOW,
    });
    window_03x.setColor = function (color) {
      this.getObjectByName('window_03x_2_1').material.color.set(color);
    };
    applyMatrixToGeometryRecursive(window_03x);
    fixElementMaterial(window_03x.getObjectByName('window_03x_2_1').material);

    loadedPrototypes.window_1leaves = window_01x;
    loadedPrototypes.window_2leaves = window_02x;
    loadedPrototypes.window_3leaves = window_03x;
  });

  addHandler('ventilation_grille_300', (gltf) => {
    const ventilation_grille_100 = cloneElement(gltf.scene.getObjectByName('ventilation_grille_100'), {
      cloneMaterial: true,
    });
    ventilation_grille_100.rotateY(Math.PI);
    ventilation_grille_100.position.set(0, 0, 0);
    addData(ventilation_grille_100, {
      group: WINDOW,
    });
    ventilation_grille_100.setColor = function (color) {
      this.getObjectByName('ventilation_grille_100_1_0').material.color.set(color);
    };
    fixElementMaterial(ventilation_grille_100.getObjectByName('ventilation_grille_100_1_0').material);

    const ventilation_grille_300 = cloneElement(gltf.scene.getObjectByName('ventilation_grille_300'), {
      cloneMaterial: true,
    });
    ventilation_grille_300.rotateY(Math.PI);
    ventilation_grille_300.position.set(0, 0, 0);
    addData(ventilation_grille_300, {
      group: WINDOW,
    });
    ventilation_grille_300.setColor = function (color) {
      this.getObjectByName('ventilation_grille_300_1_0').material.color.set(color);
    };
    fixElementMaterial(ventilation_grille_300.getObjectByName('ventilation_grille_300_1_0').material);

    loadedPrototypes.ventilation_grille_100 = ventilation_grille_100;
    loadedPrototypes.ventilation_grille_300 = ventilation_grille_300;
  });

  addHandler('rolling_door', (gltf) => {
    const { scene } = gltf;
    const rolling_gate_350_insulated = prepareGate(scene, 'rolling_gate_350_insulated');
    const rolling_gate_420_insulated = prepareGate(scene, 'rolling_gate_420_insulated');
    const rolling_gate_350_singleshell = prepareGate(scene, 'rolling_gate_350_singleshell_');
    const rolling_gate_420_singleshell = prepareGate(scene, 'rolling_gate_420_singleshell');

    Object.assign(loadedPrototypes, {
      rolling_gate_350_insulated,
      rolling_gate_420_insulated,
      rolling_gate_350_singleshell,
      rolling_gate_420_singleshell,
    });
  });

  addHandler('sectional_door', (gltf) => {
    const { scene } = gltf;
    const sectional_gate_350 = prepareGate(scene, 'sectional_gate_350_');
    const sectional_gate_420 = prepareGate(scene, 'sectional_gate_420');

    Object.assign(loadedPrototypes, {
      sectional_gate_350,
      sectional_gate_420,
    });
  });

  addHandler('pvc_gate', (gltf) => {
    const { scene } = gltf;
    const pvc_gate_350 = prepareGate(scene, 'pvc_gate_350_');
    const pvc_gate_420 = prepareGate(scene, 'pvc_gate_420');

    Object.assign(loadedPrototypes, {
      pvc_gate_350,
      pvc_gate_420,
    });
  });

  addHandler('ridge_lightband', (gltf) => {
    const ridgeLightband = cloneElement(gltf.scene.getObjectByName('ridge_lightband'));
    Object.assign(loadedPrototypes, {
      ridgeLightband,
    });
  });

  addHandler('domelight', (gltf) => {
    const domeWindow = cloneElement(gltf.scene.getObjectByName('domelight_'));
    domeWindow.translateY(-0.1);
    Object.assign(loadedPrototypes, {
      domeWindow,
    });
  });

  addHandler('wall_lightband_01', (gltf) => {
    function setColor(color) {
      this.children[1].material.color.set(color);
    }

    function prepareWallLightband(root, name) {
      const wallLightband = cloneElement(root.getObjectByName(name));
      wallLightband.position.set(0, 0, 0);
      wallLightband.setColor = setColor;
      addData(wallLightband, {
        group: WALL_LIGHTBAND,
      });
      fixElementMaterial(wallLightband.children[1].material);
      return wallLightband;
    }

    const { scene } = gltf;

    const wall_lightband_left = prepareWallLightband(scene, 'wall_lightband_left');
    const wall_lightband_body = prepareWallLightband(scene, 'wall_lightband_body');
    const wall_lightband_right = prepareWallLightband(scene, 'wall_lightband_right');

    Object.assign(loadedPrototypes, {
      wall_lightband_left,
      wall_lightband_body,
      wall_lightband_right,
    });
  });

  addHandler('raingutter', (gltf) => {
    const raingutter_left = cloneElement(gltf.scene.getObjectByName('raingutter_left'));
    const raingutter_body = cloneElement(gltf.scene.getObjectByName('raingutter_body'));
    const raingutter_right = cloneElement(gltf.scene.getObjectByName('raingutter_right'));
    fixProfileMaterial(raingutter_body.material);
    Object.assign(loadedPrototypes, {
      raingutter_left,
      raingutter_body,
      raingutter_right,
    });
  });

  addHandler('textil_500', (gltf) => {
    Object.assign(
      typedSegments5m,
      prepareTextileRoof(gltf.scene, {
        topElementName: 'textile_pvc_top_500',
        topBarName: 'textile_bar_top_500',
        overlappingElementName: 'textile_pvc_overlapping_500',
        overlappingBarName: 'textile_bar_overlapping_500',
      })
    );

    Object.assign(
      typedSegments4m,
      prepareTextileRoof(gltf.scene, {
        topElementName: 'textile_pvc_top_500',
        topBarName: 'textile_bar_top_500',
        overlappingElementName: 'textile_pvc_overlapping_500',
        overlappingBarName: 'textile_bar_overlapping_500',
        width: 4,
      })
    );
  });

  return {
    loadAll,
  };
}

function prepareGate(root, name) {
  const gate = cloneElement(root.getObjectByName(name), {
    cloneMaterial: true,
  });
  gate.position.set(0, 0, -0.2);
  applyMatrixToGeometryRecursive(gate);
  centerGeometryYRecursive(gate);
  addData(gate, {
    group: GATE,
  });
  fixElementMaterial(gate.children[0].material);
  fixElementMaterial(gate.children[1].material);
  gate.setColor = function (color) {
    this.children[0].material.color.set(color);
  };
  return gate;
}

function prepareTextileRoof(
  root,
  { topElementName, topBarName, overlappingElementName, overlappingBarName, width }
) {
  const textile_pvc_top = cloneElement(root.getObjectByName(topElementName), { cloneGeometry: true });
  applyMatrixToGeometryRecursive(textile_pvc_top);
  const textile_bar_top = cloneElement(root.getObjectByName(topBarName), { cloneGeometry: true });
  textile_bar_top.name = 'bar';
  textile_pvc_top.position.set(0, 0, 0);
  applyMatrixToGeometryRecursive(textile_bar_top);
  textile_pvc_top.add(textile_bar_top);

  const angleRad = MathUtils.degToRad(roofTiltAngle);
  const plane = new Plane(new Vector3(0, 0, 1), 0);
  sliceByPlanes(textile_pvc_top, [plane]);

  if (width) {
    textile_pvc_top.geometry.applyMatrix4(new Matrix4().makeScale(width / defaultWallSegmentWidth, 1, 1));
  }

  textile_pvc_top.rotateX(halfPI - angleRad - 0.002);
  applyMatrixToGeometryRecursive(textile_pvc_top);
  textile_pvc_top.geometry.center();
  textile_bar_top.geometry.center();

  const textileBarTopSize = getObjectSize(textile_bar_top);
  textile_bar_top.scale.set(1, 1, 1);
  textile_bar_top.position.set(
    getObjectSize(textile_pvc_top).x * 0.5 + textileBarTopSize.x * 0.5,
    0,
    textileBarTopSize.z
  );
  fixProfileMaterial(textile_bar_top.material);

  const textile_pvc_overlapping = cloneElement(root.getObjectByName(overlappingElementName), {
    cloneGeometry: true,
  });
  const textile_bar_overlapping = cloneElement(root.getObjectByName(overlappingBarName), {
    cloneGeometry: true,
  });
  textile_bar_overlapping.name = 'bar';
  applyMatrixToGeometryRecursive(textile_pvc_overlapping);
  applyMatrixToGeometryRecursive(textile_bar_overlapping);
  textile_pvc_overlapping.add(textile_bar_overlapping);

  sliceByPlanes(textile_pvc_overlapping, [plane]);

  if (width) {
    textile_pvc_overlapping.geometry.applyMatrix4(new Matrix4().makeScale(width / 5, 1, 1));
  }

  textile_pvc_overlapping.position.set(0, 0, 0);
  textile_pvc_overlapping.geometry.center();
  textile_bar_overlapping.geometry.center();
  textile_bar_overlapping.position.set(
    getObjectSize(textile_pvc_overlapping).x * 0.5 + getObjectSize(textile_bar_overlapping).x * 0.5,
    0.0271,
    -0.0181
  );
  fixProfileMaterial(textile_bar_overlapping.material);

  return {
    textile_pvc_top,
    textile_pvc_overlapping,
  };
}

export function createEnvironmentLoader(options) {
  const { loadPart } = createModelLoader('3d/environments/', options);
  const { cleanUpBeforeLoad } = options;

  let environment;
  let currentEnv;

  async function load(warehouseConfiguration) {
    const lastEnv = currentEnv;
    if (warehouseConfiguration.length > 50) {
      currentEnv = 'env_10_09_weit';
    } else {
      currentEnv = 'env_10_08';
    }
    if (lastEnv !== currentEnv) {
      cleanUpBeforeLoad && cleanUpBeforeLoad(environment);
      environment = (await loadPart(currentEnv)).scene;
    }
    return environment;
  }

  return {
    load,
  };
}
