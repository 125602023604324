import { Matrix4 } from 'three';

export function applyMatrixToGeometry(object) {
  object.updateMatrix();
  object.geometry.applyMatrix4(object.matrix);
  object.applyMatrix4(new Matrix4().getInverse(object.matrix));
  return object;
}

export function applyMatrixToGeometryRecursive(object) {
  object.updateWorldMatrix(true, true);
  if (object.geometry) {
    object.geometry.applyMatrix4(object.matrixWorld);
  }
  for (const child of object.children) {
    applyMatrixToGeometryRecursive(child);
  }
  object.applyMatrix4(new Matrix4().getInverse(object.matrix));
  object.updateWorldMatrix(true, true);
  return object;
}
