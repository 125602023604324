import { useEffect, useState } from 'react';

const useConfigurationChanges = (target, propFilter = null) => {
  const [state, setState] = useState(target.getData());
  useEffect(() => {
    return target.onChange(({ data: { state: _state } }) => setState({ ..._state }), propFilter);
  });
  return state;
};

export default useConfigurationChanges;
