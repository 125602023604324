import { Euler, Group, Vector3 } from 'three';
import { getObjectSize } from './utils/objectSize';

/**
 * @param elementPrototype
 * @param {object} [options]
 * @param {boolean} [options.cloneMaterial]
 * @param {boolean} [options.cloneGeometry]
 * @return {*}
 */
export function cloneElement(elementPrototype, options = {}) {
  const { cloneMaterial = false, cloneGeometry = false } = options;
  const element = elementPrototype.clone(true);
  if (cloneGeometry) {
    if (element.geometry) {
      element.geometry = element.geometry.clone();
    }
    element.traverse((child) => {
      if (child.geometry) {
        child.geometry = child.geometry.clone();
      }
    });
  }
  if (cloneMaterial) {
    if (element.material) {
      element.material = element.material.clone();
    }
    element.traverse((child) => {
      if (child.material) {
        child.material = child.material.clone();
      }
    });
  }
  return element;
}

export function addData(object, data) {
  Object.assign(object.userData, data);
  if (data.name) {
    object.name = data.name;
  }
}

export function repeatElement(elementPrototype, count, data = {}, cloneOptions = {}) {
  const size = getObjectSize(elementPrototype);
  const width = size.x;
  count = Math.round(count);
  let offset = -((count - 1) * width) * 0.5;
  const g = new Group();
  for (let i = 0; i < count; i++) {
    const block = cloneElement(elementPrototype, cloneOptions);
    addData(block, {
      index: i,
      ...data,
    });
    block.position.x = offset;
    offset += width;
    g.add(block);
  }
  addData(g, data);
  return g;
}

export function wrapWithGroup(object) {
  if (object.isWrapperGroup) {
    return object;
  }
  const g = new Group();
  const parent = object.parent;
  const rot = new Euler().copy(object.rotation);
  const pos = new Vector3().copy(object.position);
  g.position.copy(object.position);
  if (parent) {
    const idx = parent.children.indexOf(object);
    parent.children[idx] = g;
    g.parent = parent;
  }
  g.add(object);
  g.rotation.copy(rot);
  g.position.copy(pos);
  object.rotation.set(0, 0, 0);
  object.position.set(0, 0, 0);
  g.wrappedObject = object;
  g.isWrapperGroup = true;
  object.wrapperObject = g;
  addData(g, {
    name: 'wrapperGroup',
  });
  return g;
}

export function getWrappedObject(object) {
  return object.isWrapperGroup ? object.wrappedObject : object;
}

export function toBottom(object, offset = 0) {
  object.position.y = -getObjectSize(object.parent).y * 0.5 + getObjectSize(object).y * 0.5 + (offset || 0);
}
export function toTop(object, offset = 0) {
  object.position.y = getObjectSize(object.parent).y * 0.5 - getObjectSize(object).y * 0.5 - (offset || 0);
}
