import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    titleTypography: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    closeButton: {
      marginRight: -12,
      marginLeft: 24,
      marginTop: -12,
      marginBottom: -12,
    },
  };
});

// eslint-disable-next-line no-unused-vars
export const MenuTitleBar = forwardRef(({ title, onClose, divider }, ref) => {
  const classes = useStyles();
  return (
    <>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.titleTypography} variant={'h3'}>
          {title}
        </Typography>
        {onClose && (
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      {divider && <Divider />}
    </>
  );
});

MenuTitleBar.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  divider: PropTypes.bool,
};

MenuTitleBar.defaultProps = {
  divider: true,
};

MenuTitleBar.displayName = 'MenuTitleBar';

export default MenuTitleBar;
