import debounce from '@material-ui/core/utils/debounce';
import { useCallback, useMemo, useState } from 'react';
const alwaysValid = () => true;

export const useForm = (fields, options = {}) => {
  const { validatorFn = alwaysValid } = options;

  const [formData, setFormData] = useState(fields);

  const changeHandler = useCallback(
    (name, value) => {
      setFormData((formData) => {
        const fieldData = { ...formData[name], value };
        return {
          ...formData,
          [name]: validatorFn(fieldData),
        };
      });
    },
    [setFormData, validatorFn]
  );

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const validate = useCallback(() => {
    const validatedFields = {};
    let isAllValid = true;
    for (const key of Object.keys(fields)) {
      const validatedFieldData = validatorFn(formData[key]);
      validatedFields[key] = validatedFieldData;
      isAllValid = isAllValid && !validatedFieldData.error;
    }
    !isAllValid && setFormData(validatedFields);
    return isAllValid;
  }, [formData, fields, validatorFn, setFormData]);

  const reset = useCallback(() => setFormData(fields), [fields, setFormData]);

  const getValues = () => {
    const values = {};
    for (const key of Object.keys(fields)) {
      values[key] = formData[key].value;
    }
    return values;
  };

  return {
    formData,
    changeHandler,
    debouncedChangeHandler,
    validate,
    reset,
    getValues,
  };
};
