export const SET_VISIBLE = 'UI_SET_VISIBLE';
export const SET_WORK_IN_PROGRESS = 'UI_SET_WORK_IN_PROGRESS';
export const SHOW_CUSTOM_ERROR = 'UI_SHOW_CUSTOM_ERROR';
export const DISMISS_CUSTOM_ERROR = 'UI_DISMISS_CUSTOM_ERROR';

const setVisibility = (name, show) => ({
  type: SET_VISIBLE,
  name,
  show,
});

export const setVisible = (name, show) => (dispatch, getState) => {
  const { visibilityMap } = getState().Ui;
  if (visibilityMap[name] !== show) {
    dispatch(exportFunctions.setVisibility(name, show));
  }
};

export const setWorkInProgress = (name, value) => ({
  type: SET_WORK_IN_PROGRESS,
  name,
  value,
});

export const reportError = (errorMessage) => ({
  type: SHOW_CUSTOM_ERROR,
  error: {
    message: errorMessage,
  },
});

export const dismissError = () => ({
  type: DISMISS_CUSTOM_ERROR,
});

const exportFunctions = {
  setVisibility,
};

export default exportFunctions;
