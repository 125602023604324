import { Box3, Euler, MathUtils, Ray, Sphere, Spherical, Vector2, Vector3 } from 'three';
import TWEEN from '@tweenjs/tween.js';

import { createPointerMoveEventListener, getPointFromMultipleTouchEvent } from '../Utils/pointerEvents';
import { getContainerBox } from './utils/objectSize';
import { BACK, FRONT, LEFT, RIGHT, TOP, FRONT_LEFT, FRONT_RIGHT } from '../Constants/CameraViews';
import { MODEL_NAME } from './Constants';
import OrbitControls from './utils/OrbitControls';

export async function fitSceneToCamera(rootObject, controls, options = {}) {
  const box = getContainerBox(rootObject);

  const sphere = new Sphere();
  box.getBoundingSphere(sphere);
  const minDistance = sphere.radius + 2;
  const maxDistance = sphere.radius * 2 + 10;

  if (minDistance === controls.minDistance) {
    return;
  }
  const camera = controls.object;

  controls.minDistance = 0;
  controls.maxDistance = Infinity;
  camera.near = minDistance * 0.01;
  camera.far = maxDistance * 100;
  camera.updateProjectionMatrix();

  const distance = camera.position.length();
  if (distance < minDistance || distance > maxDistance) {
    const newPosition = camera.position
      .clone()
      .normalize()
      .multiplyScalar(maxDistance * 0.75);

    const { startAnimation, stopAnimation } = options;
    if (startAnimation && stopAnimation) {
      startAnimation();
      await moveCameraToPosition(camera, newPosition);
      stopAnimation();
    } else {
      camera.position.copy(newPosition);
    }
  }
  controls.minDistance = minDistance;
  controls.maxDistance = maxDistance;
  controls.update();
}

export function moveCameraToPosition(camera, position, options = {}) {
  const { duration = 500 } = options;
  const sp0 = new Spherical();
  sp0.setFromVector3(camera.position);
  const sp1 = new Spherical();
  sp1.setFromVector3(position);
  // always take the shorter way around the target
  if (Math.abs(sp1.theta - sp0.theta) > Math.PI) {
    sp1.theta += (sp1.theta > 0 ? -1 : 1) * Math.PI * 2;
  }
  return new Promise((resolve) => {
    new TWEEN.Tween(sp0)
      .to(sp1, duration)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        camera.position.setFromSpherical(sp0);
      })
      .onComplete(resolve)
      .start();
  });
}

export function moveCameraToTarget(controls, target, options = {}) {
  const { duration = 500 } = options;
  const t0 = controls.target.clone();
  const t1 = target;
  return new Promise((resolve) => {
    new TWEEN.Tween(t0)
      .to(t1, duration)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        controls.target.copy(t0);
      })
      .onComplete(resolve)
      .start();
  });
}

export function setupDefaultControls(camera, element, render) {
  const controls = new OrbitControls(camera, element);

  render && controls.addEventListener('change', render); // call this only in static scenes (i.e., if there is no animation loop)

  controls.minDistance = 3;
  controls.maxDistance = 60;
  controls.maxPolarAngle = Math.PI * 0.5;
  controls.enableKeys = false;
  controls.target.y = 2;

  return controls;
}

export function setupPanControls(controls, element, scene) {
  let panningEnabled = false;
  let startPoint = new Vector2();

  function setupPanning() {
    return createPointerMoveEventListener(
      element,
      (evt) => {
        const { x, y } = getPointFromMultipleTouchEvent(evt);
        const point = new Vector2(x, y);
        const pointDelta = point.clone().sub(startPoint);
        controls.pan(pointDelta.x, pointDelta.y);
        controls.update();
        startPoint.copy(point);
      },
      {
        onStart: (evt) => {
          const { x, y } = getPointFromMultipleTouchEvent(evt);
          startPoint.set(x, y);
        },
        onEnd: () => {},
      }
    );
  }

  let remove;
  controls.togglePanning = function (value) {
    panningEnabled = value;
    controls.enableRotate = !panningEnabled;
    if (panningEnabled) {
      ({ remove } = setupPanning());
    } else if (remove) {
      remove();
    }
  };

  controls.fixTargetBounding = function (target) {
    const warehouse = scene.getObjectByName(MODEL_NAME);
    warehouse.updateMatrix(); // make sure plane's local matrix is updated
    warehouse.updateMatrixWorld(); // make sure plane's world matrix is updated
    const boundingBox = new Box3();
    boundingBox.expandByObject(warehouse);
    boundingBox.min.multiplyScalar(1.1);
    boundingBox.max.multiplyScalar(1.1);

    if (target.x < boundingBox.min.x) {
      target.x = boundingBox.min.x;
    } else if (target.x > boundingBox.max.x) {
      target.x = boundingBox.max.x;
    }
    if (target.z < boundingBox.min.z) {
      target.z = boundingBox.min.z;
    } else if (target.z > boundingBox.max.z) {
      target.z = boundingBox.max.z;
    }
  };

  controls.addEventListener('change', () => {
    const camera = controls.object;
    camera.updateMatrix(); // make sure camera's local matrix is updated
    camera.updateMatrixWorld(); // make sure camera's world matrix is updated
    camera.matrixWorldInverse.getInverse(camera.matrixWorld);

    const warehouse = scene.getObjectByName(MODEL_NAME);
    warehouse.updateMatrix(); // make sure plane's local matrix is updated
    warehouse.updateMatrixWorld(); // make sure plane's world matrix is updated
    const boundingBox = new Box3();
    boundingBox.expandByObject(warehouse);

    const dir = new Vector3();
    camera.getWorldDirection(dir);
    dir.multiplyScalar(-1);
    const ray = new Ray(camera.position, dir);
    const target = new Vector3();
    if (ray.intersectBox(boundingBox, target)) {
      camera.position.copy(target).add(dir);
    }
  });
}

export function setupFixedViews(controls, scene, options = {}) {
  const fitOffset = 1;
  const camera = controls.object;

  const calculateLengthAwareY = (x) =>
    x < 51 ? ((1 - x / 50) * 0.6 + 0.4) * 0.26 : ((1 - (x - 50) / 150) * 0.7 + 0.3) * 0.12;

  const getPosition = (side) => {
    const warehouse = scene.getObjectByName(MODEL_NAME);

    const box = new Box3();
    box.expandByObject(warehouse);

    const size = box.getSize(new Vector3());
    const center = box.getCenter(new Vector3());

    let maxSize;
    let positionBase;
    let positionOffset;

    switch (side) {
      case FRONT: {
        maxSize = Math.max(size.z, size.y);
        const y = calculateLengthAwareY(size.x);
        positionBase = new Vector3(1, y, 0);
        positionOffset = size.x / 2;
        break;
      }
      case BACK: {
        maxSize = Math.max(size.z, size.y);
        const y = calculateLengthAwareY(size.x);
        positionBase = new Vector3(-1, y, 0);
        positionOffset = size.x / 2;
        break;
      }
      case LEFT: {
        maxSize = Math.max(size.x, size.y);
        positionBase = new Vector3(0, 0.2, 1);
        positionOffset = size.z / 2;
        break;
      }
      case RIGHT: {
        maxSize = Math.max(size.x, size.y);
        positionBase = new Vector3(0, 0.2, -1);
        positionOffset = size.z / 2;
        break;
      }
      case TOP: {
        maxSize = Math.max(size.x, size.z);
        positionBase = new Vector3(0, 1, 0.00000001);
        positionOffset = size.y;
        break;
      }
      case FRONT_LEFT: {
        const p = (200 - size.x) / 200;
        const pRev = 1 - p;
        maxSize = size.x * 0.8;
        positionBase = new Vector3(5, 1.6 - pRev, 2);
        positionBase.applyEuler(new Euler(0, MathUtils.degToRad(-40) * p, 0));
        positionOffset = size.z - Math.pow(80, pRev);
        center.x = size.x * 0.5 * 0.6 * pRev;
        break;
      }
      case FRONT_RIGHT: {
        const p = (200 - size.x) / 200;
        const pRev = 1 - p;
        maxSize = size.x * 0.8;
        positionBase = new Vector3(5, 1.6 - pRev, -2);
        positionBase.applyEuler(new Euler(0, MathUtils.degToRad(40) * p, 0));
        positionOffset = size.z - Math.pow(80, pRev);
        center.x = size.x * 0.5 * 0.6 * pRev;
        break;
      }
    }

    const fitDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
    const distance = fitOffset * fitDistance;

    return {
      position: positionBase.normalize().multiplyScalar(distance + positionOffset),
      target: center,
    };
  };

  const moveTo = async (side, direct = false, animationOptions = {}) => {
    const { position, target } = getPosition(side);
    const { startAnimation, stopAnimation } = options;
    if (!direct && startAnimation && stopAnimation) {
      stopAnimation();
      startAnimation();
      await Promise.all([
        moveCameraToPosition(camera, position, animationOptions),
        moveCameraToTarget(controls, target, animationOptions),
      ]);
      stopAnimation();
    } else {
      camera.position.copy(position);
      controls.target.copy(target);
      controls.update();
      const { render } = options;
      // double render is needed to make sure the onBeforeRender functions are properly called
      render && render();
      render && render();
    }
  };

  controls.fixedViews = {
    moveTo,
  };
}
