import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { VisualizationContext } from '../../../../ContextProviders/VisualizationProvider';
import { InteractionStarted } from '../../../../../Visualization/EventTypes';
import { BACK, FRONT, LEFT, RIGHT, TOP } from '../../../../../Constants/CameraViews';
import { ToolbarButtonWithSubmenu } from '../../ToolbarButtonWithSubmenu';
import { ToolbarButton } from '../../ToolbarButton';

import viewInactiveIconSrc from './icons/view_inactive.svg';
import viewActiveIconSrc from './icons/view_active.svg';
import cameraLeftInactiveIconSrc from './icons/camera_left_inactive.svg';
import cameraLeftActiveIconSrc from './icons/camera_left_active.svg';
import cameraRightInactiveIconSrc from './icons/camera_right_inactive.svg';
import cameraRightActiveIconSrc from './icons/camera_right_active.svg';
import cameraFrontInactiveIconSrc from './icons/camera_front_inactive.svg';
import cameraFrontActiveIconSrc from './icons/camera_front_active.svg';
import cameraBackInactiveIconSrc from './icons/camera_back_inactive.svg';
import cameraBackActiveIconSrc from './icons/camera_back_active.svg';
import cameraTopInactiveIconSrc from './icons/camera_top_inactive.svg';
import cameraTopActiveIconSrc from './icons/camera_top_active.svg';

const ViewButton = () => {
  const visualization = useContext(VisualizationContext);
  const { t } = useTranslation();
  const [active, setActive] = useState();
  useEffect(() => {
    if (active) {
      return visualization.on(InteractionStarted, () => setActive(false));
    }
  }, [active, visualization]);

  const createClickHandler = (side) => () => visualization.controls.fixedViews.moveTo(side) + setActive(side);

  return (
    <ToolbarButtonWithSubmenu iconSrc={viewInactiveIconSrc} activeIconSrc={viewActiveIconSrc}>
      <Grid container spacing={3}>
        <Grid item>
          <ToolbarButton
            iconSrc={cameraLeftInactiveIconSrc}
            activeIconSrc={cameraLeftActiveIconSrc}
            onClick={createClickHandler(LEFT)}
            label={t('cameraViews.left')}
            active={active === LEFT}
          />
        </Grid>
        <Grid item>
          <ToolbarButton
            iconSrc={cameraRightInactiveIconSrc}
            activeIconSrc={cameraRightActiveIconSrc}
            onClick={createClickHandler(RIGHT)}
            label={t('cameraViews.right')}
            active={active === RIGHT}
          />
        </Grid>
        <Grid item>
          <ToolbarButton
            iconSrc={cameraFrontInactiveIconSrc}
            activeIconSrc={cameraFrontActiveIconSrc}
            onClick={createClickHandler(FRONT)}
            label={t('cameraViews.front')}
            active={active === FRONT}
          />
        </Grid>
        <Grid item>
          <ToolbarButton
            iconSrc={cameraBackInactiveIconSrc}
            activeIconSrc={cameraBackActiveIconSrc}
            onClick={createClickHandler(BACK)}
            label={t('cameraViews.back')}
            active={active === BACK}
          />
        </Grid>
        <Grid item>
          <ToolbarButton
            iconSrc={cameraTopInactiveIconSrc}
            activeIconSrc={cameraTopActiveIconSrc}
            onClick={createClickHandler(TOP)}
            label={t('cameraViews.top')}
            active={active === TOP}
          />
        </Grid>
      </Grid>
    </ToolbarButtonWithSubmenu>
  );
};

export default ViewButton;
