import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const LoaderStateContext = createContext(null);

export function createLoaderStateManager() {
  let currentPromise;
  let onStart;
  let onFinish;
  let loading = false;

  function start() {
    let _resolve;
    const lastPromise = currentPromise;
    let nextPromise = Promise.all([lastPromise, new Promise((resolve) => (_resolve = resolve))]);
    currentPromise = nextPromise;
    currentPromise.finally(() => {
      if (nextPromise === currentPromise) {
        loading = false;
        onFinish && onFinish();
      }
    });
    if (!loading) {
      onStart && onStart();
      loading = true;
    }
    return _resolve;
  }

  function wrap(fn) {
    return async (...args) => {
      const finish = start();
      try {
        return await fn(...args);
      } finally {
        finish();
      }
    };
  }

  async function watchPromise(promise) {
    const finish = start();
    try {
      return await promise;
    } finally {
      finish();
    }
  }

  return {
    start,
    wrap,
    watchPromise,
    setStartListener: (fn) => (onStart = fn),
    setFinishListener: (fn) => (onFinish = fn),
    isLoading: () => loading,
  };
}

export const LoaderStateProvider = ({ loaderStateManager, children }) => {
  const [loadingState, setLoadingState] = useState(false);
  useEffect(() => {
    loaderStateManager.setStartListener(() => setLoadingState(true));
    loaderStateManager.setFinishListener(() => setLoadingState(false));
    setLoadingState(loaderStateManager.isLoading());
  }, [loaderStateManager]);
  return <LoaderStateContext.Provider value={loadingState}>{children}</LoaderStateContext.Provider>;
};

LoaderStateProvider.propTypes = {
  children: PropTypes.node,
  loaderStateManager: PropTypes.object,
};
