import { SET_VISIBLE, SET_WORK_IN_PROGRESS, SHOW_CUSTOM_ERROR, DISMISS_CUSTOM_ERROR } from './Actions';

const initialState = {
  visibilityMap: {},
  backgroundWorkMap: {},
  error: null,
};

const UIReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_VISIBLE: {
      const { name, show } = action;
      return {
        ...state,
        visibilityMap: {
          ...state.visibilityMap,
          [name]: show,
        },
      };
    }

    case SET_WORK_IN_PROGRESS: {
      const { name, value } = action;
      return {
        ...state,
        backgroundWorkMap: {
          ...state.backgroundWorkMap,
          [name]: value,
        },
      };
    }

    case SHOW_CUSTOM_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }

    case DISMISS_CUSTOM_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

export default UIReducer;
