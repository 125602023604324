import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ConfigurationContext } from '../../../ContextProviders/ConfigurationProvider';
import useConfigurationChanges from '../../../Hooks/useConfigurationChanges';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { findByEmbeddedOptionIdentifier, getEmbeddedOptionIdentifier } from '../../../Selectors/Option';

export const Walls = () => {
  const configuration = useContext(ConfigurationContext);
  const {
    localOptions: { walls: wallTypes },
  } = useSelector((state) => state.Option);
  const { setWallType } = configuration;
  const { wallType } = useConfigurationChanges(configuration);

  return (
    <RadioGroup
      aria-label="wall type"
      value={getEmbeddedOptionIdentifier(wallType)}
      onChange={({ target: { value } }) => setWallType(findByEmbeddedOptionIdentifier(wallTypes, value))}
    >
      {wallTypes.map(({ option: { identifier, title } }) => (
        <FormControlLabel key={identifier} value={identifier} control={<Radio />} label={title} />
      ))}
    </RadioGroup>
  );
};

export default Walls;
