import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';
import { isRidgeLightBand } from '../../../DataStructures/roofWindowTypes';
import { ConfigurationContext } from '../../../ContextProviders/ConfigurationProvider';
import useConfigurationChanges from '../../../Hooks/useConfigurationChanges';
import DialogContentDivider from '../../DialogContentDivider';
import GroupLabel from '../../GroupLabel';
import { useSelector } from 'react-redux';
import { findByEmbeddedOptionIdentifier, getEmbeddedOptionIdentifier } from '../../../Selectors/Option';

const useStyles = makeStyles(() => ({
  icon: {
    width: 35,
    height: 30,
    display: 'inline-flex',
    color: '#ED1C29',
    opacity: 0.38,
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const Roof = () => {
  const configuration = useContext(ConfigurationContext);
  const { setRoofType, setRoofWindowType, setRainGutter } = configuration;
  const {
    roofType,
    roofWindowType,
    rainGutter,
    isRoofWindowEnabled,
    isRidgeLightBandEnabled,
  } = useConfigurationChanges(configuration);
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    localOptions: { roof: roofTypes, roof_window: roofWindowTypes },
  } = useSelector((state) => state.Option);

  return (
    <>
      <GroupLabel>{t('roofType')}</GroupLabel>
      <RadioGroup
        aria-label="roof type"
        value={getEmbeddedOptionIdentifier(roofType)}
        onChange={({ target: { value } }) => setRoofType(findByEmbeddedOptionIdentifier(roofTypes, value))}
      >
        {roofTypes.map(({ option: { identifier, title } }) => (
          <FormControlLabel key={identifier} value={identifier} control={<Radio />} label={title} />
        ))}
      </RadioGroup>

      <DialogContentDivider />

      <GroupLabel>{t('roofWindow')}</GroupLabel>
      <RadioGroup
        aria-label="roof window type"
        value={getEmbeddedOptionIdentifier(roofWindowType)}
        onChange={({ target: { value } }) =>
          setRoofWindowType(findByEmbeddedOptionIdentifier(roofWindowTypes, value))
        }
        disabled={!isRoofWindowEnabled}
      >
        <FormControlLabel key={'none'} value={''} control={<Radio />} label={t('noRoofWindow')} />
        {roofWindowTypes.map(({ value, option: { identifier, title } }) => (
          <div className={classes.labelWrapper} key={`labelWrapper${identifier}`}>
            <FormControlLabel
              key={identifier}
              value={identifier}
              control={<Radio />}
              label={title}
              disabled={!isRoofWindowEnabled || (isRidgeLightBand(value) ? !isRidgeLightBandEnabled : false)}
            />
            {(!isRoofWindowEnabled || (isRidgeLightBand(value) ? !isRidgeLightBandEnabled : false)) && (
              <ErrorOutline className={classes.icon} />
            )}
          </div>
        ))}
      </RadioGroup>

      <DialogContentDivider />

      <FormControlLabel
        control={
          <Switch
            checked={rainGutter}
            onChange={(evt) => setRainGutter(evt.target.checked)}
            name="rain gutter"
          />
        }
        label={t('rainGutter')}
      />
    </>
  );
};

export default Roof;
