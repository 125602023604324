import { getObjectsBySide, isXSide } from '../Selectors/Side';
import { DOOR, GATE, WALL_LIGHTBAND } from '../../Constants/ObjectTypes';
import { isRangeObject } from '../Selectors/RangeObject';

function isLimitedPositionType(type) {
  return [DOOR, GATE].indexOf(type) !== -1;
}

function isLimitedToOnePerSegment(type) {
  return [WALL_LIGHTBAND].indexOf(type) !== -1;
}

function isAllowedByWallType(type, wallType) {
  // lightbands are not allowed for 80 and 100 mm wall thickness
  if (type === WALL_LIGHTBAND) {
    return [80, 100].indexOf(wallType.thickness) === -1;
  }
  return true;
}

function isAllowedByHeight(objectHeight, height) {
  // e.g. 4.2 m gates are not allowed with 4.2 m warehouse height
  return objectHeight < height;
}

export function canObjectBePlacedToSegment(configurationData, type, side, index, objectRef = null) {
  // limited position types can be added only once to the first 3 and last 3 wall segments
  if (isLimitedPositionType(type) && isXSide(side)) {
    const objects = getObjectsBySide(configurationData, side).filter(
      (obj) => objectRef !== obj && isLimitedPositionType(obj.type)
    );
    const firstIndex = 3;
    const lastIndex = configurationData.xWallSegmentCount - 1 - firstIndex;
    return !(
      (index < firstIndex && objects.some((obj) => obj.index < firstIndex)) ||
      (index > lastIndex && objects.some((obj) => obj.index > lastIndex))
    );
  }
  if (isLimitedToOnePerSegment(type)) {
    return !configurationData.objects.some(
      (obj) =>
        obj.side === side &&
        obj.type === type &&
        (isRangeObject(type) ? index >= obj.indexFrom && index <= obj.indexTo : obj.index === index)
    );
  }
  return true;
}

export function canWallSegmentGetObjectType(configurationData, type, side, index, objectHeight = null) {
  // gates can be added only to 5 m wall segments
  if (isXSide(side) && type === GATE && configurationData.xWallSegmentWidth < 5) {
    return false;
  }
  if (!isAllowedByWallType(type, configurationData.wallType)) {
    return false;
  }
  if (type === GATE && objectHeight && !isAllowedByHeight(objectHeight, configurationData.height)) {
    return false;
  }
  return canObjectBePlacedToSegment(configurationData, type, side, index);
}

export default canWallSegmentGetObjectType;
