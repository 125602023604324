import get from 'lodash/get';
import { createRequest } from '../../Api';

export const RECEIVE_SAMPLES = 'SAMPLES_RECEIVE_SAMPLES';
export const REQUEST_ERROR = 'SAMPLES_REQUEST_ERROR';

export const createRequestError = (e) => ({
  type: REQUEST_ERROR,
  error: e,
});

export const getSamples = () => async (dispatch) => {
  const { request } = createRequest();
  try {
    const response = await request('/frontendapi/samples');
    const samples = get(response, 'data', []);
    dispatch({
      type: RECEIVE_SAMPLES,
      samples,
    });
  } catch (e) {
    dispatch(createRequestError(e));
  }
};
