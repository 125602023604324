import { disposeMaterial } from './disposeMaterial';

export function disposeObject(obj) {
  if (!obj) {
    return;
  }
  while (obj.children.length > 0) {
    disposeObject(obj.children[0]);
    obj.remove(obj.children[0]);
  }
  if (obj.geometry) obj.geometry.dispose();
  if (obj.material) disposeMaterial(obj.material);
}

export default disposeObject;
