import { Vector2 } from 'three';

export default function makeScreenshots(visualization, views) {
  const { controls, renderer, measurements, notes } = visualization;

  // store original values
  const camera = controls.object.clone();
  const target = controls.target.clone();
  const size = renderer.getSize(new Vector2());
  const pixelRatio = renderer.getPixelRatio();

  renderer.setPixelRatio(1);

  const data = {};

  for (const view of views) {
    let { width = 2480, aspectRatio = 4 / 3, height = 0 } = view;
    if (!height && width && aspectRatio) {
      height = width / aspectRatio;
    } else if (!width && height && aspectRatio) {
      width = height * aspectRatio;
    } else if (!aspectRatio && width && height) {
      aspectRatio = width / height;
    } else {
      throw new Error('Either { width | height, aspectRatio } or { width, height } pairs must be given');
    }

    renderer.setSize(width, height);
    controls.object.aspect = aspectRatio;
    controls.object.updateProjectionMatrix();
    if (measurements) {
      measurements.toggle(view.measurements);
    }
    if (notes) {
      notes.visible = !!view.notes;
    }
    controls.fixedViews.moveTo(view.name, true);
    data[view.name] = renderer.domElement.toDataURL('image/jpeg', 0.9);
  }

  if (notes) {
    notes.visible = true;
  }

  // restore original values
  controls.object.copy(camera);
  controls.target.copy(target);
  renderer.setPixelRatio(pixelRatio);
  renderer.setSize(size.x, size.y);
  controls.object.updateProjectionMatrix();

  return data;
}
