export const configurationMenuItems = [
  {
    identifier: 'warehouseType',
    title: 'Hallentyp',
  },
  {
    identifier: 'dimensions',
    title: 'Abmessungen',
  },
  {
    identifier: 'roof',
    title: 'Dach',
  },
  {
    identifier: 'walls',
    title: 'Wände',
  },
  {
    identifier: 'colors',
    title: 'Farben',
  },
];

export default configurationMenuItems;
