import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = {
  root: {
    background: 'rgba(255, 255, 255, 0.8)',
    boxShadow: 'none',
    padding: 12,
  },
};

const Toolbar = ({ classes, children }) => {
  return <Paper className={classes.root}>{children}</Paper>;
};

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  isHorizontal: PropTypes.bool,
};

export default withStyles(styles)(Toolbar);
