import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ConfigurationContext } from '../../../ContextProviders/ConfigurationProvider';
import useConfigurationChanges from '../../../Hooks/useConfigurationChanges';
import MenuAccordion from '../../MenuAccordion';
import { DOORS, GATES, PROFILE, ROOF, WALLS, WINDOWS } from '../../../../Constants/ColorizableParts';
import { useSelector } from 'react-redux';
import { getEmbeddedOptionIdentifier } from '../../../Selectors/Option';

const useStyles = makeStyles((theme) => ({
  colorTile: {
    cursor: 'pointer',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
  },
  checkedIcon: ({ wallColor }) => ({
    color: theme.palette.getContrastText(wallColor),
  }),
  titleBar: {
    marginLeft: 20,
  },
  color: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
  },
  selected: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}));

const ColorTile = ({ value, title, selected, onChange }) => {
  const classes = useStyles({ wallColor: value });
  return (
    <div className={classes.colorTile} onClick={() => onChange(value)}>
      <div className={clsx(classes.color, selected && classes.selected)} style={{ backgroundColor: value }} />
      <Typography component={'div'} className={classes.titleBar}>
        {title}
      </Typography>
    </div>
  );
};

ColorTile.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

const ColorSection = ({ onChange, selectedColor }) => {
  const {
    localOptions: { colors },
  } = useSelector((state) => state.Option);

  return (
    <div>
      {colors.map((color) => {
        const {
          value,
          option: { title, identifier },
        } = color;
        return (
          <ColorTile
            key={value}
            value={value}
            title={title}
            selected={getEmbeddedOptionIdentifier(selectedColor) === identifier}
            onChange={() => onChange(color)}
          />
        );
      })}
    </div>
  );
};

ColorSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedColor: PropTypes.object.isRequired,
};

const Colors = () => {
  const configuration = useContext(ConfigurationContext);
  const {
    wallColor,
    consoleColor,
    roofColor,
    gateColor,
    doorColor,
    windowColor,
    isRoofColorEnabled,
  } = useConfigurationChanges(configuration);

  const {
    setWallColor,
    setConsoleColor,
    setRoofColor,
    setGateColor,
    setDoorColor,
    setWindowColor,
  } = configuration;

  const [expanded, setExpanded] = useState(isRoofColorEnabled ? ROOF : WALLS);

  const { t } = useTranslation();

  const handleChange = (panel) => (event, newExpanded) => {
    newExpanded && setExpanded(panel);
  };

  return (
    <>
      <MenuAccordion
        title={t(ROOF)}
        expanded={expanded === ROOF}
        onChange={handleChange(ROOF)}
        disabled={!isRoofColorEnabled}
      >
        <ColorSection onChange={setRoofColor} selectedColor={roofColor} />
      </MenuAccordion>
      <MenuAccordion title={t(WALLS)} expanded={expanded === WALLS} onChange={handleChange(WALLS)}>
        <ColorSection onChange={setWallColor} selectedColor={wallColor} />
      </MenuAccordion>
      <MenuAccordion title={t(PROFILE)} expanded={expanded === PROFILE} onChange={handleChange(PROFILE)}>
        <ColorSection onChange={setConsoleColor} selectedColor={consoleColor} />
      </MenuAccordion>
      <MenuAccordion title={t(GATES)} expanded={expanded === GATES} onChange={handleChange(GATES)}>
        <ColorSection onChange={setGateColor} selectedColor={gateColor} />
      </MenuAccordion>
      <MenuAccordion title={t(DOORS)} expanded={expanded === DOORS} onChange={handleChange(DOORS)}>
        <ColorSection onChange={setDoorColor} selectedColor={doorColor} />
      </MenuAccordion>
      <MenuAccordion title={t(WINDOWS)} expanded={expanded === WINDOWS} onChange={handleChange(WINDOWS)}>
        <ColorSection onChange={setWindowColor} selectedColor={windowColor} />
      </MenuAccordion>
    </>
  );
};

export default Colors;
