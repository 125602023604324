import React, { useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {
  loadSampleConfiguration,
  resetConfigurationCode,
  setConfigurationUpdated,
} from '../../../Reducers/Configuration/Actions';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ConfigurationContext } from '../../../ContextProviders/ConfigurationProvider';

const useStyles = makeStyles(() => ({
  warehouseTypeButton: {
    width: '100%',
    minHeight: 169,
    padding: 0,
  },
  warehouseTypeTitle: { marginBottom: 10, marginTop: 2 },
}));

export const WarehouseType = ({ onClose }) => {
  const classes = useStyles();
  const { samples } = useSelector((state) => state.Sample);
  const { configuration, configurationUpdated } = useSelector((state) => state.Configuration);

  const dispatch = useDispatch();
  const customData = useContext(ConfigurationContext);

  useEffect(() => {
    if (configurationUpdated) {
      configuration && configuration.customdata && customData.setData(configuration.customdata);
      dispatch(setConfigurationUpdated(false));
      dispatch(resetConfigurationCode());
      onClose();
    }
  }, [configuration, customData, dispatch, onClose, configurationUpdated]);

  const setSampleConfiguration = async (code) => {
    await dispatch(loadSampleConfiguration(code));
  };

  return (
    <>
      {samples.map((sample) => (
        <React.Fragment key={sample.id}>
          <Button
            key={`warehouse_type_${sample.id}`}
            onClick={() => setSampleConfiguration(sample.configurationCode)}
            className={classes.warehouseTypeButton}
          >
            <img src={sample.imageUrl} alt={sample.title} />
          </Button>
          <Typography className={classes.warehouseTypeTitle} key={`warehouse_type_title_${sample.id}`}>
            {sample.title}
          </Typography>
        </React.Fragment>
      ))}
    </>
  );
};
WarehouseType.propTypes = {
  imagePreloader: PropTypes.object,
  onClose: PropTypes.func,
};
export default WarehouseType;
