import React, { forwardRef, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

const MultiLevelMenu = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ items, onSelect, show, level, renderMenuItem, renderBackButton, onRendered }, ref) => {
    const [selected, setSelected] = useState(null);
    useEffect(() => {
      setSelected(null);
    }, [show]);
    useLayoutEffect(() => {
      onRendered && onRendered();
    }, [onRendered, selected]);
    const itemsToRender = selected ? selected.children : items;
    return (
      <>
        {selected && renderMenuItem({ item: selected, selected: true })}
        {itemsToRender &&
          itemsToRender.map((item, idx) => {
            const hasChildren = item.children && item.children.length;
            const open = selected === item;
            const onClick = hasChildren ? () => setSelected(item) : (evt) => onSelect(item, evt);
            const subMenuLevel = selected ? level + 1 : level;
            return (
              <React.Fragment key={idx}>
                {renderMenuItem({ item, level: subMenuLevel, hasChildren, onClick })}
                {open && hasChildren && (
                  <MultiLevelMenu
                    items={item.children}
                    onSelect={onSelect}
                    level={subMenuLevel + 1}
                    renderMenuItem={renderMenuItem}
                    onRendered={onRendered}
                  />
                )}
              </React.Fragment>
            );
          })}
        {selected && renderBackButton && renderBackButton({ onClick: () => setSelected(null) })}
      </>
    );
  }
);

MultiLevelMenu.defaultProps = {
  level: 0,
};

MultiLevelMenu.propTypes = {
  items: PropTypes.array,
  show: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  renderMenuItem: PropTypes.func.isRequired,
  renderBackButton: PropTypes.func,
  level: PropTypes.number,
  onRendered: PropTypes.func,
};

export default MultiLevelMenu;
