import {
  CONFIGURATION_UPDATED,
  RECEIVE_CONFIGURATION,
  RECEIVE_SAVE_CONFIGURATION,
  REQUEST_ERROR,
  RESET_CODE,
  SET_LOAD_CODE,
  RESET_ERROR,
} from './Actions';

const initialState = {
  configuration: {
    code: null,
  },
  configurationUpdated: false,
  error: null,
  loadCode: null,
};

export default function ConfigurationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_CONFIGURATION: {
      const { configuration } = action;

      return {
        ...state,
        configuration: configuration,
      };
    }

    case RECEIVE_SAVE_CONFIGURATION: {
      const { configuration } = action;
      const { code } = configuration;
      return {
        ...state,
        configuration,
        loadCode: code,
      };
    }

    case RESET_CODE: {
      const configuration = state.configuration;
      if (configuration) {
        configuration.code = null;
      }
      return {
        ...state,
        configuration,
      };
    }

    case SET_LOAD_CODE: {
      const { loadCode } = action;
      return {
        ...state,
        loadCode,
      };
    }

    case REQUEST_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }

    case CONFIGURATION_UPDATED: {
      const { updated } = action;
      return {
        ...state,
        configurationUpdated: updated,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
}
