import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  privacyPolicyControlLabel: {
    marginTop: 24,
  },
  privacyPolicyControlLabelText: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
}));

const PrivacyPolicyControl = ({ checked, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControlLabel
      classes={{
        root: classes.privacyPolicyControlLabel,
        label: classes.privacyPolicyControlLabelText,
      }}
      control={<Checkbox checked={checked} onChange={() => onChange(!checked)} color="primary" />}
      label={
        <Trans
          i18nKey="pdfDialog.privacyPolicyText"
          components={{
            dataProtectionStatementLink: <Link href={t('pdfDialog.privacyPolicyLink')} target="_blank" />,
          }}
        />
      }
    />
  );
};

PrivacyPolicyControl.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PrivacyPolicyControl;
