import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  MuiDialogBorder: {},
  MuiStyledDialog: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paperWidthMd': {
        maxWidth: 720,
      },
      '& .MuiDialogTitle-root': {
        paddingTop: 20,
        paddingBottom: 15,
        '& p, h2': {
          fontSize: 24,
          fontWeight: 'bold',
          letterSpacing: 'normal',
          [theme.breakpoints.down('xs')]: {
            fontSize: 18,
          },
        },
      },
      '& .MuiDialogActions-root': {
        paddingBottom: 24,
      },
      '& .MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root': {
        paddingLeft: 32,
        paddingRight: 32,
        '& > p': {
          fontSize: 15,
          letterSpacing: 'normal',
        },
      },
    },
    '& .MuiButton-root': {
      height: 40,
      textTransform: 'none',
      minWidth: 140,
    },
  },
  stickyDialog: {
    '& .MuiDialog-container': {
      '& .MuiDialogTitle-root': {
        paddingTop: 25,
        paddingBottom: 8,
      },
    },
    '& .MuiButton-root': {
      width: '100%',
      height: 56,
      minWidth: 64,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 28,
    top: 28,

    [theme.breakpoints.down('xs')]: {
      right: 20,
      top: 22,
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const CustomDialog = (props) => {
  const { className, width, dialogtitle, onCancel, children, fullScreen } = props;
  const classes = useStyles(props);

  return (
    <Dialog
      maxWidth={width === 'md' || width === 'lg' || width === 'xl' ? 'md' : 'sm'}
      fullWidth={true}
      classes={{
        paper: clsx(classes.MuiDialogBorder, 'popoverBorder'),
        root: classes.MuiStyledDialog,
      }}
      className={className + ' ' + (width === 'xs' ? classes.stickyDialog : '')}
      fullScreen={fullScreen}
      {...props}
    >
      <DialogTitle className={classes.title}>
        {dialogtitle}
        <CloseIcon className={classes.closeIcon} onClick={onCancel} />
      </DialogTitle>
      {children}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  dialogtitle: PropTypes.string,
  onCancel: PropTypes.func,
  children: PropTypes.any,
  fullScreen: PropTypes.bool,
};

export default CustomDialog;
