import React, { useContext, useEffect, useState } from 'react';
import { VisualizationContext } from '../../../../ContextProviders/VisualizationProvider';
import { ToolbarButton } from '../../ToolbarButton';

import measurementsInactiveIconSrc from './icons/showmeasurements_inactive.svg';
import measurementsActiveIconSrc from './icons/showmeasurements_active.svg';
import { ConfigurationContext } from '../../../../ContextProviders/ConfigurationProvider';
import {
  Group,
  Line,
  Sprite,
  SpriteMaterial,
  BufferGeometry,
  Vector3,
  CanvasTexture,
  LineBasicMaterial,
  MathUtils,
  Color,
  Mesh,
} from 'three';
import { getAxisValueBySide, ObjectControlEvents } from '../../../../../Visualization/objectControls';
import { getContainerBox } from '../../../../../Visualization/utils/objectSize';
import { BACK, FRONT, LEFT, RIGHT } from '../../../../../Constants/Sides';
import { VisualizationUpdated } from '../../../../../Visualization/EventTypes';

const halfPI = Math.PI * 0.5;
const closingSize = 0.1;

const measurementColor = new Color('#f44336').convertSRGBToLinear();
const measurementLineMaterial = new LineBasicMaterial({ color: measurementColor, toneMapped: false });

function createMeasurementLine(size, options = {}) {
  const { closings = true } = options;
  const halfSize = size * 0.5;
  const points = closings
    ? [
        new Vector3(halfSize, 0, -closingSize),
        new Vector3(halfSize, 0, closingSize),
        new Vector3(halfSize, 0, 0),
        new Vector3(-halfSize, 0, 0),
        new Vector3(-halfSize, 0, -closingSize),
        new Vector3(-halfSize, 0, closingSize),
      ]
    : [new Vector3(-halfSize, 0, 0), new Vector3(halfSize, 0, 0)];
  const geometry = new BufferGeometry().setFromPoints(points);
  const line = new Line(geometry, measurementLineMaterial);
  const wrapper = new Mesh();
  wrapper.add(line);
  wrapper.line = line;
  return wrapper;
}

function drawRoundRect(ctx, x, y, width, height, radius) {
  if (width < 2 * radius) radius = width / 2;
  if (height < 2 * radius) radius = height / 2;
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.arcTo(x + width, y, x + width, y + height, radius);
  ctx.arcTo(x + width, y + height, x, y + height, radius);
  ctx.arcTo(x, y + height, x, y, radius);
  ctx.arcTo(x, y, x + width, y, radius);
  ctx.closePath();
}

function addLabel(lineWrapper, text) {
  const { texture, updateText } = createLabelTexture(text);
  const material = new SpriteMaterial({
    map: texture,
    transparent: true,
  });
  const sprite = new Sprite(material);
  sprite.center.set(0.5, 0);
  sprite.scale.set(1.2, 1.2, 1);
  sprite.updateText = updateText;

  lineWrapper.label = sprite;
  lineWrapper.add(sprite);
  return sprite;
}

function formatSize(size) {
  return `${+size.toFixed(2)} m`;
}

function addSizeLabel(line, size) {
  return addLabel(line, formatSize(size));
}

function createLabelTexture(text) {
  const canvas = document.createElement('canvas');
  canvas.width = 512;
  canvas.height = 512;
  const ctx = canvas.getContext('2d');

  const texture = new CanvasTexture(canvas);

  const updateText = (text) => {
    drawRoundRect(ctx, 1, 512 - 170 - 1, 510, 170, 30);
    ctx.fillStyle = '#' + measurementColor.getHexString();
    ctx.fill();
    ctx.font = '96px Arial';
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, 256, 512 - 170 * 0.5);
    texture.needsUpdate = true;
  };

  text && updateText(text);

  return {
    texture,
    updateText,
  };
}

function addDistanceAwareRendering(meas, labelPosition = false, visibility = false) {
  const label = meas.label;
  meas.onBeforeRender = function (renderer, scene, camera) {
    const distance = camera.position.distanceTo(label.getWorldPosition(new Vector3()));
    if (visibility) {
      const visible = distance < camera.position.length();
      meas.line.visible = visible;
      label.visible = visible;
    }
    const scale = Math.max(1.2, distance / 20);
    label.scale.set(scale, scale, 1);
    labelPosition && label.position.copy(labelPosition.clone().multiplyScalar(scale));
    label.updateMatrix();
  };
}

function createUnitMeasurement() {
  const meas = createMeasurementLine(1);
  addSizeLabel(meas, 0);
  meas.label.center.set(0.5, 0.15);
  meas.label.material.depthTest = false;
  const material = measurementLineMaterial.clone();
  material.depthTest = false;
  meas.line.material = material;
  meas.line.renderOrder = 99; // always on top
  addDistanceAwareRendering(meas);
  return meas;
}

function getVectorComponentBySide(side) {
  switch (side) {
    case LEFT:
    case RIGHT:
      return 'z';
    case FRONT:
    case BACK:
      return 'x';
  }
}

function getOtherVectorComponent(vectorComponent) {
  return vectorComponent === 'z' ? 'x' : 'z';
}

function createObjectPositionMeasurements(visualization, configuration) {
  const topLine = createUnitMeasurement();
  topLine.rotation.z = halfPI;
  topLine.rotation.y = halfPI;

  const bottomLine = createUnitMeasurement();
  bottomLine.rotation.z = halfPI;
  bottomLine.rotation.y = halfPI;

  const rightLine = createUnitMeasurement();
  rightLine.rotation.x = halfPI;

  const leftLine = createUnitMeasurement();
  leftLine.rotation.x = halfPI;

  const measurementLines = {
    topLine,
    bottomLine,
    rightLine,
    leftLine,
  };
  const objectMeasurementGroup = new Group();

  Object.assign(objectMeasurementGroup, { measurementLines });
  objectMeasurementGroup.add(...Object.values(measurementLines));
  objectMeasurementGroup.position.y = 0.01;

  const update = (object) => {
    const box = getContainerBox(object);
    const center = box.getCenter(new Vector3());
    const { width, length, height } = configuration.getData();
    const {
      userData: { side },
    } = object;

    const { topLine, bottomLine, rightLine, leftLine } = objectMeasurementGroup.measurementLines;

    const sideComponent = getVectorComponentBySide(side);
    objectMeasurementGroup.position[sideComponent] = center[sideComponent];
    objectMeasurementGroup.position[getOtherVectorComponent(sideComponent)] = 0;
    objectMeasurementGroup.rotation.y = getAxisValueBySide({ x: 0, z: -halfPI }, side);

    const topSize = height - box.max.y;
    topLine.label.updateText(formatSize(topSize));
    topLine.line.scale.set(topSize, 1, 1);
    topLine.position.x = getAxisValueBySide(center, side);
    topLine.position.y = box.max.y + topSize * 0.5;

    const bottomSize = box.min.y;
    bottomLine.label.updateText(formatSize(bottomSize));
    bottomLine.line.scale.set(bottomSize, 1, 1);
    bottomLine.position.x = getAxisValueBySide(center, side);
    bottomLine.position.y = box.min.y - bottomSize * 0.5;

    const wallSize = getAxisValueBySide({ x: length, z: width }, side);

    const rightX = getAxisValueBySide(box.max, side);
    const rightSize = wallSize * 0.5 - rightX;
    rightLine.label.updateText(formatSize(rightSize));
    rightLine.line.scale.set(rightSize, 1, 1);
    rightLine.position.x = rightX + rightSize * 0.5;
    rightLine.position.y = center.y;

    const leftX = getAxisValueBySide(box.min, side);
    const leftSize = leftX + wallSize * 0.5;
    leftLine.label.updateText(formatSize(leftSize));
    leftLine.line.scale.set(leftSize, 1, 1);
    leftLine.position.x = leftX - leftSize * 0.5;
    leftLine.position.y = center.y;
  };

  const offMovementStart = visualization.objectControls.on(
    ObjectControlEvents.movementStart,
    ({ data: { object } }) => {
      if (!object) {
        return;
      }
      visualization.scene.add(objectMeasurementGroup);
      update(object);
      visualization.render();
    }
  );

  const offMovementEnd = visualization.objectControls.on(ObjectControlEvents.movementEnd, () => {
    visualization.scene.remove(objectMeasurementGroup);
  });

  const offPositionChanged = visualization.objectControls.on(
    ObjectControlEvents.positionChanged,
    ({ data: { object } }) => {
      if (!object) {
        return;
      }
      !objectMeasurementGroup.parent && visualization.scene.add(objectMeasurementGroup);
      update(object);
    }
  );

  const dispose = () => {
    offMovementStart();
    offPositionChanged();
    offMovementEnd();
  };

  return {
    dispose,
  };
}

function createWarehouseMeasurements(visualization, configuration) {
  const measurementGroup = new Group();

  const widthLine = createMeasurementLine(1);
  widthLine.rotation.y = halfPI;
  addSizeLabel(widthLine, 0);
  addDistanceAwareRendering(widthLine, new Vector3(0, 0, 0.5), true);

  const widthLine2 = createMeasurementLine(1);
  widthLine2.rotation.y = halfPI;
  addSizeLabel(widthLine2, 0);
  addDistanceAwareRendering(widthLine2, new Vector3(0, 0, -0.5), true);

  const lengthLine = createMeasurementLine(1);
  addSizeLabel(lengthLine, 0);
  addDistanceAwareRendering(lengthLine, new Vector3(0, 0, 0.5), true);

  const lengthLine2 = createMeasurementLine(1);
  addSizeLabel(lengthLine2, 0);
  addDistanceAwareRendering(lengthLine2, new Vector3(0, 0, -0.5), true);

  const heightLine = createMeasurementLine(1);
  heightLine.rotation.z = halfPI;
  addSizeLabel(heightLine, 0);
  addDistanceAwareRendering(heightLine, new Vector3(0, -0.5, 0), true);

  const totalHeightLine = createMeasurementLine(1);
  totalHeightLine.rotation.z = halfPI;
  addSizeLabel(totalHeightLine, 0);
  addDistanceAwareRendering(totalHeightLine, new Vector3(0, -0.5, 0), true);

  measurementGroup.add(widthLine, widthLine2, lengthLine, lengthLine2, heightLine, totalHeightLine);
  measurementGroup.position.y = 0.01;
  measurementGroup.visible = false;
  visualization.scene.add(measurementGroup);

  const update = (configurationData) => {
    const { width, length, height } = configurationData;

    widthLine.position.x = length * 0.5 + 0.5;
    widthLine.label.updateText(formatSize(width));
    widthLine.line.scale.set(width, 1, 1);

    widthLine2.position.x = -widthLine.position.x;
    widthLine2.label.updateText(formatSize(width));
    widthLine2.line.scale.set(width, 1, 1);

    lengthLine.position.z = width * 0.5 + 0.5;
    lengthLine.label.updateText(formatSize(length));
    lengthLine.line.scale.set(length, 1, 1);

    lengthLine2.position.z = -lengthLine.position.z;
    lengthLine2.label.updateText(formatSize(length));
    lengthLine2.line.scale.set(length, 1, 1);

    heightLine.position.set(length * 0.5, height * 0.5, -width * 0.5 - 0.5);
    heightLine.label.updateText(formatSize(height));
    heightLine.line.scale.set(height, 1, 1);

    const totalHeight = height + Math.tan(MathUtils.degToRad(18)) * width * 0.5;
    totalHeightLine.position.set(length * 0.5 + 0.1, totalHeight * 0.5, 0);
    totalHeightLine.label.updateText(formatSize(totalHeight));
    totalHeightLine.line.scale.set(totalHeight, 1, 1);
  };

  const dispose = () => {
    visualization.off(VisualizationUpdated, visualizationUpdateHandler);
    visualization.scene.remove(measurementGroup);
  };

  const isActive = () => measurementGroup.visible;

  const visualizationUpdateHandler = ({ data: { warehouseConfiguration } }) =>
    isActive() && update(warehouseConfiguration);

  const toggle = (on) => {
    const active = measurementGroup.visible;
    measurementGroup.visible = !!on;
    if (on && !active) {
      visualization.on(VisualizationUpdated, visualizationUpdateHandler);
      update(configuration.getData());
      visualization.render(); // extra render is needed to make the label positioning correct
    } else {
      visualization.off(VisualizationUpdated, visualizationUpdateHandler);
    }
    visualization.render();
  };

  const measurements = {
    dispose,
    isActive,
    toggle,
  };

  Object.assign(visualization, { measurements });

  return measurements;
}

export function setMeasurementsVisibility(visualization, visible) {
  const { measurements } = visualization;
  measurements && measurements.toggle(visible);
}

const PanButton = () => {
  const visualization = useContext(VisualizationContext);
  const configuration = useContext(ConfigurationContext);

  useEffect(() => {
    const warehouseMeasurements = createWarehouseMeasurements(visualization, configuration);
    const objectPositionMeasurements = createObjectPositionMeasurements(visualization, configuration);
    return () => {
      warehouseMeasurements.dispose();
      objectPositionMeasurements.dispose();
    };
  }, [configuration, visualization]);

  const [measurementsActive, setMeasurementsActive] = useState(false);
  const toggleMeasurements = () => {
    const active = !measurementsActive;
    setMeasurementsActive(active);
    setMeasurementsVisibility(visualization, active);
  };
  return (
    <ToolbarButton
      iconSrc={measurementsInactiveIconSrc}
      activeIconSrc={measurementsActiveIconSrc}
      onClick={toggleMeasurements}
      active={measurementsActive}
    />
  );
};

export default PanButton;
