import { BACK, FRONT, LEFT, RIGHT } from '../../Constants/Sides';

export function getObjectsBySide(warehouseConfiguration, side) {
  return warehouseConfiguration.objects.filter((item) => item.side === side);
}

export function isXSide(side) {
  return side === LEFT || side === RIGHT;
}

export function isZSide(side) {
  return side === FRONT || side === BACK;
}
