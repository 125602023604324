import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '../Toolbar';
import LoadButton from './Tools/LoadButton/LoadButton';
import SaveButton from './Tools/SaveButton/SaveButton';
import PdfButton from './Tools/PdfButton/PdfButton';
import SaveConfigurationDialog from './Tools/SaveConfigurationDialog/SaveConfigurationDialog';
import LoadConfigurationDialog from './Tools/LoadConfigurationDialog/LoadConfigurationDialog';
import PdfDialog from './Tools/PdfDialog/PdfDialog';

const StyledToolbar = withStyles({
  root: {
    position: 'absolute',
    top: 20,
    left: 20,
    flexDirection: 'row',
    display: 'flex',
    padding: 4,
  },
})(Toolbar);

const ConfigurationToolbar = () => {
  return (
    <StyledToolbar>
      <SaveButton />
      <LoadButton />
      <PdfButton />
      <SaveConfigurationDialog />
      <LoadConfigurationDialog />
      <PdfDialog />
    </StyledToolbar>
  );
};

export default ConfigurationToolbar;
