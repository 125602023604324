export const getLocationLanguageParam = (defaultLanguage = 'de_DE') => {
  const locale = new RegExp(`_?language=([a-z]{2}_[A-Z]{2})`);
  const results = locale.exec(location.search);
  if (results) {
    return results[1];
  }
  return defaultLanguage;
};

export default getLocationLanguageParam;
