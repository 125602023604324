export const ValidationErrorTypes = {
  required: 'required',
  email: 'email',
};

export function validateFieldData(fieldData, translateFn) {
  const validatedData = { ...fieldData, error: null };
  let error;
  if (fieldData.required && !fieldData.value.length) {
    error = ValidationErrorTypes.required;
  }
  if (fieldData.type === 'email' && !/^[^@]+@[^@]+$/.test(fieldData.value)) {
    error = ValidationErrorTypes.email;
  }
  if (error) {
    validatedData.error = true;
    validatedData.helperText = translateFn ? translateFn(error) : error;
  }
  return validatedData;
}

export function validateFields(fields, translateFn) {
  const validatedFields = {};
  let isAllValid = true;
  for (const key of Object.keys(fields)) {
    const validatedFieldData = validateFieldData(fields[key], translateFn);
    validatedFields[key] = validatedFieldData;
    isAllValid = isAllValid && !validatedFieldData.error;
  }
  return {
    validatedFields,
    isAllValid,
  };
}
