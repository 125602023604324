/**
 * @param {MouseEvent|TouchEvent} evt
 * @param {HTMLElement} [relativeTo]
 * @returns {{x: number, y: number}}
 */
export const getPointFromPointerEvent = (evt, relativeTo = null) => {
  let x, y;
  const touches = (evt.changedTouches && evt.changedTouches.length > 0 && evt.changedTouches) || evt.touches;
  if (touches && touches.length > 0) {
    x = touches[0].clientX;
    y = touches[0].clientY;
  } else {
    x = evt.clientX;
    y = evt.clientY;
  }
  if (relativeTo) {
    const { top, left } = relativeTo.getBoundingClientRect();
    x -= left;
    y -= top;
  }
  return { x, y };
};

/**
 * Creates and stores the print of the event position for later comparison.
 * It can be used to create a position change checker function, in order to solve the problem
 * of Chrome's automatically triggered mousemove event when clicking without moving the mouse.
 * @param evt
 * @returns {function(*=): boolean}
 */
export const createPositionChangeChecker = (evt) => {
  const getPositionPrint = (evt) => '' + evt.pageX + '-' + evt.pageY;
  const initialPrint = getPositionPrint(evt);
  return (evt) => initialPrint !== getPositionPrint(evt);
};

/**
 * Creates a "hit" event listener.
 * It detects a single tap (or click) - if the pointer was released without moving.
 * @param element
 * @param {function} fn The event handler function
 * @returns {function()} A function to be called to remove the event listeners
 */
export const createPointerHitListener = (element, fn) => {
  let moved = false;
  let checkPositionChange = false;

  // preventing the emulated mouse events after touchend
  // see https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent
  const preventEmulatedEvents = (cb) => (evt) => {
    if (!moved && evt.type === 'touchend') {
      evt.preventDefault();
    }
    cb(evt);
  };

  const startHandler = (evt) => {
    moved = false;
    const isTouch = (evt.changedTouches || evt.touches || []).length > 0;
    if (isTouch) {
      // in case of touch events we can skip the check, the events are fired correctly
      checkPositionChange = false;
      element.addEventListener('touchmove', moveHandler, true);
      element.addEventListener('touchend', endHandler);
    } else {
      checkPositionChange = createPositionChangeChecker(evt);
      element.addEventListener('mousemove', moveHandler);
      element.addEventListener('mouseup', endHandler);
    }
  };
  const moveHandler = (evt) => {
    if (checkPositionChange) {
      moved = checkPositionChange(evt);
    } else {
      moved = true;
    }
  };
  const endHandler = preventEmulatedEvents((...args) => {
    !moved && fn(...args);
    element.removeEventListener('touchmove', moveHandler, true);
    element.removeEventListener('mousemove', moveHandler);
    element.removeEventListener('touchend', endHandler);
    element.removeEventListener('mouseup', endHandler);
  });

  element.addEventListener('mousedown', startHandler);
  element.addEventListener('touchstart', startHandler);
  return () => {
    element.removeEventListener('mousedown', startHandler);
    element.removeEventListener('touchstart', startHandler);
  };
};

/**
 * Creates a general pointer event listener (for mouse and touch events)
 * @param element
 * @param {string} name The name of the event phase ('start'|'move'|'end')
 * @param {function} fn The event handler function
 * @returns {function()} A function to be called to remove the event listeners
 */
export const createPointerEventListener = (element, name, fn) => {
  let touchName, mouseName;
  switch (name) {
    case 'start': {
      touchName = 'touchstart';
      mouseName = 'mousedown';
      break;
    }
    case 'move': {
      touchName = 'touchmove';
      mouseName = 'mousemove';
      break;
    }
    case 'end': {
      touchName = 'touchend';
      mouseName = 'mouseup';
      break;
    }
  }
  const tolerance = 1; // ms
  let timeStamp = 0;
  const touchHandler = (evt, ...args) => {
    timeStamp = evt.timeStamp;
    fn(evt, ...args);
  };
  const mouseHandler = (evt, ...args) => {
    if (evt.timeStamp - timeStamp < tolerance) {
      console.log('Skipped double event handling.');
      return;
    }
    // for 'start' we call the function only if the element is is the target (or contained by the target)
    // for others it has to be called in every case
    if (name !== 'start' || evt.target === element || evt.target.contains(element)) {
      fn(evt, ...args);
    }
  };
  element.addEventListener(touchName, touchHandler);
  window.addEventListener(mouseName, mouseHandler, true);
  return () => {
    element.removeEventListener(touchName, touchHandler);
    window.removeEventListener(mouseName, mouseHandler, true);
  };
};

/**
 * Helper function to get the distance of two points
 * @param {number} x1
 * @param {number} x2
 * @param {number} y1
 * @param {number} y2
 * @return {number}
 */
const getTwoPointsDistance = (x1, x2, y1, y2) => {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
};

/**
 * Returns the distance between the first two touch points
 * @param {array} touches The touches list from the touch event
 * @return {null|number} The distance
 */
const getTouchDistance = ({ touches }) => {
  if (!touches || touches.length < 2) {
    return null;
  }

  return getTwoPointsDistance(touches[0].clientX, touches[1].clientX, touches[0].clientY, touches[1].clientY);
};

export const getPointFromMultipleTouchEvent = (evt, prevPosition) => {
  if (!prevPosition || !evt.touches || evt.touches.length < 2) {
    return getPointFromPointerEvent(evt);
  }

  let x = null;
  let y = null;
  let distance = null;

  for (let i = 0; i < evt.touches.length; i++) {
    const newDistance = getTwoPointsDistance(
      evt.touches[i].clientX,
      prevPosition.x,
      evt.touches[i].clientY,
      prevPosition.y
    );

    // Get the closest touch event to the previous one
    if (distance === null || newDistance < distance) {
      distance = newDistance;
      x = evt.touches[i].clientX;
      y = evt.touches[i].clientY;
    }
  }

  return { x, y };
};

/**
 * Creates a pointer move event listener (for mouse and touch events)
 * @param element
 * @param {function} fn The event handler function
 * @param {object} [options]
 * @param {function} [options.onStart]
 * @param {function} [options.onEnd]
 * @returns {object}
 */
export const createPointerMoveEventListener = (element, fn, options = {}) => {
  const { onStart, onEnd } = options;
  let moveListener = null;

  const removeMoveListener = () => {
    if (moveListener) {
      moveListener();

      moveListener = null;
    }
  };

  const setStartEvent = (evt) => {
    let prevPosition = getPointFromMultipleTouchEvent(evt);
    let prevZoomDistance = getTouchDistance(evt);

    removeMoveListener();

    moveListener = createPointerEventListener(element, 'move', (evt, ...args) => {
      const currentPosition = getPointFromMultipleTouchEvent(evt, prevPosition);
      const currentZoomDistance = getTouchDistance(evt);
      const change = {
        x: currentPosition.x - prevPosition.x,
        y: currentPosition.y - prevPosition.y,
        zoom:
          prevZoomDistance !== null && currentZoomDistance !== null
            ? currentZoomDistance / prevZoomDistance
            : null,
      };

      prevPosition = currentPosition;
      prevZoomDistance = currentZoomDistance;

      fn(evt, change, ...args);
    });
  };

  const startListener = createPointerEventListener(element, 'start', (evt) => {
    onStart && onStart(evt);
    setStartEvent(evt);
  });

  const endListener = createPointerEventListener(element, 'end', (evt) => {
    removeMoveListener();
    onEnd && onEnd(evt);
  });

  return {
    setStartEvent,
    remove: () => {
      startListener();
      endListener();
      removeMoveListener();
    },
  };
};

/**
 * Creates a mouse wheel event listener
 * @param {HTMLElement} element
 * @param {function} fn The event handler function
 * @returns {function} A function to be called to remove the event listener
 */
export const createMouseWheelEventListener = (element, fn) => {
  const handler = (evt, ...args) => {
    fn(evt, ...args);
  };

  element.addEventListener('wheel', handler);
  return () => {
    element.removeEventListener('wheel', handler);
  };
};

/**
 * Creates a "pinch zoom" listener using touch events
 * @param {HTMLElement} element
 * @param {function} fn The event handler function
 * @param {number} sensitivity The min distance to call the wrapped event handler
 * @return {function} A function to be called to remove the event listener
 */
export const createPinchZoomListener = (element, fn, sensitivity = 10) => {
  let prevDistance;

  const startHandler = (evt) => {
    if (evt.touches.length < 2) {
      return;
    }
    element.addEventListener('touchmove', moveHandler);
    element.addEventListener('touchend', endHandler);
    prevDistance = getTouchDistance(evt);
  };

  const moveHandler = (evt) => {
    if (evt.touches.length < 2) {
      cleanUpListeners();
      return;
    }
    const distance = getTouchDistance(evt);
    if (Math.abs(distance - prevDistance) > sensitivity) {
      fn(evt, { prevDistance, distance });
      prevDistance = distance;
    }
  };

  const endHandler = () => element.removeEventListener('touchmove', moveHandler);

  element.addEventListener('touchstart', startHandler);

  const cleanUpListeners = () => {
    element.removeEventListener('touchstart', startHandler);
  };

  return cleanUpListeners;
};

/**
 * Creates a general zoom handler that includes mouse wheel and the two finger pinch as well
 * @param {HTMLElement} element
 * @param {function} fn The event handler function
 * @return {function} A function to be called to remove the event listeners
 */
export const createZoomHandler = (element, fn) => {
  const cleanUpMouseWheelListener = createMouseWheelEventListener(element, (evt) => {
    const eventDelta = evt.deltaY || evt.detail;
    const direction = eventDelta > 0 ? 'out' : 'in';
    fn(evt, { direction });
  });
  const cleanUpPinchZoomListener = createPinchZoomListener(element, (evt, data) => {
    const { distance, prevDistance } = data;
    const direction = distance / prevDistance < 1 ? 'out' : 'in';
    fn(evt, { direction });
  });
  return () => {
    cleanUpMouseWheelListener();
    cleanUpPinchZoomListener();
  };
};
