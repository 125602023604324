import { initLocalOptionsWithDefaultOption } from '../Selectors/Option';

export const roofTypes = initLocalOptionsWithDefaultOption([
  {
    identifier: 'textile',
    externalIdentifier: 'roof_type_textile',
    variant: 'textile',
    title: 'Textildach',
  },
  {
    identifier: 'trapezoidal_sheet',
    externalIdentifier: 'roof_type_trapezoidal',
    variant: 'trapezoidal_sheet',
    title: 'Trapezblech',
  },
  {
    identifier: 'sandwich_40',
    externalIdentifier: 'roof_type_sandwich_40',
    variant: 'sandwich',
    thickness: 40,
    title: 'Sandwich 40 mm',
  },
  {
    identifier: 'sandwich_60',
    externalIdentifier: 'roof_type_sandwich_60',
    variant: 'sandwich',
    thickness: 60,
    title: 'Sandwich 60 mm',
  },
  {
    identifier: 'sandwich_80',
    externalIdentifier: 'roof_type_sandwich_80',
    variant: 'sandwich',
    thickness: 80,
    title: 'Sandwich 80 mm',
  },
  {
    identifier: 'sandwich_100',
    externalIdentifier: 'roof_type_sandwich_100',
    variant: 'sandwich',
    thickness: 100,
    title: 'Sandwich 100 mm',
  },
]);

export const defaultRoofType = roofTypes[1];

export function isTextileRoof(obj) {
  return obj.identifier === 'textile';
}
