import { Geometry, Matrix4 } from 'three';
import sliceGeometry from './sliceGeometry/sliceGeometry';

function convertGeometry(object) {
  if (object.geometry.isBufferGeometry) {
    const geometry = new Geometry();
    geometry.fromBufferGeometry(object.geometry);
    geometry.mergeVertices();
    geometry.computeFlatVertexNormals();
    object.geometry = geometry;
  }
}

export function sliceByPlanes(root, planesArray) {
  root.updateWorldMatrix(true, true);
  root.traverse((object) => {
    if (object.geometry) {
      convertGeometry(object);
      object.geometry = object.geometry.clone(true);
      const matrix = object.matrixWorld;
      object.geometry.applyMatrix4(matrix);
      for (const plane of planesArray) {
        const geometry = sliceGeometry(object.geometry, plane);
        if (geometry.vertices.length !== object.geometry.vertices.length) {
          object.geometry = geometry;
        }
      }
      object.geometry.applyMatrix4(new Matrix4().getInverse(matrix));
      object.geometry.computeFlatVertexNormals();
    }
  });
}
