export default function createConfigurationNotes(configuration) {
  const getNotes = () => {
    return configuration.getData().notes || [];
  };
  const setNotes = (notes) => {
    configuration.setData({ ...configuration.getData(), notes });
  };
  const saveNote = (note) => {
    const currentNotes = getNotes();
    const notes = currentNotes.filter(({ noteId }) => noteId !== note.noteId);
    notes.push({ ...note, noteId: note.noteId || ++noteCounter });
    setNotes(notes);
  };
  const findNoteById = (id) => {
    return getNotes().find(({ noteId }) => noteId === id);
  };
  const deleteNoteById = (id) => {
    setNotes(getNotes().filter(({ noteId }) => noteId !== id));
  };
  const findLastNoteId = () => {
    const noteIds = getNotes().map(({ noteId }) => noteId);
    return Math.max(...noteIds, 0);
  };
  let noteCounter = findLastNoteId();
  return {
    saveNote,
    getNotes,
    findNoteById,
    deleteNoteById,
  };
}
