import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import HintPopup from '../HintPopup';
import touchIcon from './icons/touch.svg';
import { makeStyles } from '@material-ui/core/styles';
import useLocalStorage from '../../Hooks/useLocalStorage';
import { LoaderStateContext } from '../../ContextProviders/LoaderStateProvider';

const useStyles = makeStyles((theme) => ({
  text: {
    marginRight: 20,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9em',
    },
    maxWidth: 200,
  },
}));

const HowToConfigureHint = () => {
  const isLoading = useContext(LoaderStateContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const [show, setShow] = useLocalStorage('showHintPopup', true);
  const handleClose = () => setShow(false);
  return (
    <HintPopup open={!isLoading && show} onClose={handleClose}>
      <img src={touchIcon} alt="hint icon" />
      <Typography className={classes.text}>{t('howToConfigureHint.text')}</Typography>
    </HintPopup>
  );
};

export default HowToConfigureHint;
