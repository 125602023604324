import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Fade from '@material-ui/core/Fade';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import DialogContent from '@material-ui/core/DialogContent';
import { InteractionStarted, ItemSelected } from '../Visualization/EventTypes';
import { useMergedState } from './Hooks/useMergedState';
import MultiLevelMenu from './Components/MultiLevelMenu';
import { VisualizationContext } from './ContextProviders/VisualizationProvider';
import { getMenuItems, isEditableItem } from './DataStructures/contextMenu';
import { ConfigurationContext } from './ContextProviders/ConfigurationProvider';
import MenuTitleBar from './Components/MenuTitleBar';
import canWallSegmentGetObjectType from './Rules/canWallSegmentGetObjectType';
import { CLONE, REMOVE } from '../Constants/ItemActions';
import { useSelector } from 'react-redux';
import { getOptionAttributeValue } from './Selectors/Option';
import { ToolbarButton } from './Components/VisuailzationToolbar/ToolbarButton';
import getMenuIcons from './Icons/getMenuIcons';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { getPointFromPointerEvent } from '../Utils/pointerEvents';
import { ActivePopupContext } from './ContextProviders/ActivePopupProvider';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: 1,
    height: 1,
  },
  popoverRoot: {
    pointerEvents: 'none',
  },
  paper: {
    pointerEvents: 'all',
  },
  list: {
    paddingTop: 0,
    outline: 'none',
    '&$flexList': {
      display: 'flex',
    },
  },
  menuItemImage: {
    display: 'inline-flex',
    height: 100,
    width: 100,
    background: 'gray',
    marginRight: 20,
  },
  flexList: {},
  icon: {
    width: 35,
    height: 30,
    display: 'inline-flex',
    color: '#ED1C29',
    opacity: 0.38,
    marginLeft: 'auto',
  },
});

const TileMenuItem = withStyles((theme) => ({
  root: {
    padding: '6px 24px',
    '&:focus, &:hover': {
      background: 'none',
      color: theme.palette.secondary.main,
    },
    '&$selected': {
      background: 'none',
      cursor: 'default',
      fontWeight: 'bold',
      '&:focus, &:hover': {
        color: 'inherit',
        background: 'none',
      },
    },
  },
  selected: {},
}))(MenuItem);

const BackMenuItem = withStyles(() => ({
  root: {
    fontWeight: 'bold',
    margin: '12px 0',
  },
}))(TileMenuItem);

const MenuDialogContent = withStyles({
  root: {
    padding: '8px 0',
  },
})(DialogContent);

const InlineMenuItem = withStyles(() => ({
  root: {
    flex: 1,
    '& svg': {
      marginRight: 12,
    },
  },
}))(TileMenuItem);
InlineMenuItem.componentName = 'InlineMenuItem';

const defaultTransformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const editTransformOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const ContextMenu = () => {
  const { t } = useTranslation();
  const [state, updateState] = useMergedState({ show: false, top: 0, left: 0 });
  const visualization = useContext(VisualizationContext);
  const configuration = useContext(ConfigurationContext);
  const { componentOptions } = useSelector((state) => state.Option);

  const { addObject, removeObject } = configuration;

  const classes = useStyles();

  const { show, top, left, items, targetObject, showEditActions } = state;

  useEffect(() => {
    if (visualization) {
      return visualization.on(ItemSelected, ({ data: { object, event } }) => {
        if (object.userData.editable) {
          const items = getMenuItems(
            object,
            componentOptions,
            t('itemContextMenu.windowTitle'),
            t('itemContextMenu.gateTitle')
          );
          if (!items.length) {
            return;
          }
          const { x = 0, y = 0 } = getPointFromPointerEvent(event);
          updateState({
            show: true,
            left: x,
            top: y,
            targetObject: object,
            showEditActions: isEditableItem(object.userData.type),
            items,
          });
        } else {
          updateState({ show: false });
        }
      });
    }
  }, [t, componentOptions, visualization, updateState]);

  const handleClose = useCallback(() => updateState({ show: false }), [updateState]);

  useEffect(() => {
    if (show) {
      return visualization.on(InteractionStarted, () => {
        updateState({ show: false });
      });
    }
  }, [show, visualization, updateState]);

  useContext(ActivePopupContext).useActive('ContextMenu', handleClose, show);

  const handleSelect = useCallback(
    async (item, evt) => {
      updateState({ show: false });
      if (item.action === REMOVE) {
        const {
          userData: { rangeIndex },
        } = targetObject;
        removeObject(targetObject.warehouseObjectRef, { rangeIndex });
      } else if (item.action === CLONE) {
        const clone = await configuration.cloneObject(targetObject.warehouseObjectRef);
        clone && visualization.objectControls.setActiveObjectByRef(clone, evt);
      } else {
        const {
          userData: { side, index },
        } = targetObject;
        const type = getOptionAttributeValue(item, 'type', item.identifier);
        const height = getOptionAttributeValue(item, 'height') / 1000;
        addObject(type, side, index, item.identifier, height, item);
      }
    },
    [targetObject, addObject, removeObject, updateState, configuration, visualization]
  );

  const isItemDisabled = useCallback(
    (item) => {
      const {
        userData: { side, index },
      } = targetObject;
      const type = getOptionAttributeValue(item, 'type') || item.type;
      const height = getOptionAttributeValue(item, 'height') / 1000;
      return targetObject && !canWallSegmentGetObjectType(configuration.getData(), type, side, index, height);
    },
    [targetObject, configuration]
  );

  const updateDialogPosition = useCallback(() => {
    top && updateState();
  }, [top, updateState]);

  return (
    <Popover
      open={show}
      onClose={handleClose}
      TransitionComponent={Fade}
      classes={{
        root: classes.popoverRoot,
        paper: classes.paper,
      }}
      anchorReference="anchorPosition"
      anchorPosition={{
        top,
        left,
      }}
      transformOrigin={showEditActions ? editTransformOrigin : defaultTransformOrigin}
    >
      <MenuTitleBar
        title={t(showEditActions ? 'itemContextMenu.editTitle' : 'itemContextMenu.addTitle')}
        onClose={handleClose}
      />
      <MenuDialogContent>
        <MenuList
          classes={{ root: clsx(classes.list, showEditActions && classes.flexList) }}
          autoFocusItem={true}
          variant={'menu'}
        >
          <MultiLevelMenu
            show={show}
            items={items}
            onSelect={handleSelect}
            onRendered={updateDialogPosition}
            renderMenuItem={({ item, level, selected, onClick }) => {
              const isDisabled = isItemDisabled(item);
              if (showEditActions) {
                const { action, IconComponent } = item;
                return (
                  <InlineMenuItem key={action} onClick={onClick} disabled={isDisabled}>
                    {IconComponent && <IconComponent />} {t(`itemActions.${action}`)}
                  </InlineMenuItem>
                );
              }
              if (selected || level === 0) {
                const { iconActive, iconInactive } = getMenuIcons(item.identifier);
                return (
                  <TileMenuItem
                    className={classes.mainMenuItem}
                    key={item.identifier}
                    onClick={onClick}
                    disabled={isDisabled}
                    selected={selected}
                  >
                    <ToolbarButton iconSrc={iconInactive} activeIconSrc={iconActive} active={selected} />
                    {item.title}
                    {isDisabled && <ErrorOutline className={classes.icon} />}
                  </TileMenuItem>
                );
              }
              return (
                <TileMenuItem key={item.identifier} onClick={onClick} disabled={isDisabled}>
                  {item.title}
                  {isDisabled && <ErrorOutline className={classes.icon} />}
                </TileMenuItem>
              );
            }}
            renderBackButton={({ onClick }) => (
              <BackMenuItem onClick={onClick}>
                <ChevronLeft /> {t('back')}
              </BackMenuItem>
            )}
          />
        </MenuList>
      </MenuDialogContent>
    </Popover>
  );
};

export default ContextMenu;
