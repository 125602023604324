import colorsActive from './menuIcons/colors_active.svg';
import colorsInactive from './menuIcons/colors_inactive.svg';
import dimensionsActive from './menuIcons/dimensions_active.svg';
import dimensionsInactive from './menuIcons/dimensions_inactive.svg';
import roofActive from './menuIcons/roof_active.svg';
import roofInactive from './menuIcons/roof_inactive.svg';
import wallsActive from './menuIcons/walls_active.svg';
import wallsInactive from './menuIcons/walls_inactive.svg';
import warehouseTypeActive from './menuIcons/warehouseType_active.svg';
import warehouseTypeInactive from './menuIcons/warehouseType_inactive.svg';
import doorActive from './menuIcons/door_active.svg';
import doorInactive from './menuIcons/door_inactive.svg';
import gateActive from './menuIcons/gate_active.svg';
import gateInactive from './menuIcons/gate_inactive.svg';
import lightbandActive from './menuIcons/lightband_active.svg';
import lightbandInactive from './menuIcons/lightband_inactive.svg';
import windowActive from './menuIcons/window_active.svg';
import windowInactive from './menuIcons/window_inactive.svg';

export default function getMenuIcons(identifier) {
  let iconActive;
  let iconInactive;
  switch (identifier) {
    case 'colors':
      iconActive = colorsActive;
      iconInactive = colorsInactive;
      break;
    case 'dimensions':
      iconActive = dimensionsActive;
      iconInactive = dimensionsInactive;
      break;
    case 'door':
      iconActive = doorActive;
      iconInactive = doorInactive;
      break;
    case 'gate':
      iconActive = gateActive;
      iconInactive = gateInactive;
      break;
    case 'roof':
      iconActive = roofActive;
      iconInactive = roofInactive;
      break;
    case 'wall_lightband':
      iconActive = lightbandActive;
      iconInactive = lightbandInactive;
      break;
    case 'walls':
      iconActive = wallsActive;
      iconInactive = wallsInactive;
      break;
    case 'warehouseType':
      iconActive = warehouseTypeActive;
      iconInactive = warehouseTypeInactive;
      break;
    case 'window':
      iconActive = windowActive;
      iconInactive = windowInactive;
      break;
  }
  return {
    iconActive,
    iconInactive,
  };
}
