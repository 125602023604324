import find from 'lodash/find';
import { RECEIVE_OPTIONS, REQUEST_ERROR } from './Actions';
import { createEmbeddedOptions } from '../../Selectors/Option';

const initialState = {
  optionsLoaded: false,
  componentOptions: [
    {
      key: 'colors',
      identifier: 'colors',
      options: [],
    },
    {
      key: 'wall_elements',
      identifier: 'wall_elements',
      options: [],
    },
    {
      key: 'walls',
      identifier: 'walls',
      options: [],
    },
    {
      key: 'roof',
      identifier: 'roof',
      options: [],
    },
  ],
  error: null,
};

export default function OptionReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_OPTIONS: {
      const { key, options } = action;
      const newState = state;
      find(newState.componentOptions, (component) => component.key === key).options = options;
      newState.optionsLoaded = true;
      newState.componentOptions.forEach((component) => {
        if (component.options.length === 0) {
          newState.optionsLoaded = false;
        }
      });

      if (newState.optionsLoaded) {
        newState.localOptions = createEmbeddedOptions(newState.componentOptions);
      }

      return newState;
    }

    case REQUEST_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }

    default:
      return state;
  }
}
