import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import DialogContent from '@material-ui/core/DialogContent';
import Colors from './Content/Colors';
import Dimensions from './Content/Dimensions';
import configurationMenuItems from '../../DataStructures/configurationMenu';
import WarehouseType from './Content/WarehouseType';
import Walls from './Content/Walls';
import Roof from './Content/Roof';
import MenuTitleBar from '../MenuTitleBar';
import { ToolbarButton } from '../VisuailzationToolbar/ToolbarButton';
import getMenuIcons from '../../Icons/getMenuIcons';
import useMergedState from '../../Hooks/useMergedState';
import { useTranslation } from 'react-i18next';
import { ActivePopupContext } from '../../ContextProviders/ActivePopupProvider';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      [theme.media.landscape]: {
        flex: 1,
      },
      [theme.breakpoints.up('sm')]: {
        [theme.media.portrait]: {
          flex: 1,
        },
      },
    },
    menuItem: {
      display: 'flex',
      cursor: 'pointer',
      width: 120,
      maxWidth: '20%',
    },
    menuItemInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        transform: 'scale(0.8)',
      },
    },
    menuItemText: {
      fontSize: '0.875rem',
      [theme.breakpoints.down('xs')]: {
        [theme.media.portrait]: {
          fontSize: '0.8rem',
        },
      },
    },
    popoverRoot: {
      pointerEvents: 'none',
    },
    paper: {
      pointerEvents: 'all',
      minWidth: '300px !important',
    },
  };
});

const MenuItem = ({ children, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.menuItem} onClick={onClick}>
      <div className={classes.menuItemInner}>{children}</div>
    </div>
  );
};

MenuItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

const ContentWrapper = ({ identifier, selectedIdentifier, ContentComponent, keepMounted, onClose }) =>
  keepMounted ? (
    <div style={identifier === selectedIdentifier ? {} : { display: 'none' }}>
      <ContentComponent onClose={onClose} />
    </div>
  ) : identifier === selectedIdentifier ? (
    <ContentComponent />
  ) : null;

ContentWrapper.propTypes = {
  onClose: PropTypes.func,
  selectedIdentifier: PropTypes.string,
  identifier: PropTypes.string.isRequired,
  keepMounted: PropTypes.bool,
  ContentComponent: PropTypes.func,
};

const MenuContent = ({ identifier, onClose }) => (
  <>
    <ContentWrapper
      ContentComponent={WarehouseType}
      identifier={'warehouseType'}
      selectedIdentifier={identifier}
      keepMounted
      onClose={onClose}
    />
    <ContentWrapper ContentComponent={Dimensions} identifier={'dimensions'} selectedIdentifier={identifier} />
    <ContentWrapper ContentComponent={Colors} identifier={'colors'} selectedIdentifier={identifier} />
    <ContentWrapper ContentComponent={Walls} identifier={'walls'} selectedIdentifier={identifier} />
    <ContentWrapper ContentComponent={Roof} identifier={'roof'} selectedIdentifier={identifier} />
  </>
);

MenuContent.propTypes = {
  onClose: PropTypes.func,
  identifier: PropTypes.string.isRequired,
};

const ConfigurationMenu = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [menuState, setMenuState] = useMergedState({});
  const { selected, anchorEl } = menuState;

  const handleClick = (event, item) => {
    if (anchorEl === event.currentTarget) {
      setMenuState({ anchorEl: null });
    } else {
      setMenuState({ selected: item, anchorEl: event.currentTarget });
    }
  };

  const handleClose = useCallback(() => {
    setMenuState({ anchorEl: null });
  }, [setMenuState]);

  const open = Boolean(anchorEl);

  useContext(ActivePopupContext).useActive('ConfigurationMenu', handleClose, open);

  return (
    <div className={classes.root}>
      {configurationMenuItems.map((item) => {
        const { iconActive, iconInactive } = getMenuIcons(item.identifier);
        return (
          <MenuItem key={item.identifier} onClick={(evt) => handleClick(evt, item)}>
            <ToolbarButton
              iconSrc={iconInactive}
              activeIconSrc={iconActive}
              active={open && selected ? selected.identifier === item.identifier : false}
            />
            <Typography className={classes.menuItemText}>
              {t(`configurationMenu.${item.identifier}`)}
            </Typography>
          </MenuItem>
        );
      })}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -20,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 999999,
        }}
        classes={{
          root: classes.popoverRoot,
          paper: classes.paper,
        }}
      >
        {selected && (
          <>
            <MenuTitleBar title={t(`configurationMenu.${selected.identifier}`)} onClose={handleClose} />
            <DialogContent>
              <MenuContent identifier={selected.identifier} onClose={handleClose} />
            </DialogContent>
          </>
        )}
      </Popover>
    </div>
  );
};
export default ConfigurationMenu;
