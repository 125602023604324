import { Raycaster, Vector2, Vector3 } from 'three';
import { getPointFromPointerEvent } from '../../Utils/pointerEvents';

function createMouseRayCaster(pointerEvent, renderer, camera) {
  const raycaster = new Raycaster();
  const {
    width: elementWidth,
    height: elementHeight,
    left: offsetX,
    top: offsetY,
  } = renderer.domElement.getBoundingClientRect();
  const point = getPointFromPointerEvent(pointerEvent);
  const mouse = new Vector2(
    ((point.x - offsetX) / elementWidth) * 2 - 1,
    -((point.y - offsetY) / elementHeight) * 2 + 1
  );
  raycaster.setFromCamera(mouse, camera);
  return raycaster;
}

export function getIntersection(pointerEvent, renderer, camera, root, findIntersection) {
  const raycaster = createMouseRayCaster(pointerEvent, renderer, camera);

  const intersects =
    root.children && root.children.length
      ? raycaster.intersectObjects(root.children, true)
      : raycaster.intersectObject(root, true);
  if (intersects.length > 0) {
    return findIntersection ? findIntersection(intersects) : intersects[0]; // closest object
  }

  return null;
}

export function createIntersectionQuery(pointerEvent, renderer, camera, findIntersection) {
  const raycaster = createMouseRayCaster(pointerEvent, renderer, camera);

  const getIntersection = (root) => {
    const intersects = raycaster.intersectObject(root, true);
    if (intersects.length > 0) {
      return findIntersection ? findIntersection(intersects) : intersects[0]; // closest object
    }
    return null;
  };
  return {
    getIntersection,
  };
}

export function getIntersectionWithPlane(pointerEvent, renderer, camera, plane) {
  const raycaster = createMouseRayCaster(pointerEvent, renderer, camera);
  const point = new Vector3();
  return raycaster.ray.intersectPlane(plane, point);
}

export default getIntersection;

export function findClosestEditable(object) {
  if (object.userData && object.userData.editable) {
    return object;
  }
  return object.parent ? findClosestEditable(object.parent) : null;
}
