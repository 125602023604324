import axios from 'axios';
import qs from 'qs';
import networkConf from './Config/network';
import getLocationLanguageParam from '../Utils/getLocationLanguageParam';

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

/**
 * axios instance to handle API requests
 */
const ax = axios.create({
  baseURL: networkConf.baseURL,
  timeout: networkConf.timeout,
  cancelToken: source.token,
  params: {
    _language: getLocationLanguageParam(),
  },

  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

export function createRequest() {
  let cancelSource = CancelToken.source();
  return {
    request: (config) => {
      config = typeof config === 'string' ? { url: config } : config;
      return axios.request({
        ...ax.defaults,
        ...config,
        cancelToken: cancelSource.token,
      });
    },
    cancel: () => {
      cancelSource.cancel();
      cancelSource = CancelToken.source();
    },
  };
}
