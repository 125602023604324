import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade/Fade';
import Popover from '@material-ui/core/Popover/Popover';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 'calc(100% - 70px)',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    padding: 20,
    boxShadow: 'none',
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const HintPopup = ({ open, onClose, children }) => {
  const classes = useStyles();
  return (
    <Popover
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      classes={{
        paper: classes.paper,
      }}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: window.innerHeight * 0.56,
        left: window.innerWidth * 0.2,
      }}
    >
      <IconButton
        className={classes.closeButton}
        color="inherit"
        aria-label="close"
        component="span"
        size="small"
        onClick={onClose}
      >
        <Close />
      </IconButton>
      {children}
    </Popover>
  );
};

HintPopup.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default HintPopup;
