import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';

export const DialogContentDivider = withStyles({
  root: {
    margin: '10px -24px',
  },
})(Divider);

export default DialogContentDivider;
