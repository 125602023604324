import {
  BoxGeometry,
  Color,
  CylinderBufferGeometry,
  DoubleSide,
  Matrix4,
  Mesh,
  MeshPhongMaterial,
  MeshStandardMaterial,
  PlaneBufferGeometry,
  RepeatWrapping,
  TextureLoader,
} from 'three';

import { DOOR, GATE, WALL_LIGHTBAND, WINDOW } from '../Constants/ObjectTypes';
import tex2 from './Textures/tex2.jpg';
import tex1_100 from './Textures/tex1_100.jpg';
import { addData } from './constructionTools';

function addHorizontalTexturePattern(material, url, repeatX) {
  material.color = new Color(1, 1, 1);
  const texture = new TextureLoader().load(url);
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.repeat.set(repeatX, 1);
  texture.anisotropy = 8;
  material.map = texture;
}

export function createSampleSegment(segmentWidth, texture = null, height = 1) {
  const geometry = new BoxGeometry(segmentWidth, height, 0.02);
  const material = new MeshStandardMaterial({ color: 0xffffff, roughness: 0.8, metalness: 0.1 });
  texture && addHorizontalTexturePattern(material, texture, segmentWidth);
  return new Mesh(geometry, material);
}

// eslint-disable-next-line no-unused-vars
function createSampleGround() {
  const ground = new Mesh(new PlaneBufferGeometry(1, 1), new MeshPhongMaterial({ color: 0x3a2e27 }));
  ground.scale.multiplyScalar(100);
  ground.name = 'ground';
  ground.rotation.x = -Math.PI / 2;
  return {
    ground,
  };
}

export function createSampleTypedSegments(segmentWidth) {
  return {
    trapezoidal_sheet: createSampleSegment(segmentWidth, tex2),
    sandwich: createSampleSegment(segmentWidth, tex1_100),
    textile_pvc_top: createSampleSegment(segmentWidth),
    textile_pvc_overlapping: createSampleSegment(segmentWidth, null, 0.3),
  };
}

export function createSampleProfiles() {
  const wallProfile = new Mesh(
    new BoxGeometry(0.2, 1, 0.2),
    new MeshStandardMaterial({ color: 0xffff00, roughness: 0.8, metalness: 0.2 })
  );
  const roofProfile = new Mesh(
    new BoxGeometry(1, 0.1, 1),
    new MeshStandardMaterial({ color: 0xffff00, roughness: 0.8, metalness: 0.2 })
  );
  return {
    wallProfile,
    roofProfile,
  };
}

export function createSampleFloor() {
  const floor = new Mesh(
    new BoxGeometry(1, 0.05, 1),
    new MeshStandardMaterial({ color: 0x666666, roughness: 1, metalness: 0.8 })
  );
  floor.name = 'ground';
  floor.setColor = setColor;
  return {
    floor,
  };
}

export function createSampleRainGutter() {
  const radius = 0.1;
  const rotateZ = new Matrix4().makeRotationZ(-Math.PI / 2);
  const material = new MeshStandardMaterial({ color: '#FF8888', metalness: 0.6, side: DoubleSide });
  const sideGeometry = new CylinderBufferGeometry(radius, radius, 0.02, 32, 1, false, 0, Math.PI);
  const bodyGeometry = new CylinderBufferGeometry(radius, radius, 5, 32, 1, true, 0, Math.PI);
  sideGeometry.applyMatrix4(rotateZ);
  bodyGeometry.applyMatrix4(rotateZ);

  const raingutter_left = new Mesh(sideGeometry, material);
  const raingutter_right = new Mesh(sideGeometry, material);
  const raingutter_body = new Mesh(bodyGeometry, material);
  return {
    raingutter_body,
    raingutter_left,
    raingutter_right,
  };
}

export function createSampleDomeWindow() {
  const material = new MeshStandardMaterial({ color: '#8888FF', metalness: 0.6 });
  const geometry = new BoxGeometry(1.5, 0.3, 1.5);
  const domeWindow = new Mesh(geometry, material);
  return {
    domeWindow,
  };
}

export function createSampleRidgeLightBand() {
  const material = new MeshStandardMaterial({ color: '#8888FF', metalness: 0.6 });
  const geometry = new BoxGeometry(20, 0.3, 1.5);
  const ridgeLightband = new Mesh(geometry, material);
  return {
    ridgeLightband,
  };
}

function setColor(color) {
  this.material.color.set(color);
}

function createSampleWindow(width, material) {
  const window = new Mesh(new BoxGeometry(width, 1, 0.15), material);
  addData(window, {
    group: WINDOW,
  });
  window.setColor = setColor;
  return window;
}

function createSampleLightBand(width, material) {
  const lightband = new Mesh(new BoxGeometry(width, 0.8, 0.15), material);
  addData(lightband, {
    group: WALL_LIGHTBAND,
  });
  lightband.setColor = setColor;
  return lightband;
}

function createSampleWallObjects() {
  const material = new MeshStandardMaterial({ metalness: 0.1, roughness: 1 });

  const door = new Mesh(new BoxGeometry(1, 2, 0.15), material.clone());
  door.frame = door;
  door.setColor = setColor;

  const gate350 = new Mesh(new BoxGeometry(4.88, 3.5, 0.15), material);
  gate350.setColor = setColor;
  const gate420 = new Mesh(new BoxGeometry(4.88, 4.2, 0.15), material);
  gate420.setColor = setColor;
  addData(door, {
    group: DOOR,
  });
  addData(gate350, {
    group: GATE,
  });
  addData(gate420, {
    group: GATE,
  });

  const rolling_gate_350_singleshell = gate350;
  const rolling_gate_420_singleshell = gate420;
  const rolling_gate_350_insulated = gate350;
  const rolling_gate_420_insulated = gate420;
  const sectional_gate_350 = gate350;
  const sectional_gate_420 = gate420;
  const pvc_gate_350 = gate350;
  const pvc_gate_420 = gate420;

  const windowMaterial = material.clone();
  const window_1leaves = createSampleWindow(1, windowMaterial);
  const window_2leaves = createSampleWindow(2, windowMaterial);
  const window_3leaves = createSampleWindow(3, windowMaterial);
  const ventilation_grille_300 = createSampleWindow(3, windowMaterial);
  const ventilation_grille_100 = createSampleWindow(1, windowMaterial);

  const wall_lightband = createSampleLightBand(4.8, windowMaterial);
  const wall_lightband_left = createSampleLightBand(0.3, windowMaterial);
  const wall_lightband_body = createSampleLightBand(1, windowMaterial);
  const wall_lightband_right = createSampleLightBand(0.3, windowMaterial);

  return {
    door,
    rolling_gate_350_singleshell,
    rolling_gate_420_singleshell,
    rolling_gate_350_insulated,
    rolling_gate_420_insulated,
    sectional_gate_350,
    sectional_gate_420,
    pvc_gate_350,
    pvc_gate_420,
    window_1leaves,
    window_2leaves,
    window_3leaves,
    ventilation_grille_100,
    ventilation_grille_300,
    wall_lightband,
    wall_lightband_body,
    wall_lightband_left,
    wall_lightband_right,
  };
}

export function createSampleElements() {
  return {
    ...createSampleGround(),
    ...createSampleFloor(),
    ...createSampleProfiles(),
    ...createSampleRainGutter(),
    ...createSampleDomeWindow(),
    ...createSampleRidgeLightBand(),
    ...createSampleWallObjects(),
  };
}
