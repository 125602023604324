export function disposeMaterial(material) {
  const textures = Object.values(material).filter((propValue) => propValue && propValue.isTexture);
  for (let texture of textures) {
    texture.dispose();
    texture.image = null;
    texture.originalImage = null;
  }
  material.dispose();
}

export default disposeMaterial;
