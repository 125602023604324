import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withStyles } from '@material-ui/core/styles';
import { setVisible } from '../../../../Reducers/Ui/Actions';
import { useDispatch } from 'react-redux';

const LoadButton = withStyles({
  root: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 8px',
  },
  icon: {
    display: 'inline-flex',
    color: '#4F4F4F',
  },
  label: {
    fontSize: '0.9em',
  },
})(({ classes }) => {
  const dispatch = useDispatch();

  const onLoad = () => {
    dispatch(setVisible('loadConfiguration', true));
  };
  return (
    <div className={classes.root} onClick={onLoad}>
      <CloudUploadIcon className={classes.icon} />
    </div>
  );
});

export default LoadButton;
