import ReactDOM from 'react-dom';
import React from 'react';
import './App/i18n';
import Main from './App/Main';
import { VisualizationProvider } from './App/ContextProviders/VisualizationProvider';
import { ConfigurationProvider } from './App/ContextProviders/ConfigurationProvider';
import { createVisualization } from './Visualization/createVisualization';
import createWarehouseConfiguration from './App/createWarehouseConfiguration';
import { CustomThemeProvider } from './theme';
import { createObjectControls } from './Visualization/objectControls';
import { Provider } from 'react-redux';
import Store from './App/Store';
import { InitContainer } from './App/InitContainer';
import { LoaderStateProvider } from './App/ContextProviders/LoaderStateProvider';
import { globalLoaderStateManager } from './App/GlobalLoader';
import isDevMode from './Utils/isDevMode';
import ActivePopupProvider from './App/ContextProviders/ActivePopupProvider';

const configuration = createWarehouseConfiguration();
const visualization = createVisualization(configuration);
createObjectControls(visualization, configuration);

// expose objects for console hacks in dev mode
if (isDevMode()) {
  window.visualization = visualization;
  window.configuration = configuration;
}

ReactDOM.render(
  <Provider store={Store}>
    <LoaderStateProvider loaderStateManager={globalLoaderStateManager}>
      <ConfigurationProvider configuration={configuration}>
        <VisualizationProvider visualization={visualization}>
          <CustomThemeProvider>
            <ActivePopupProvider>
              <InitContainer>
                <Main />
              </InitContainer>
            </ActivePopupProvider>
          </CustomThemeProvider>
        </VisualizationProvider>
      </ConfigurationProvider>
    </LoaderStateProvider>
  </Provider>,
  document.getElementById('root')
);
