import Observable from '../Utils/Observable';

import { defaultColor } from './DataStructures/colors';
import { defaultWallType } from './DataStructures/wallTypes';
import { defaultRoofType, isTextileRoof } from './DataStructures/roofTypes';
import { defaultRoofWindowType, isRidgeLightBand, NO_ROOF_WINDOW } from './DataStructures/roofWindowTypes';
import { isXSide, isZSide } from './Selectors/Side';
import { canWallSegmentGetObjectType } from './Rules/canWallSegmentGetObjectType';
import { combineRangeObjects, isRangeObject, removeFromRange } from './Selectors/RangeObject';

/**
 * Converts the centerOffsetX local value (relative to segment) to absolute value (relative to the wall)
 * @param {object} object
 * @param {object} warehouseConfiguration
 * @return {number}
 */
function calculateAbsoluteCenterOffsetX(object, warehouseConfiguration) {
  if (object.absoluteOffset) {
    return object.centerOffsetX;
  }
  const {
    xWallSegmentWidth,
    zWallSegmentWidth,
    xWallSegmentCount,
    zWallSegmentCount,
  } = warehouseConfiguration;
  const segmentSize = isXSide(object.side) ? xWallSegmentWidth : zWallSegmentWidth;
  const segmentCount = isXSide(object.side) ? xWallSegmentCount : zWallSegmentCount;
  return segmentSize * object.index + object.centerOffsetX - (segmentCount - 1) * segmentSize * 0.5;
}

function findNextFreeIndexForRangeObject(warehouseConfiguration, object) {
  const { type, side, indexFrom, indexTo } = object;
  const { xWallSegmentCount, zWallSegmentCount } = warehouseConfiguration;
  const segmentCount = isXSide(object.side) ? xWallSegmentCount : zWallSegmentCount;
  for (let index = indexTo; index < segmentCount; index++) {
    if (canWallSegmentGetObjectType(warehouseConfiguration, type, side, index)) {
      return { index };
    }
  }
  for (let index = indexFrom; index >= 0; index--) {
    if (canWallSegmentGetObjectType(warehouseConfiguration, type, side, index)) {
      return { index };
    }
  }
  return false;
}

const initialState = {
  width: 15,
  length: 15,
  height: 4.2,
  xWallSegmentWidth: 5,
  zWallSegmentWidth: 5,
  wallType: defaultWallType,
  roofType: defaultRoofType,
  wallColor: defaultColor,
  consoleColor: defaultColor,
  roofColor: defaultColor,
  rainGutter: false,
  roofWindowType: defaultRoofWindowType,
  isRoofWindowEnabled: true,
  isRidgeLightBandEnabled: false,
  doorColor: defaultColor,
  gateColor: defaultColor,
  windowColor: defaultColor,
  objects: [],
};

export const ChangeEvent = 'change';
export const EventActions = {
  setProp: 'setProp',
  addObject: 'addObject',
  removeObject: 'removeObject',
  cloneObject: 'cloneObject',
  setData: 'setData',
};

function createWarehouseConfiguration(state = { ...initialState }) {
  calculateDerivedData(state);
  const observable = new Observable();
  const { on, dispatch } = observable;

  const createSetter = (prop) => (value) => {
    state[prop] = value;
    calculateDerivedData(state);
    dispatch(ChangeEvent, { action: EventActions.setProp, prop, value, state });
  };

  const setWidth = createSetter('width');
  const setLength = createSetter('length');
  const setHeight = createSetter('height');
  const setWallType = createSetter('wallType');
  const setRoofType = createSetter('roofType');
  const setWallColor = createSetter('wallColor');
  const setConsoleColor = createSetter('consoleColor');
  const setRoofColor = createSetter('roofColor');
  const setRainGutter = createSetter('rainGutter');
  const setRoofWindowType = createSetter('roofWindowType');
  const setDoorColor = createSetter('doorColor');
  const setGateColor = createSetter('gateColor');
  const setWindowColor = createSetter('windowColor');

  const getData = () => state;

  const onChange = (fn, propFilter = null) =>
    on(ChangeEvent, !propFilter ? fn : (obj) => propFilter.indexOf(obj.data.prop) !== -1 && fn(obj));

  /**
   * Adds an object (type) to a wall segment (defined by side and index)
   * @param {string} type
   * @param {string} side
   * @param {number} index
   * @param {string} [variant]
   * @param {number || null} height
   * @param {*} option
   */
  const addObject = (type, side, index, variant, height = null, option = null) => {
    if (!canWallSegmentGetObjectType(state, type, side, index, height)) {
      return;
    }
    const { objects } = state;
    const object = {
      type,
      side,
      index,
      variant,
      centerOffsetX: 0,
      centerOffsetY: 0,
      height,
      option,
    };

    if (isRangeObject(type)) {
      combineRangeObjects(objects, object);
    } else {
      objects.push(object);
    }

    dispatch(ChangeEvent, { action: EventActions.addObject, prop: 'objects', objects, state, object });
  };

  const cloneObject = (object) => {
    const { objects } = state;
    let clonedObject;

    if (isRangeObject(object.type)) {
      // if it's a range object, cloning means extending by one
      const index = findNextFreeIndexForRangeObject(state, object);
      if (index) {
        clonedObject = combineRangeObjects(objects, {
          ...object,
          ...index,
        });
      } else {
        throw new Error('No space left to clone object!');
      }
    } else {
      clonedObject = {
        ...object,
        index: null,
        absoluteOffset: true,
        centerOffsetX: calculateAbsoluteCenterOffsetX(object, state),
      };
      objects.push(clonedObject);
    }
    dispatch(ChangeEvent, {
      action: EventActions.cloneObject,
      prop: 'objects',
      objects,
      state,
      object: clonedObject,
    });
    return clonedObject;
  };

  const removeObject = (objectToRemove, options = {}) => {
    const { objects } = state;
    if (isRangeObject(objectToRemove.type)) {
      const { rangeIndex } = options;
      removeFromRange(objects, objectToRemove, rangeIndex);
    } else {
      const index = objects.indexOf(objectToRemove);
      if (index !== -1) {
        objects.splice(index, 1);
      }
    }
    dispatch(ChangeEvent, { action: EventActions.removeObject, prop: 'objects', objects, state });
  };

  const setData = (data) => {
    state = data;
    calculateDerivedData(state);
    dispatch(ChangeEvent, { action: EventActions.setData, state });
  };

  return {
    setWidth,
    setLength,
    setHeight,
    setWallType,
    setRoofType,
    setWallColor,
    setConsoleColor,
    setRoofColor,
    setRainGutter,
    setRoofWindowType,
    setDoorColor,
    setGateColor,
    setWindowColor,

    addObject,
    removeObject,
    cloneObject,

    onChange,
    getData,
    setData,
  };
}

function isObjectInRange({ side, index }, state) {
  const { xWallSegmentCount, zWallSegmentCount } = state;
  return (isXSide(side) && index < xWallSegmentCount) || (isZSide(side) && index < zWallSegmentCount);
}

function filterObjectsInRange(state) {
  return (state.objects || []).filter((object) => {
    return isRangeObject(object.type) || isObjectInRange(object, state);
  });
}

function calculateDerivedData(state) {
  const isLargestSize = state.width === 30 && state.height === 6.2;
  const xWallSegmentWidth = isLargestSize ? 4 : 5;
  let length = Math.max(state.length, state.width);
  length = length - (length % xWallSegmentWidth);

  const isRoofWindowEnabled = !isTextileRoof(state.roofType);
  const isRidgeLightBandEnabled = isRoofWindowEnabled && length >= 30;

  const removeRoofWindow =
    !isRoofWindowEnabled || (!isRidgeLightBandEnabled && isRidgeLightBand(state.roofWindowType));
  const roofWindowType = removeRoofWindow ? NO_ROOF_WINDOW : state.roofWindowType;

  const isRoofColorEnabled = !isTextileRoof(state.roofType);

  const xWallSegmentCount = length / xWallSegmentWidth;
  const zWallSegmentCount = state.width / state.zWallSegmentWidth;

  Object.assign(state, {
    xWallSegmentWidth,
    xWallSegmentCount,
    zWallSegmentCount,
    length,
    roofWindowType,
    isRoofWindowEnabled,
    isRidgeLightBandEnabled,
    isRoofColorEnabled,
  });

  const objects = filterObjectsInRange(state);

  // re-add the objects with validation
  state.objects = [];
  objects.forEach((object) => {
    const { type, side, index, height } = object;
    if (isRangeObject(type)) {
      const maxIndex = isXSide(side) ? xWallSegmentCount - 1 : zWallSegmentCount - 1;
      // skip out-of-range objects
      if (object.indexFrom > maxIndex) {
        return;
      }
      // shrink to fit
      if (object.indexTo > maxIndex) {
        object.indexTo = maxIndex;
      }
    }
    if (canWallSegmentGetObjectType(state, type, side, index, height)) {
      state.objects.push(object);
    }
  });

  return state;
}

export default createWarehouseConfiguration;
