import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';

export function withLocalValue(Component) {
  function LocalValueHOC({ onChange, onBlur, value, ...props }) {
    const [localValue, setLocalValue] = useState(value);
    const changeHandler = useCallback(
      ({ target: { value } }) => {
        setLocalValue(value);
      },
      [setLocalValue]
    );
    const blurHandler = () => onBlur(props.name, localValue);
    useEffect(() => {
      setLocalValue(value);
    }, [value]);
    useEffect(() => {
      value !== localValue && onChange(props.name, localValue);
    }, [props.name, onChange, value, localValue]);
    return <Component {...props} onChange={changeHandler} onBlur={blurHandler} value={localValue} />;
  }
  LocalValueHOC.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    value: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
  };
  return LocalValueHOC;
}
