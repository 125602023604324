import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Error from '@material-ui/icons/Error';
import Check from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import CustomDialog from '../../../CustomDialog';
import MainButton from '../../../MainButton';
import { setVisible, setWorkInProgress } from '../../../../Reducers/Ui/Actions';
import {
  resetConfigurationCode,
  resetError,
  saveConfiguration,
} from '../../../../Reducers/Configuration/Actions';
import { ConfigurationContext } from '../../../../ContextProviders/ConfigurationProvider';
import { VisualizationContext } from '../../../../ContextProviders/VisualizationProvider';
import { validateFieldData } from '../../../../Utils/Validator';
import { useForm } from '../../../../Hooks/useForm';
import { withLocalValue } from '../../../withLocalValue';
import PrivacyPolicyControl from '../../PrivacyPolicyControl';

const StyledTextField = withStyles({
  root: {
    marginBottom: 8,
  },
})(withLocalValue(TextField));

const useStyles = makeStyles((theme) => ({
  dialogTitleRoot: {
    padding: '12px 12px 20px',
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  dialogContentRoot: {
    padding: '0 24px 24px 12px',
  },
  activeButton: {
    backgroundColor: '#ed1c29',
    padding: '6px 15px',
    '&:hover': {
      backgroundColor: '#ed1c29',
      opacity: 0.5,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  errorResult: {
    background: '#ED1C29',
    color: '#fff',
    padding: '14px 8px',
    borderRadius: '4px',
    fontSize: 14,
    lineHeight: 1.7,
    textAlign: 'center',
    '& p': { color: '#fff !important' },
  },
  successResult: {
    background: '#ed1c29',
    color: '#fff',
    padding: '14px 8px',
    borderRadius: '4px',
    fontSize: 14,
    lineHeight: 1.7,
    textAlign: 'center',
    '& p': { color: '#fff !important' },
  },
  errorResultIcon: {
    float: 'left',
  },
  successResultIcon: {
    float: 'left',
  },
  TypographyCaption: {
    fontSize: 12,
    lineHeight: 1.7,
  },
  fieldsWrapper: {
    marginTop: 24,
    marginBottom: 32,
  },
  dialogSavingInProgress: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    height: 50,
    marginTop: 24,
  },
}));

const formFields = {
  title: {
    type: 'text',
    autoFocus: true,
    value: '',
  },
  email: {
    type: 'email',
    required: true,
    value: '',
  },
  firstName: {
    type: 'text',
    value: '',
  },
  name: {
    type: 'text',
    value: '',
  },
  company: {
    type: 'text',
    value: '',
  },
};

const PdfDownload = (props) => {
  const { backgroundWorkMap, visibilityMap } = useSelector((state) => state.Ui);
  const { error } = useSelector((state) => state.Configuration);
  const customData = useContext(ConfigurationContext);
  const visualization = useContext(VisualizationContext);
  const [downloadReady, setDownloadReady] = useState(false);
  const [privacyPolicyApproved, setPrivacyPolicyApproved] = useState(false);

  const dispatch = useDispatch();

  const { formData, changeHandler, debouncedChangeHandler, validate, reset, getValues } = useForm(
    formFields,
    {
      validatorFn: validateFieldData,
    }
  );

  const show = !!visibilityMap.pdfDownload;
  const workInProgress = !!backgroundWorkMap.saveConfiguration;

  const classes = useStyles(props);
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      reset();
      setDownloadReady(false);
      dispatch(resetError());
      setPrivacyPolicyApproved(false);
    }
  }, [reset, show, dispatch]);

  const onDownloadClick = async () => {
    if (!validate()) {
      return;
    }
    await dispatch(setWorkInProgress('saveConfiguration', true));
    const screenshots = (await visualization.getScreenshots()) || {};
    const data = await customData.getData();
    await dispatch(saveConfiguration(data, screenshots, 'print', getValues()));
    setDownloadReady(true);
  };

  const onChange = (...args) => {
    if (error) {
      dispatch(resetError());
    }
    debouncedChangeHandler(...args);
  };

  const onClose = () => {
    dispatch(setVisible('pdfDownload', false));
    // we need to reset the code so a new one is created on next save
    dispatch(resetConfigurationCode());
  };

  return (
    <CustomDialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      dialogtitle={t(
        downloadReady
          ? 'pdfDialog.dialogTitleSuccess'
          : workInProgress
          ? 'pdfDialog.generationInProgress'
          : 'pdfDialog.dialogTitle'
      )}
      onCancel={onClose}
    >
      <DialogContent className={classes.dialogContentRoot}>
        {workInProgress && (
          <div className={classes.dialogSavingInProgress}>
            <CircularProgress />
          </div>
        )}

        {!workInProgress && !downloadReady && (
          <React.Fragment>
            <Typography
              variant="body1"
              classes={{ root: classes.TypographyCaption }}
              id="alert-dialog-description"
            >
              {t('pdfDialog.pdfTitleDescription')}
            </Typography>
            <div className={classes.fieldsWrapper}>
              {Object.keys(formData).map((name) => (
                <StyledTextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  key={name}
                  name={name}
                  {...formData[name]}
                  label={t(`pdfDialog.fields.${name}`)}
                  helperText={formData[name].error && t(`validationErrorTexts.${formData[name].helperText}`)}
                  onChange={onChange}
                  onBlur={changeHandler}
                />
              ))}
              <PrivacyPolicyControl onChange={setPrivacyPolicyApproved} checked={privacyPolicyApproved} />
            </div>
          </React.Fragment>
        )}
        {downloadReady && !error && (
          <div className={classes.successResult}>
            <Check className={classes.successResultIcon} />
            <Typography variant="body1">{t('pdfDialog.pdfSuccess')}</Typography>
          </div>
        )}
        {error && (
          <div className={classes.errorResult}>
            <Error className={classes.errorResultIcon} />
            <Typography variant="body1">{t('pdfDialog.saveError')}</Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {downloadReady && (
          <MainButton onClick={onClose} variant="contained" size="medium" className={classes.activeButton}>
            {t('pdfDialog.okButtonText')}
          </MainButton>
        )}
        {!workInProgress && !downloadReady && (
          <MainButton
            onClick={() => onDownloadClick()}
            variant="contained"
            className={classes.activeButton}
            size="medium"
            disabled={!privacyPolicyApproved}
          >
            {t('pdfDialog.downloadNow')}
          </MainButton>
        )}
      </DialogActions>
    </CustomDialog>
  );
};
PdfDownload.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
  show: PropTypes.bool,
};
export default PdfDownload;
