import { combineReducers } from 'redux';
import Configuration from './Configuration';
import Option from './Option';
import Offer from './Offer';
import Sample from './Sample';
import Ui from './Ui';

export default combineReducers({
  Configuration,
  Option,
  Offer,
  Sample,
  Ui,
});
