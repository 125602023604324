import { useCallback, useEffect, useState } from 'react';

const useLocalStorage = (name, initialValue) => {
  const getValue = useCallback(() => {
    const str = window.localStorage.getItem(name);
    if (!str) {
      return initialValue;
    }
    try {
      return JSON.parse(str);
    } catch (e) {
      return initialValue;
    }
  }, [name, initialValue]);
  const [value, setValue] = useState(getValue());
  useEffect(() => {
    if (value !== getValue()) {
      window.localStorage.setItem(name, value);
    }
  }, [name, getValue, value]);
  return [value, setValue];
};

export default useLocalStorage;
