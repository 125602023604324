import { initLocalOptionsWithDefaultOption } from '../Selectors/Option';

export const wallTypes = initLocalOptionsWithDefaultOption([
  {
    identifier: 'trapezoidal_sheet',
    externalIdentifier: 'wall_trapezoidal',
    variant: 'trapezoidal_sheet',
    title: 'Trapezblech',
  },
  {
    identifier: 'sandwich_40',
    externalIdentifier: 'wall_sandwich_40',
    variant: 'sandwich',
    thickness: 40,
    title: 'Sandwich 40 mm',
  },
  {
    identifier: 'sandwich_60',
    externalIdentifier: 'wall_sandwich_60',
    variant: 'sandwich',
    thickness: 60,
    title: 'Sandwich 60 mm',
  },
  {
    identifier: 'sandwich_80',
    externalIdentifier: 'wall_sandwich_80',
    variant: 'sandwich',
    thickness: 80,
    title: 'Sandwich 80 mm',
  },
  {
    identifier: 'sandwich_100',
    externalIdentifier: 'wall_sandwich_100',
    variant: 'sandwich',
    thickness: 100,
    title: 'Sandwich 100 mm',
  },
]);

export const defaultWallType = wallTypes[0];
