import { RECEIVE_REQUEST_OFFER, RESET_REQUEST_OFFER, REQUEST_ERROR, RESET_ERROR } from './Actions';

const initialState = {
  requestOfferReceived: false,
  error: null,
};

export default function ConfigurationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_REQUEST_OFFER: {
      return {
        ...state,
        requestOfferReceived: true,
        error: null,
      };
    }

    case RESET_REQUEST_OFFER: {
      return {
        ...state,
        requestOfferReceived: false,
        error: null,
      };
    }

    case REQUEST_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
}
