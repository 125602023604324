import React, { useContext } from 'react';
import { createLoaderStateManager, LoaderStateContext } from './ContextProviders/LoaderStateProvider';
import { Loader } from './Loader';

export const globalLoaderStateManager = createLoaderStateManager();

export const GlobalLoader = () => {
  const isLoading = useContext(LoaderStateContext);
  return <Loader loading={isLoading} />;
};
