import React from 'react';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { useDispatch, useSelector } from 'react-redux';
import { resetConfigurationCode, resetError } from '../../../../Reducers/Configuration/Actions';
import { withStyles } from '@material-ui/core/styles';
import { setVisible } from '../../../../Reducers/Ui/Actions';

const PdfButton = withStyles({
  root: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 8px',
  },
  icon: {
    display: 'inline-flex',
    color: '#4F4F4F',
  },
  label: {
    fontSize: '0.9em',
  },
})(({ classes }) => {
  const { error } = useSelector((state) => state.Ui);
  const dispatch = useDispatch();

  const handlePdf = async () => {
    if (error) {
      dispatch(resetError());
    }
    await dispatch(resetConfigurationCode());
    await dispatch(setVisible('pdfDownload', true));
  };

  return (
    <div className={classes.root} onClick={handlePdf}>
      <PictureAsPdf className={classes.icon} />
    </div>
  );
});

export default PdfButton;
