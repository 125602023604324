import React, { createContext, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';

export const ActivePopupContext = createContext(null);

export const ActivePopupProvider = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [activePopup, setActivePopup] = useState(null);

  const value = useMemo(
    () => ({
      useActive: (name, close, open) => {
        useEffect(() => {
          setActivePopup((activePopup) => {
            open && activePopup && activePopup.name !== name && activePopup.close();
            return open ? { name, close } : activePopup;
          });
        }, [name, close, open]);
      },
    }),
    []
  );

  return <ActivePopupContext.Provider value={value}>{children}</ActivePopupContext.Provider>;
};

ActivePopupProvider.propTypes = {
  children: PropTypes.node,
};

export default ActivePopupProvider;
