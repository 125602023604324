import { createRequest } from '../../Api';

export const REQUEST_ERROR = 'CONFIGURATION_REQUEST_ERROR';
export const SEND_REQUEST_OFFER = 'OFFER_SEND_REQUEST_OFFER';
export const RECEIVE_REQUEST_OFFER = 'OFFER_RECEIVE_REQUEST_OFFER';
export const RESET_REQUEST_OFFER = 'OFFER_RESET_REQUEST_OFFER';
export const RESET_ERROR = 'OFFER_RESET_ERROR';

export const createRequestError = (e) => ({
  type: REQUEST_ERROR,
  error: e,
});

export const receiveRequestOffer = (response) => ({
  type: RECEIVE_REQUEST_OFFER,
  response,
});

export const resetRequestOffer = () => ({
  type: RESET_REQUEST_OFFER,
});

export const resetError = () => ({
  type: RESET_ERROR,
});

export const sendRequestOffer = (configurationCode, formData) => async (dispatch) => {
  const { request } = createRequest();
  try {
    const response = await request({
      method: 'post',
      url: `/frontendapi/configurations/${configurationCode}/offer`,
      data: {
        name: formData.surname.value,
        firstName: formData.name.value,
        company: formData.company.value,
        phoneNumber: formData.tel.value,
        email: formData.email.value,
        intendedLocation: formData.address.value,
        comment: formData.message.value,
      },
    });
    dispatch(receiveRequestOffer(response));
  } catch (e) {
    dispatch(createRequestError(e));
  }
};
