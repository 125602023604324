import { WALL_LIGHTBAND } from '../../Constants/ObjectTypes';

export const RangeObjects = [WALL_LIGHTBAND];

export function isRangeObject(type) {
  return RangeObjects.indexOf(type) !== -1;
}

/**
 * Finds the left and right range objects next to the targetObject (if there is any)
 * @param {array} objects
 * @param {object} targetObject
 * @return {{left: *, right: *}}
 */
function findNearRangeObjects(objects, targetObject) {
  const { type, side, index } = targetObject;
  const leftIndex = index - 1;
  const rightIndex = index + 1;
  const matches = objects.filter((obj) => obj.side === side && obj.type === type);
  const left = matches.find((obj) => obj.indexTo === leftIndex);
  const right = matches.find((obj) => obj.indexFrom === rightIndex);
  return { left, right };
}

/**
 * Combines consecutive range objects into one single object (the targetObject)
 * by extending the range in both directions (if possible)
 * @param {array} objects
 * @param {object} targetObject
 */
export function combineRangeObjects(objects, targetObject) {
  const { left, right } = findNearRangeObjects(objects, targetObject);
  if (left && right) {
    left.indexTo = right.indexTo;
    const rightIndex = objects.indexOf(right);
    objects.splice(rightIndex, 1);
  } else if (left) {
    left.indexTo = targetObject.index;
  } else if (right) {
    right.indexFrom = targetObject.index;
  } else {
    objects.push({
      ...targetObject,
      indexFrom: targetObject.index,
      indexTo: targetObject.index,
    });
  }
}

export function removeFromRange(objects, rangeObject, rangeIndex) {
  const index = rangeObject.indexFrom + rangeIndex;
  if (rangeObject.indexFrom === rangeObject.indexTo) {
    objects.splice(objects.indexOf(rangeObject), 1);
  } else if (rangeObject.indexFrom === index) {
    rangeObject.indexFrom = index + 1;
  } else if (rangeObject.indexTo === index) {
    rangeObject.indexTo = index - 1;
  } else {
    objects.push({
      ...rangeObject,
      index,
      indexFrom: index + 1,
    });
    rangeObject.indexTo = index - 1;
  }
}

export function getRangeObjectSize(targetObject) {
  const { indexFrom: from, indexTo: to } = targetObject;
  return {
    from,
    to,
    size: to - from + 1,
  };
}
