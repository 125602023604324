import withStyles from '@material-ui/core/styles/withStyles';
import MuiSlider from '@material-ui/core/Slider';

export const Slider = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '&:after': {
      display: 'none',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    display: 'none',
  },
  markLabel: {
    display: 'none',
  },
}))(MuiSlider);

export default Slider;
