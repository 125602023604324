import React, { useContext, useState } from 'react';
import { VisualizationContext } from '../../../../ContextProviders/VisualizationProvider';
import { ToolbarButton } from '../../ToolbarButton';

import panInactiveIconSrc from './icons/pan_inactive.svg';
import panActiveIconSrc from './icons/pan_active.svg';

const PanButton = () => {
  const visualization = useContext(VisualizationContext);
  const [panningActive, setPanningActive] = useState(false);
  const togglePanning = () => {
    const active = !panningActive;
    setPanningActive(active);
    visualization.controls.togglePanning(active);
  };
  return (
    <ToolbarButton
      iconSrc={panInactiveIconSrc}
      activeIconSrc={panActiveIconSrc}
      onClick={togglePanning}
      active={panningActive}
    />
  );
};

export default PanButton;
