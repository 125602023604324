import React, { useContext } from 'react';
import { ConfigurationContext } from '../../../ContextProviders/ConfigurationProvider';
import Typography from '@material-ui/core/Typography';
import useConfigurationChanges from '../../../Hooks/useConfigurationChanges';
import Slider from '../../Slider';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import GroupLabel from '../../GroupLabel';

const Dimensions = () => {
  const configuration = useContext(ConfigurationContext);
  const { t } = useTranslation();
  const { width, length, height, xWallSegmentWidth } = useConfigurationChanges(configuration);

  const lengthDefault = xWallSegmentWidth === 4 ? 12 : 15;
  const lengthMin = width;

  return (
    <>
      <Grid container spacing={0} justify="space-between">
        <Grid item>
          <GroupLabel id="width" gutterBottom>
            {t('dimensions.width')}
          </GroupLabel>
        </Grid>
        <Grid item>
          <Typography gutterBottom>{width} m</Typography>
        </Grid>
      </Grid>
      <Slider
        onChange={(evt, value) => configuration.setWidth(value)}
        defaultValue={15}
        aria-labelledby="width"
        valueLabelDisplay="off"
        step={5}
        min={10}
        max={30}
        value={width}
      />
      <Grid container spacing={0} justify="space-between">
        <Grid item>
          <GroupLabel id="length" gutterBottom>
            {t('dimensions.length')}
          </GroupLabel>
        </Grid>
        <Grid item>
          <Typography gutterBottom>{length} m</Typography>
        </Grid>
      </Grid>
      <Slider
        onChange={(evt, value) => configuration.setLength(value)}
        defaultValue={lengthDefault}
        aria-labelledby="length"
        valueLabelDisplay="off"
        step={xWallSegmentWidth}
        min={lengthMin}
        max={200}
        value={length}
      />

      <Grid container spacing={0} justify="space-between">
        <Grid item>
          <GroupLabel id="height" gutterBottom>
            {t('dimensions.height')}
          </GroupLabel>
        </Grid>
        <Grid item>
          <Typography gutterBottom>{height} m</Typography>
        </Grid>
      </Grid>
      <Slider
        onChange={(evt, value) => configuration.setHeight(value)}
        defaultValue={5.2}
        aria-labelledby="height"
        valueLabelDisplay="off"
        step={null}
        marks={[4.2, 5.2, 6.2].map((v) => ({ value: v, label: v }))}
        max={6.2}
        min={4.2}
        value={height}
      />
    </>
  );
};

export default Dimensions;
