import { NO_ROOF_WINDOW } from '../../Constants/RoofWindowTypes';
import { initLocalOptionsWithDefaultOption } from '../Selectors/Option';

export const roofWindowTypes = initLocalOptionsWithDefaultOption([
  {
    value: 'dome_window',
    title: 'Lichtkuppel',
    identifier: 'dome_window',
    externalIdentifier: 'domelight',
  },
  {
    value: 'ridge_light_band',
    title: 'Firstlichtband',
    identifier: 'ridge_light_band',
    externalIdentifier: 'ridge_lightband',
  },
]);

export const defaultRoofWindowType = NO_ROOF_WINDOW;

export { NO_ROOF_WINDOW };

export function isRidgeLightBand(value) {
  return value === 'ridge_light_band';
}
