import get from 'lodash/get';
import { createRequest } from '../../Api';

export const REQUEST_ERROR = 'OPTION_REQUEST_ERROR';
export const RECEIVE_OPTIONS = 'OPTION_RECEIVE_OPTIONS';

export const createRequestError = (e) => ({
  type: REQUEST_ERROR,
  error: e,
});

export const receiveOptions = (key, options) => ({
  type: RECEIVE_OPTIONS,
  key,
  options,
});

export const loadOptions = (key, component, configuration) => async (dispatch) => {
  const { request } = createRequest();
  try {
    const response = await request({
      method: 'post',
      url: `frontendapi/options/list/warehouse/${component}?_channel=_default`,
      data: {
        configuration,
        configurationtype: 'user',
      },
    });
    const options = get(response, 'data', []);
    dispatch(receiveOptions(key, options));
  } catch (e) {
    dispatch(createRequestError(e));
  }
};
