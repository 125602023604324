import AddCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';
import { DOOR, GATE, WALL, WALL_LIGHTBAND, WINDOW } from '../../Constants/ObjectTypes';
import { CLONE, REMOVE } from '../../Constants/ItemActions';
import find from 'lodash/find';
import { getOptionAttributeValue, getOptionsByComponentIdentifier } from '../Selectors/Option';

const editableItems = [DOOR, GATE, WINDOW, WALL_LIGHTBAND];

export function isEditableItem(type) {
  return editableItems.indexOf(type) !== -1;
}

export function getMenuItems(targetObject, componentOptions, windowTitle, gateTitle) {
  const { type } = targetObject.userData;
  const wallElements = getOptionsByComponentIdentifier(componentOptions, 'wall_elements');
  const wallItems = [
    {
      identifier: 'gate',
      title: gateTitle,
      type: GATE,
      children: [],
    },
    {
      identifier: 'window',
      title: windowTitle,
      type: WINDOW,
      children: [],
    },
  ];
  wallElements.map((element) => {
    const type = getOptionAttributeValue(element, 'type', '');
    const gates = find(wallItems, { identifier: 'gate' });
    const windows = find(wallItems, { identifier: 'window' });
    switch (type) {
      case DOOR:
        wallItems.push(element);
        break;
      case WALL_LIGHTBAND:
        wallItems.push(element);
        break;
      case GATE:
        gates.children.push(element);
        break;
      case WINDOW:
        windows.children.push(element);
        break;
      default:
        wallItems.push(element);
    }
  });
  if (type === WALL) {
    return wallItems;
  } else if (isEditableItem(type)) {
    return editActions;
  }
}

const editActions = [
  {
    action: REMOVE,
    IconComponent: Delete,
  },
  {
    action: CLONE,
    IconComponent: AddCircle,
  },
];
