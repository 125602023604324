import { Box3, Vector3 } from 'three';

export function getContainerBox(root) {
  const box = new Box3();
  box.expandByObject(root);
  return box;
}

export function getObjectSize(object) {
  const size = new Vector3();
  getContainerBox(object).getSize(size);
  return size;
}

export function getGeometrySize(object) {
  const size = new Vector3();
  object.geometry.computeBoundingBox();
  object.geometry.boundingBox.getSize(size);
  return size;
}
