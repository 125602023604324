import PropTypes from 'prop-types';
import React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import withStyles from '@material-ui/core/styles/withStyles';
import GroupLabel from './GroupLabel';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';

const Accordion = withStyles(
  (theme) => ({
    root: {
      margin: '0 -24px',
      padding: '0 24px',
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: '0 -24px',
      },
      '&:last-child': {
        borderBottom: 'none',
      },
      '&$disabled': {
        backgroundColor: 'inherit',
      },
    },
    expanded: {},
    disabled: {},
  }),
  { name: 'MenuAccordion' }
)(MuiAccordion);

const AccordionSummary = withStyles(
  {
    root: {
      padding: 0,
      '&$expanded': {
        margin: 'auto',
        minHeight: 48,
      },
    },
    content: {
      '&$expanded': {
        margin: 0,
      },
    },
    expanded: {},
  },
  { name: 'MenuAccordionSummary' }
)(MuiAccordionSummary);

const AccordionDetails = withStyles(
  {
    root: {
      padding: '0 0 12px 0',
      display: 'block',
    },
  },
  { name: 'MenuAccordionDetails' }
)(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
  icon: {
    width: 35,
    height: 30,
    display: 'inline-flex',
    marginLeft: 'auto',
    color: '#ED1C29',
    opacity: '1 !important',
  },
  summaryDisabled: {
    justifyContent: 'space-between',
  },
}));

export const MenuAccordion = ({ title, expanded, onChange, disabled, children }) => {
  const classes = useStyles();
  return (
    <Accordion square expanded={expanded} onChange={onChange} disabled={disabled}>
      <AccordionSummary className={disabled ? classes.summaryDisabled : ''}>
        <GroupLabel>{title}</GroupLabel>
        {disabled && <ErrorOutline className={classes.icon} />}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

MenuAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default MenuAccordion;
