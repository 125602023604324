import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { VisualizationContext } from '../../../../ContextProviders/VisualizationProvider';
import { ConfigurationContext } from '../../../../ContextProviders/ConfigurationProvider';
import MenuTitleBar from '../../../MenuTitleBar';
import { ToolbarButton } from '../../ToolbarButton';
import commentInactiveIconSrc from './icons/comment_inactive.svg';
import commentActiveIconSrc from './icons/comment_active.svg';
import commentCursorIconSrc from './icons/comment_cursor.svg';
import createConfigurationNotes from './createConfigurationNotes';
import { createNoteControls, createNotesVisualization } from './notesVisualization';

const useStyles = makeStyles({
  '@global': {
    '#visualizationContainer': ({ active }) => {
      return active
        ? {
            cursor: `url(${commentCursorIconSrc}) 20 20, copy`,
          }
        : {};
    },
  },
  actions: {
    padding: 24,
  },
});

const MultilineTypography = ({ children, ...props }) =>
  typeof children === 'string'
    ? children.split(/\r?\n/).map((line, key) => (
        <Typography component="div" key={key} {...props}>
          {line || <br />}
        </Typography>
      ))
    : null;

const Notes = () => {
  const visualization = useContext(VisualizationContext);
  const configuration = useContext(ConfigurationContext);

  useEffect(() => {
    const { dispose, syncNotes } = createNotesVisualization(visualization, configuration);
    syncNotes();
    return dispose;
  }, [visualization, configuration]);

  const [active, setActive] = useState(false);
  const [note, setNote] = useState({});
  const [editorState, setEditorState] = useState({ open: false, mode: '' });
  const { open, mode } = editorState;

  const configurationNotes = useMemo(() => createConfigurationNotes(configuration), [configuration]);

  const handleClose = useCallback(
    () => setEditorState((editorState) => ({ ...editorState, open: false })),
    []
  );
  useEffect(() => {
    return createNoteControls(visualization, ({ position, noteId }) => {
      if (noteId) {
        setNote(configurationNotes.findNoteById(noteId));
      } else {
        setNote({ position, noteId, text: '' });
      }
      if (noteId) {
        setEditorState({ open: true, mode: 'view' });
        return false; // prevent default object selection
      } else if (active) {
        setEditorState({ open: true, mode: 'edit' });
        return false; // prevent default object selection
      }
    });
  }, [active, visualization, configurationNotes]);
  const addNote = () => {
    configurationNotes.saveNote(note);
    setNote({});
    handleClose();
  };
  const deleteNote = () => {
    configurationNotes.deleteNoteById(note.noteId);
    setNote({});
    handleClose();
  };
  const changeHandler = useCallback(
    ({ target: { value } }) => setNote((note) => ({ ...note, text: value })),
    []
  );

  const { t } = useTranslation();
  const classes = useStyles({ active });

  return (
    <>
      <ToolbarButton
        iconSrc={commentInactiveIconSrc}
        activeIconSrc={commentActiveIconSrc}
        onClick={() => setActive(!active)}
        active={active}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <MenuTitleBar
          title={t('notes.dialogTitle') + (note.noteId ? ` #${note.noteId}` : '')}
          onClose={handleClose}
        />
        <DialogContent>
          {mode === 'view' && <MultilineTypography>{note.text}</MultilineTypography>}
          {mode === 'edit' && (
            <TextField
              variant="outlined"
              margin="dense"
              value={note.text}
              onChange={changeHandler}
              multiline
              rows={3}
              rowsMax={6}
              autoFocus
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          {note.noteId && (
            <Button onClick={deleteNote} disableElevation color="default" variant="contained">
              {t('notes.deleteButton')}
            </Button>
          )}
          {mode === 'edit' && (
            <Button onClick={addNote} disableElevation color="primary" variant="contained">
              {t('notes.confirmButton')}
            </Button>
          )}
          {mode === 'view' && (
            <Button
              onClick={() => setEditorState({ ...editorState, mode: 'edit' })}
              disableElevation
              color="primary"
              variant="contained"
            >
              {t('notes.editButton')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Notes;
