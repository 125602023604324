import isEmpty from 'lodash/isEmpty';
import { RECEIVE_SAMPLES, REQUEST_ERROR } from './Actions';

const initialState = {
  samples: [{ configurationCode: null, description: '', id: null, imageUrl: '', title: '' }],
  error: null,
};

export default function SampleReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_SAMPLES: {
      const { samples } = action;

      // workaround needed for now because API sends urls strangely formatted
      if (!isEmpty(samples)) {
        samples.map((sample) => {
          sample.imageUrl = `http:${sample.imageUrl}`;
        });
      }
      return {
        ...state,
        samples,
      };
    }
    case REQUEST_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }

    default:
      return state;
  }
}
