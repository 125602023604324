import React from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useDispatch, useSelector } from 'react-redux';
import { resetConfigurationCode, resetError } from '../../../../Reducers/Configuration/Actions';
import { withStyles } from '@material-ui/core/styles';
import { setVisible } from '../../../../Reducers/Ui/Actions';

const SaveButton = withStyles({
  root: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 8px',
  },
  icon: {
    display: 'inline-flex',
    color: '#4F4F4F',
  },
  label: {
    fontSize: '0.9em',
  },
})(({ classes }) => {
  const { error } = useSelector((state) => state.Configuration);
  const dispatch = useDispatch();

  const handleSave = async () => {
    if (error) {
      dispatch(resetError());
    }
    await dispatch(resetConfigurationCode());
    dispatch(setVisible('saveConfiguration', true));
  };
  return (
    <div className={classes.root} onClick={handleSave}>
      <CloudDownloadIcon className={classes.icon} />
    </div>
  );
});

export default SaveButton;
