import {
  ACESFilmicToneMapping,
  AxesHelper,
  Color,
  DirectionalLight,
  HemisphereLight,
  PCFSoftShadowMap,
  PerspectiveCamera,
  PMREMGenerator,
  Scene,
  Sphere,
  sRGBEncoding,
  UnsignedByteType,
  WebGLRenderer,
} from 'three';
import debounce from 'lodash/debounce';
import TWEEN from '@tweenjs/tween.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { createPointerEventListener, createPointerHitListener } from '../Utils/pointerEvents';
import { BACK, FRONT, FRONT_LEFT, FRONT_RIGHT, LEFT, RIGHT, TOP } from '../Constants/CameraViews';
import Observable from '../Utils/Observable';
import { getContainerBox } from './utils/objectSize';
import { findClosestEditable, getIntersection } from './utils/getIntersection';
import { EventActions } from '../App/createWarehouseConfiguration';
import { InteractionStarted, ItemSelected, PointerIntersection, VisualizationUpdated } from './EventTypes';
import {
  createEnvironmentLoader,
  createFrontMarker,
  createModelPartsLoader,
  createWarehouse,
} from './warehouseBuilder';
import { fitSceneToCamera, setupDefaultControls, setupFixedViews, setupPanControls } from './cameraControls';
import makeScreenshots from './makeScreenshots';
import { globalLoaderStateManager } from '../App/GlobalLoader';
import isDevMode from '../Utils/isDevMode';
import disposeObject from './utils/disposeObject';

const highlightEmissiveColor = new Color(0, 0.2, 0);

function createDefaultRenderer() {
  const renderer = new WebGLRenderer({
    alpha: true,
    antialias: true,
  });
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = PCFSoftShadowMap;
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = sRGBEncoding;
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.75;
  return renderer;
}

function createDefaultCamera() {
  const camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.01);
  camera.position.set(0.5, 1, 1).multiplyScalar(30);
  camera.lookAt(0, 0, 0);
  return camera;
}

function createEnvironmentLights() {
  const hemiLight = new HemisphereLight(0xffffff, 0xffffff, 2);
  hemiLight.name = 'hemiLight';
  return [hemiLight];
}

function setLightShadow(light, d = 50) {
  // directional light has orthographic camera (due to the parallel ray direction)
  if (light.isDirectionalLight) {
    light.castShadow = true;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.near = 0.0001;
    light.shadow.camera.far = 10000;
    light.shadow.camera.updateProjectionMatrix();
  }
}

// eslint-disable-next-line no-unused-vars
function createDirLights() {
  const light1 = new DirectionalLight(0xffffff, 0.5);
  light1.position.set(1, 0.1, 1);
  const light2 = new DirectionalLight(0xffffff, 0.5);
  light2.position.set(-1, 0.1, -1);
  const light3 = new DirectionalLight(0xffffff, 1);
  return [light1, light2, light3];
}

// eslint-disable-next-line no-unused-vars
function setShadows(root, dirLight) {
  root.traverse((object) => {
    if (object.isMesh) {
      object.castShadow = true;
    }
  });
  if (dirLight) {
    const box = getContainerBox(root);
    const sphere = new Sphere();
    box.getBoundingSphere(sphere);
    setLightShadow(dirLight, sphere.radius);
  }
}

function createScene() {
  const scene = new Scene();
  scene.add(...createEnvironmentLights());
  scene.add(...createDirLights());
  return scene;
}

function setTemporaryEmissiveColor(object, color) {
  if (object.material) {
    if (!object.originalEmissiveColor) {
      object.originalEmissiveColor = object.material.emissive;
    }
    object.material.emissive = color;
  }
  for (const child of object.children) {
    setTemporaryEmissiveColor(child, color);
  }
}

function restoreOriginalEmissiveColor(object) {
  if (object.material) {
    object.material.emissive = object.originalEmissiveColor;
  }
  for (const child of object.children) {
    restoreOriginalEmissiveColor(child);
  }
}

function loadEnvMap(renderer, scene) {
  return new Promise((resolve, reject) => {
    new RGBELoader()
      .setDataType(UnsignedByteType)
      .setPath('3d/textures/equirectangular/')
      .load(
        'driving_school_2k.hdr',
        function (texture) {
          const pmremGenerator = new PMREMGenerator(renderer);
          pmremGenerator.compileEquirectangularShader();

          const envMap = pmremGenerator.fromEquirectangular(texture).texture;

          scene.background = envMap;
          scene.environment = envMap; // todo: play around with the intensity

          texture.dispose();
          pmremGenerator.dispose();
          resolve();
        },
        undefined,
        reject
      );
  });
}

export function createVisualization(warehouseConfiguration, options = {}) {
  const observable = new Observable();
  const { on, off, dispatch } = observable;

  const { createRenderer = createDefaultRenderer } = options;

  const renderer = createRenderer();
  const camera = createDefaultCamera();
  const scene = createScene();

  let isAnimating = false;
  let animationFrame = null;

  function updateCamera(width, height) {
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  }

  function setSize({ width, height }) {
    renderer.setSize(width, height);
    updateCamera(width, height);
    renderFrame();
  }

  function render() {
    renderer.render(scene, camera);
  }

  function compile() {
    renderer.compile(scene, camera);
  }

  function renderFrame() {
    if (!isAnimating) {
      window.cancelAnimationFrame(animationFrame);
      animationFrame = window.requestAnimationFrame(render);
    }
  }

  const controls = setupDefaultControls(camera, renderer.domElement, renderFrame);
  setupPanControls(controls, renderer.domElement, scene);
  setupFixedViews(controls, scene, { startAnimation, stopAnimation, render });

  function animate(time) {
    animationFrame = window.requestAnimationFrame(animate);
    if (time) {
      TWEEN.update();
      controls && controls.update();
      render();
    }
  }

  function startAnimation() {
    isAnimating = true;
    animate();
  }

  function stopAnimation() {
    window.cancelAnimationFrame(animationFrame);
    TWEEN.removeAll();
    isAnimating = false;
  }

  let lastHoveredObject;
  createPointerEventListener(renderer.domElement, 'move', (evt) => {
    if (evt.target !== renderer.domElement || !controls.enabled) {
      return;
    }
    const intersect = getIntersection(evt, renderer, camera, scene);
    const object = intersect && findClosestEditable(intersect.object);
    if (object !== lastHoveredObject) {
      if (lastHoveredObject) {
        restoreOriginalEmissiveColor(lastHoveredObject);
      }
      if (object) {
        setTemporaryEmissiveColor(object, highlightEmissiveColor);
        lastHoveredObject = object;
      } else {
        lastHoveredObject = null;
      }
      renderFrame();
    }
  });

  createPointerHitListener(renderer.domElement, (evt) => {
    if (dispatch(PointerIntersection, { event: evt }).some((res) => res === false)) {
      isDevMode() && console.log('DEFAULT SELECT PREVENTED');
      return;
    }

    const intersect = getIntersection(evt, renderer, camera, scene);
    isDevMode() && console.log('OBJECT HIT', intersect && intersect.object);

    const object = intersect && findClosestEditable(intersect.object);
    object && dispatch(ItemSelected, { object, event: evt });
  });

  createPointerEventListener(renderer.domElement, 'start', (evt) => {
    dispatch(InteractionStarted, { event: evt });
  });

  const environmentLoader = createEnvironmentLoader({
    cleanUpBeforeLoad(environment) {
      scene.remove(environment);
      disposeObject(environment);
      compile();
    },
  });

  let warehouse, environment, isInitialized;
  const init = globalLoaderStateManager.wrap(async function init() {
    const envMapPromise = loadEnvMap(renderer, scene);

    const loader = createModelPartsLoader({ envMapIntensity: 0.2 });
    await Promise.all([loader.loadAll()]);

    const warehouseData = warehouseConfiguration.getData();

    warehouse = createWarehouse(warehouseData);
    scene.add(warehouse);

    if (isDevMode()) {
      scene.add(createFrontMarker());

      const axesHelper = new AxesHelper(5);
      axesHelper.position.set(-25, 1, -25);
      scene.add(axesHelper);
    }

    environment = await environmentLoader.load(warehouseData);
    environment && scene.add(environment);

    await envMapPromise;
    fitSceneToCamera(warehouse, controls);

    render();
    controls.fixedViews.moveTo(FRONT_LEFT, true);

    isInitialized = true;
  });

  init();

  const updateVisualization = globalLoaderStateManager.wrap(async function updateVisualization(
    warehouseConfiguration
  ) {
    const oldEnvironment = environment;
    environment = await environmentLoader.load(warehouseConfiguration);
    if (environment && oldEnvironment !== environment) {
      scene.add(environment);
    }

    scene.remove(warehouse);
    warehouse = createWarehouse(warehouseConfiguration);
    scene.add(warehouse);

    dispatch(VisualizationUpdated, { warehouseConfiguration });

    compile();
    render();

    fitSceneToCamera(warehouse, controls, { startAnimation, stopAnimation });
  });

  const updateVisualizationDebounced = debounce(updateVisualization, 50);

  warehouseConfiguration.onChange(({ data: { state, action } }) => {
    if (!isInitialized) {
      return;
    }
    if (action === EventActions.cloneObject) {
      // in case of cloning direct update needed
      updateVisualization(state);
    } else {
      updateVisualizationDebounced(state);
    }
  });

  function getScreenshots() {
    const { measurements } = visualization;
    const measurementsActive = measurements && measurements.isActive();

    const screenshots = makeScreenshots(visualization, [
      {
        name: FRONT_RIGHT,
        aspectRatio: 4 / 3,
        notes: true,
      },
      {
        name: FRONT_LEFT,
        aspectRatio: 4 / 3,
        notes: true,
      },
      { name: LEFT, aspectRatio: 16 / 9, measurements: true },
      { name: RIGHT, aspectRatio: 16 / 9, measurements: true },
      { name: FRONT, aspectRatio: 16 / 9, measurements: true },
      { name: BACK, aspectRatio: 16 / 9, measurements: true },
      { name: TOP, aspectRatio: 16 / 9 },
    ]);

    measurements && measurements.toggle(measurementsActive);

    return screenshots;
  }

  const visualization = {
    renderer,
    camera,
    scene,
    controls,
    compile,
    render,
    renderFrame,
    setSize,
    startAnimation,
    on,
    off,
    observable,
    getScreenshots,
  };

  return visualization;
}
