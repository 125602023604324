import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      opacity: 0.5,
      cursor: 'pointer',
    },
    '@media (hover: none)': {
      '&:hover': {
        opacity: 1,
      },
    },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: 'white',
    fontSize: 15,
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
}));

const MainButton = React.forwardRef((props, ref) => {
  const classes = useStyles(props);

  return <Button classes={classes} {...props} ref={ref} />;
});

MainButton.propTypes = {
  classes: PropTypes.object,
};
MainButton.displayName = 'MainButton';

export default MainButton;
