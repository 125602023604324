export function findObjectByWarehouseObjectRef(root, warehouseObjectRef) {
  if (root.warehouseObjectRef === warehouseObjectRef) {
    return root;
  }
  if (root.children) {
    for (const child of root.children) {
      const object = findObjectByWarehouseObjectRef(child, warehouseObjectRef);
      if (object) {
        return object;
      }
    }
  }
  return null;
}

export default findObjectByWarehouseObjectRef;
