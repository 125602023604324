import get from 'lodash/get';
import { createRequest } from '../../Api';
import { setVisible, setWorkInProgress } from '../Ui/Actions';

export const RECEIVE_CONFIGURATION = 'CONFIGURATION_RECEIVE_CONFIGURATION';
export const RECEIVE_SAVE_CONFIGURATION = 'CONFIGURATION_RECEIVE_SAVE_CONFIGURATION';
export const REQUEST_ERROR = 'CONFIGURATION_REQUEST_ERROR';
export const RESET_CODE = 'CONFIGURATION_RESET_CODE';
export const RESET_ERROR = 'CONFIGURATION_RESET_ERROR';
export const SET_LOAD_CODE = 'CONFIGURATION_SET_LOAD_CODE';
export const CONFIGURATION_UPDATED = 'CONFIGURATION_UPDATED';

export const createRequestError = (e) => ({
  type: REQUEST_ERROR,
  error: e,
});

export const resetError = () => ({
  type: RESET_ERROR,
});

export const setConfigurationUpdated = (updated) => (dispatch) => {
  dispatch({
    type: CONFIGURATION_UPDATED,
    updated,
  });
};

export const loadConfiguration = (code) => async (dispatch) => {
  await dispatch(setWorkInProgress('loadConfiguration', true));
  await dispatch(getConfigurationByCode(code));
  dispatch(setWorkInProgress('loadConfiguration', false));
};

export const loadSampleConfiguration = (code) => async (dispatch) => {
  await dispatch(getConfigurationByCode(code));
  dispatch(setConfigurationUpdated(true));
};

export const getConfigurationByCode = (code) => async (dispatch) => {
  const { request } = createRequest();
  try {
    const response = await request(`frontendapi/configuration/loadbyconfigurationcode/${code}`);
    const configurationNew = get(response, 'data', []);
    dispatch({
      type: RECEIVE_CONFIGURATION,
      configuration: configurationNew,
    });
    dispatch(setConfigurationUpdated(true));
    dispatch(setVisible('loadConfiguration', false));
  } catch (e) {
    console.log(e);
    dispatch(createRequestError(e));
  }
};

export const resetConfigurationCode = () => ({
  type: RESET_CODE,
});

export const saveConfiguration = (data, screenshots, configurationType, extraData = {}) => async (
  dispatch,
  getState
) => {
  const { configuration } = getState().Configuration;
  const configurationWithCustomData = {
    ...configuration,
    customdata: {
      ...data,
      title: '',
      ...extraData,
    },
    screenshots,
  };

  const { request } = createRequest();
  try {
    const response = await request({
      method: 'post',
      url: 'frontendapi/configurations',
      data: {
        configurationtype: configurationType,
        configuration: configurationWithCustomData,
      },
    });
    const configuration = get(response, 'data', []);

    dispatch({
      type: RECEIVE_SAVE_CONFIGURATION,
      configuration,
    });

    // for "print" type a second call is needed to send the generated PDF by email
    if (configurationType === 'print') {
      const code = get(configuration, 'code');
      await request({
        method: 'post',
        url: `frontendapi/configurations/${code}/pdf`,
        data: extraData,
      });
    }

    return configuration;
  } catch (e) {
    dispatch(createRequestError(e));
  } finally {
    dispatch(setWorkInProgress('saveConfiguration', false));
  }
};

export const getLocationParams = () => {
  const params = {};
  const itemRegex = new RegExp(`/(code|identifier):([^/]+)`);
  const results = itemRegex.exec(location.pathname);
  if (results) {
    params[results[1]] = results[2];
  }
  return params;
};

export const getConfiguration = () => async (dispatch) => {
  const { request } = createRequest();
  try {
    const params = getLocationParams();
    let url;
    if (params.code) {
      url = `frontendapi/configuration/loadbyconfigurationcode/${params.code}`;
    } else if (params.identifier) {
      url = `frontendapi/configuration/loadbyitemidentifier/${params.identifier}`;
    } else {
      url = `frontendapi/configuration/loadbyitemidentifier/warehouse`;
    }

    const response = await request(url);
    const configuration = get(response, 'data', []);

    dispatch({
      type: RECEIVE_CONFIGURATION,
      configuration,
    });
  } catch (e) {
    dispatch(createRequestError(e));
  }
};

export const setLoadCode = (loadCode = null) => ({
  type: SET_LOAD_CODE,
  loadCode,
});
