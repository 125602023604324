// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSamples } from './Reducers/Sample/Actions';
import { getConfiguration } from './Reducers/Configuration/Actions';
import { loadOptions } from './Reducers/Option/Actions';
import { ConfigurationContext } from './ContextProviders/ConfigurationProvider';
import { globalLoaderStateManager } from './GlobalLoader';

const { watchPromise } = globalLoaderStateManager;

export const InitContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { configuration } = useSelector((state) => state.Configuration);
  const { componentOptions } = useSelector((state) => state.Option);
  const warehouseConfiguration = useContext(ConfigurationContext);

  useEffect(() => {
    watchPromise(dispatch(getConfiguration()));
    watchPromise(dispatch(getSamples()));
  }, [dispatch]);

  useEffect(() => {
    if (configuration.code) {
      componentOptions.map(
        (classification) =>
          classification.options.length === 0 &&
          watchPromise(dispatch(loadOptions(classification.key, classification.identifier, configuration)))
      );
      warehouseConfiguration.setData(configuration.customdata);
    }
  }, [configuration]);
  return children;
};
