import React, { Suspense } from 'react';
import { hot } from 'react-hot-loader';
import { makeStyles } from '@material-ui/core/styles';
import { VisualizationContainer } from './ContextProviders/VisualizationProvider';
import ContextMenu from './ContextMenu';
import ConfigurationMenu from './Components/ConfigurationMenu/ConfigurationMenu';
import VisualizationToolbar from './Components/VisuailzationToolbar/VisualizationToolbar';
import ConfigurationToolbar from './Components/ConfigurationToolbar/ConfigurationToolbar';
import { GlobalLoader } from './GlobalLoader';
import RequestOffer from './Components/RequestOffer/RequestOffer';
import HowToConfigureHint from './Components/HowToConfigureHint/HowToConfigureHint';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#root': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
    },
  },
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  visualizationContainer: {
    flex: 1,
    overflow: 'hidden',
    '& > *': {
      display: 'flex',
    },
  },
  appContainer: {
    flex: 1,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      [theme.media.portrait]: {
        flexDirection: 'column',
      },
    },
  },
}));

const Main = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <VisualizationContainer className={classes.visualizationContainer} />
      <Suspense fallback={''}>
        <ConfigurationToolbar />
        <VisualizationToolbar />
        <ContextMenu />
        <div className={classes.bottomContainer}>
          <ConfigurationMenu />
          <RequestOffer />
        </div>
        <HowToConfigureHint />
      </Suspense>
      <GlobalLoader />
    </div>
  );
};

export default hot(module)(Main);
